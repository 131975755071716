import React, {useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Faq from "../models/Faq";
import FaqTips from "./FaqTips";
import {Routes} from "../Routes";
import HTML from "./form/HTML";
import {Divider} from "@material-ui/core";
import BackButton from "./BackButton";
import LoadingIndicator from "./LoadingIndicator";
import {useRepo} from "../repos/useRepo";

interface Params {
    faqId: string;
}

interface Props extends RouteComponentProps<Params> {

}

const useStyles = makeStyles(theme => ({
    root: {
        margin: "0 auto",
        alignContent: "center",
        width: "100%",
        /*overflow: "scroll"*/
    },
    content: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "start",
        padding: "12px",
        maxWidth: "1600px",
        margin: "0 auto",
        minHeight: "48px",
        flexWrap: "wrap"
    },
    leftSection: {
        marginTop: "16px",
        [theme.breakpoints.up('sm')]: {
            marginRight: "32px",
        },
    },
    rightSection: {
        marginTop: "16px",
        /*overflow: "scroll"*/
    },
    faq: {
        maxWidth: "1000px",
    }
}));

function FaqDetail(props: Props) {
    const classes = useStyles();

    const [faq, setFaq] = useState<Faq>();
    const {faqRepo} = useRepo();
    const faqId = props.match.params.faqId;

    useEffect(() => {
        if (faqId) {
            faqRepo.getFaqById(faqId)
                .then(faq => setFaq(faq))
                .catch(err => console.error(err));
        }
    }, [faqRepo, faqId]);

    const content = faq ? <FaqBody faq={faq}/> : <LoadingIndicator/>;

    return (
        <div className={classes.root}>

            <div className={classes.content}>

                <div className={classes.leftSection}>
                    <FaqTips onAskQuestionClicked={() => props.history.push(Routes.ask)}/>
                </div>

                <div className={classes.rightSection}>
                    
                    <BackButton onClick={() => props.history.goBack()}/>

                    {content}

                </div>

            </div>

        </div>
    );
}

function FaqBody(props: { faq: Faq }) {
    const { faq } = props;
    const classes = useStyles();

    return (
        <div className={classes.faq}>
            <h1>{faq.question}</h1>

            <Divider variant="fullWidth"
                     style={{
                         marginTop: "12px",
                         marginBottom: "12px",
                         marginRight: "30%"
                     }} />

            <HTML rawHtml={faq.answer}/>
        </div>

    )
}

export default withRouter(FaqDetail);
