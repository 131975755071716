import * as Yup from "yup";
import {AssignRolesRequest} from "../../repos/UserRepo";

export const assignRoleSchema = Yup.object().shape({
    roleIds: Yup.array().of(Yup.string()).required(),
});

export const initialValuesForAssignRoleRequest = (): AssignRolesRequest  => ({
    roleIds: []
});
