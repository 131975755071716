import React from "react";

export default function NoRolesAvailable() {
    return (
        <div style={{ maxWidth: 320 }}>
            You currently have no roles available to assign to this user.
            This generally means the user has been assigned every available role.
        </div>
    )
}

