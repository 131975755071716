import React from "react";

export interface TabActionBarProps {
    leading?: React.ReactNode;
    center?: React.ReactNode;
    trailing?: React.ReactNode
}

export default function TabActionBar({ leading, center, trailing }: TabActionBarProps) {

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '12px 0' }}>
            <div>
                {leading}
            </div>
            <div>
                {center}
            </div>
            <div>
                {trailing}
            </div>
        </div>
    )
}