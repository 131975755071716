import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {BusinessType} from "../../../repos/BusinessType";

export default function BusinessTypePicker(props: PickerPropsWithoutOptions) {
    const { businessTypeRepo } = useRepo();

    const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);

    useEffect(() => {
        businessTypeRepo.list().then(setBusinessTypes).catch(console.error);
    }, [businessTypeRepo]);

    const businessTypeOptions = useMemo(() => businessTypes.map(t => ({ id: t.value, label: t.displayName })), [businessTypes]);

    return <PickerInput
        name={props.name || "businessTypeId"}
        label={props.label || "Business Type"}
        options={businessTypeOptions}
        {...props}
    />
}
