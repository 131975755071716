import * as React from 'react';
import {Button, createStyles, Theme, WithStyles} from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import {QuestionRepo} from "../repos/QuestionRepo";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Question from "../models/Question";
import {Routes} from "../Routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Terms from "./Terms";
import QuestionStatusBadge from "./QuestionStatusBadge";
import HTML from "./form/HTML";

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        overflow: "auto"
    },
    content: {
        margin: "0 auto",
        maxWidth: "1200px",
        padding: "36px",
    },
    section: {
        marginTop: "32px",
    },
    buttonSection: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "24px",
        flexWrap: "wrap"
    },
    backButton: {
        marginBottom: "8px"
    },
    askButton: {
        marginBottom: "8px"
    },
    progressContainer: {
        paddingTop: "72px",
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    termsSection: {
        marginTop: "36px"
    },
    previousQuestionSection: {
        marginTop: "48px",
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    showPreviousQuestionButton: {
        alignSelf: "center",
        fontSize: "12px"
    },
});

interface Params {
    questionId: string;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<Params> {
    questionRepo: QuestionRepo,
}

interface State {
    isLoading: boolean;
    question: Question | null;
    previousQuestion?: Question;
    isLoadingPreviousQuestion: boolean;
}

class ViewQuestion extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            question: null,
            isLoadingPreviousQuestion: false
        };
    }

    async componentWillMount(): Promise<void> {
        await this.loadQuestion();
    }

    onShowPreviousQuestionClicked = async () => {
        try {
            const { question } = this.state;

            this.setState({ isLoadingPreviousQuestion: true });

            if (question && question.previousQuestionId) {
                const previousQuestion = await this.props.questionRepo.getQuestion(question.previousQuestionId);
                this.setState({
                    previousQuestion: previousQuestion
                })
            }

        } catch (err) {
            console.error(err);
        } finally {
            this.setState({ isLoadingPreviousQuestion: false });
        }
    };

    loadQuestion = async () => {
        try {
            this.setState({ isLoading: true });

            const { questionId } = this.props.match.params;
            const question = await this.props.questionRepo.getQuestion(questionId);

            this.setState({ question: question  });
        } catch (err) {
            console.error(err)
        } finally {
            this.setState({ isLoading: true });
        }
    };

    onBackClicked = async () => {
        this.props.history.push(Routes.myQuestions);
    };

    onAskFollowUpQuestionClicked = async () => {
        const { questionId } = this.props.match.params;
        const route = Routes.askFollowUpQuestion(questionId);
        this.props.history.push(route);
    };

    render() {

        const { classes } = this.props;
        const { question } = this.state;

        const backButton = (
            <Button className={classes.backButton}
                    onClick={() => this.onBackClicked()}
                    variant="contained"
                    color="primary">
                Back
            </Button>
        );

        return (
            <div className={classes.root}>

                <div className={classes.content}>

                    <div className={classes.buttonSection}>

                        {backButton}

                        {this._renderAskFollowupQuestionButton()}

                    </div>


                    {question ? this._renderQuestion(question) : this._renderLoadingIndicator()}

                </div>

            </div>
        );
    }

    private _renderQuestion(question: Question) {
        const { classes } = this.props;

        const hasPreviousQuestion = !!question.previousQuestionId;
        const { previousQuestion, isLoadingPreviousQuestion } = this.state;
        const statusIdentifier = question.status ? question.status.identifier : null;

        return (
            <div>

                {question.body &&
                <>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        justifyItems: "start"
                    }}>
                        <h3 style={{ marginRight: 12 }}>
                          Question
                        </h3>

                        {statusIdentifier &&
                        <QuestionStatusBadge status={statusIdentifier}/>
                        }

                    </div>


                    <Divider variant="fullWidth"/>

                    <p>{question.body}</p>

                </>
                }

                {question.answer &&
                <>
                    <h3>Answer</h3>

                    <Divider variant="fullWidth"/>

                    <HTML style={{userSelect: "none"}} rawHtml={question.answer}/>

                </>
                }

                <Divider variant="fullWidth"/>


                <div className={classes.previousQuestionSection}>
                    {hasPreviousQuestion && !previousQuestion &&
                    <Button className={classes.showPreviousQuestionButton}
                            onClick={() => this.onShowPreviousQuestionClicked()}
                            disabled={isLoadingPreviousQuestion}
                            variant="contained"
                            color="secondary">
                        Show Previous Question
                    </Button>
                    }

                    {previousQuestion && this._renderPreviousQuestionSection(previousQuestion)}
                </div>


                <div className={classes.termsSection}>
                    <Terms/>
                </div>
            </div>
        )
    }

    private _renderPreviousQuestionSection(question: Question) {

        return (
            <>
                <h3>Previous Question</h3>

                {question.body &&
                <>
                    <h4>Question</h4>

                    <Divider variant="fullWidth"/>

                    <p>{question.body}</p>

                </>
                }

                {question.answer &&
                <>
                    <h4>Answer</h4>

                    <Divider variant="fullWidth"/>

                    <HTML style={{userSelect: "none"}} rawHtml={question.answer}/>

                </>
                }
            </>
        );
    }


    private _renderLoadingIndicator() {
        const { classes } = this.props;

        return (
            <div className={classes.progressContainer}>
                <CircularProgress size={128} color="secondary"/>
            </div>
        )
    }

    private _renderAskFollowupQuestionButton() {
        const { classes } = this.props;

        return (
            <Button className={classes.askButton}
                    onClick={() => this.onAskFollowUpQuestionClicked()}
                    variant="contained"
                    color="primary">
                Ask a follow-up question
            </Button>
        );
    }
}

export default withRouter(withStyles(styles)(ViewQuestion));
