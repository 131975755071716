import {default as React} from "react";
import _ from "lodash";
import { Card } from "../../../../models/Card";

type DetailFieldModel = {
    label: string,
    value: string;
    hidden?: boolean;
}

export default function CardDetailTable(props: { card: Card }) {
    const { card } = props;

    const { last4, country, brand } = card;

    const emptyValue = "-";

    const fields: DetailFieldModel[] = [
        {
            label: "Brand",
            value: _.capitalize(brand) || emptyValue,
        },
        {
            label: "Card Number",
            value: `**** **** **** ${ last4 }` || emptyValue,
        },
        {
            label: "Country",
            value: country || emptyValue,
        },
    ]

    return (
        <table>
            <tbody>
            {fields.map(field => (
                <tr key={field.label}>
                    <td style={{ padding: 6 }}><b>{field.label}</b></td>
                    <td style={{ padding: 6, textAlign: 'left' }}>{field.value}</td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}
