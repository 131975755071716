import React from 'react';
import {TopicViewModel} from "./TopicPicker";
import {createStyles, makeStyles, Theme} from "@material-ui/core";

/*
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid silver;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;

 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: "15px 8px",
            margin: "6px",
            display: 'flex',
            width: "120px",
            flexDirection: "column",
            textAlign: "center",
            borderColor: theme.palette.grey[300],
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "6px",
            justifyContent: "center",
            alignItems: "center",
            '&:hover': {
                borderColor: theme.palette.secondary.light
            }
        },
    }),
);

interface Props {
    topic: TopicViewModel
    onSelected: (topic: TopicViewModel) => void;
}

export default function TopicPickerItem(props: Props) {

    const classes = useStyles();
    const { topic, onSelected } = props;

    return (
        <div className={classes.container} onClick={() => onSelected(topic)}>
            <img
                src={topic.iconUrl}
                alt={topic.title}
                style={{
                    width: "50px",
                    height: "50px",
                }}/>
            <div style={{paddingTop: "12px"}}>
                {topic.title}
            </div>
        </div>
    )
}