import React from 'react';
import { RouteComponentProps, withRouter} from "react-router-dom";
import {config} from "../../config";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "./Footer";
import {Button, Typography} from "@material-ui/core";
import {useServices} from "../../services/useServices";
import {useAuthState} from "../../services/auth/useAuthState";

const useStyles = makeStyles(theme => ({
  disclaimer: {
    ...theme.typography.body1,
    color: theme.palette.error.main,
    marginRight: "7px"
  },
  logoutButton: {
    color: theme.palette.error.main,
    marginRight: "7px"
  }
}));

function ImpersonationFooter(props: RouteComponentProps) {
  const classes = useStyles();
  const { userInfo } = useAuthState();
  const { authService } = useServices();
  return (
    <Footer>
      <div className={classes.disclaimer}>
        <Typography className={classes.disclaimer}>
          {`Currently impersonating user ${userInfo?.firstName} ${userInfo?.lastName}`}
        </Typography>
      </div>

      <div>
        <Button variant={"outlined"} className={classes.logoutButton} onClick={authService.logout}>Stop Impersonation</Button>
      </div>
    </Footer>
  );
}

export default withRouter(ImpersonationFooter);
