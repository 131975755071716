import Topic from "../models/Topic";
import HttpService from "@gsb/react-http";

export interface TopicRepo {
    getTopicById(id: string): Promise<Topic>;
    listTopics(): Promise<Topic[]>;
}

export class HttpTopicRepo implements TopicRepo {

    constructor(private http: HttpService) {}

    async getTopicById(id: string): Promise<Topic> {
        return this.http.get(['ti', 'v1', 'topics', id]);
    }

    async listTopics(): Promise<Topic[]> {
        return this.http.get(['ti', 'v1', 'members', 'current', 'topics']);
    }
}
