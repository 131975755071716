import React, {useContext, createContext, } from 'react';
import {User} from "../../models/User";
import {SalesChannelApplication} from "../../models/SalesChannelApplication";
import {SalesChannel} from "../../models/SalesChannel";
import {Reseller} from "../../models/Reseller";

interface StartupResult {
    user: User;
    salesChannel: SalesChannel;
    reseller: Reseller;
    roles: string[];
    licenses: string[];
    applications: SalesChannelApplication[];
    refresh: () => void;
}

const StartupContext = createContext<StartupResult>(null as unknown as StartupResult);

export const useStartupResult = () => useContext(StartupContext);

interface Props {
    result: StartupResult;
}

export const StartupProvider: React.FC<Props> = ({ children, result }) => {
    return (
        <StartupContext.Provider value={result}>
            {children}
        </StartupContext.Provider>
    );
};
