import React from "react";
import AppStartup from "../../AppStartup";

const withStartup = <P extends any>(Component: React.ComponentType<P>): React.FC<P> =>
    (props) => {
        return (
            <AppStartup>
                <Component {...props}/>
            </AppStartup>
        );
    };

export default withStartup;
