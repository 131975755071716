import {BusinessSizeRepo, HardcodedBusinessSizeRepo} from "./BusinessSizeRepo";
import {BusinessTypeRepo, HardcodedBusinessTypeRepo} from "./BusinessType";
import {TopicRepo, HttpTopicRepo} from "./TopicRepo";
import {CompanyProfileRepo, HttpCompanyProfileRepo} from "./CompanyProfileRepo";
import {EntityTypeRepo, HardcodedEntityTypeRepo} from "./EntityTypeRepo";
import {FaqRepo, HttpFaqRepo} from "./FaqRepo";
import {HttpIndustryRepo, IndustryRepo} from "./IndustryRepo";
import {HttpQuestionRepo, QuestionRepo} from "./QuestionRepo";
import {HttpStateRepo, StateRepo} from "./StateRepo";
import {HttpUserRepo, UserRepo} from "./UserRepo";
import {CompanyRepo, HttpCompanyRepo} from "./CompanyRepo";
import HttpService from "@gsb/react-http";
import {HttpSalesChannelRepo, SalesChannelRepo} from "./SalesChannelRepo";

export interface Repo {
    businessSizeRepo: BusinessSizeRepo;
    businessTypeRepo: BusinessTypeRepo;
    topicRepo: TopicRepo;
    companyRepo: CompanyRepo;
    companyProfileRepo: CompanyProfileRepo;
    entityTypeRepo: EntityTypeRepo;
    faqRepo: FaqRepo;
    industryRepo: IndustryRepo;
    questionRepo: QuestionRepo;
    stateRepo: StateRepo;
    userRepo: UserRepo;
    salesChannelRepo: SalesChannelRepo;
}

export class DefaultRepo implements Repo {
    businessSizeRepo: BusinessSizeRepo = new HardcodedBusinessSizeRepo();
    businessTypeRepo: BusinessTypeRepo = new HardcodedBusinessTypeRepo();
    topicRepo: TopicRepo = new HttpTopicRepo(this.httpService);
    companyRepo: CompanyRepo = new HttpCompanyRepo(this.httpService);
    companyProfileRepo: CompanyProfileRepo = new HttpCompanyProfileRepo(this.httpService);
    entityTypeRepo: EntityTypeRepo = new HardcodedEntityTypeRepo();
    faqRepo: FaqRepo = new HttpFaqRepo(this.httpService);
    industryRepo: IndustryRepo = new HttpIndustryRepo(this.httpService);
    questionRepo: QuestionRepo = new HttpQuestionRepo(this.httpService);
    stateRepo: StateRepo = new HttpStateRepo(this.httpService);
    userRepo: UserRepo = new HttpUserRepo(this.httpService);
    salesChannelRepo: SalesChannelRepo = new HttpSalesChannelRepo(this.httpService);

    constructor(private httpService: HttpService) {}
}
