import * as React from "react";
import moment from "moment";
import {useState, useEffect} from "react";
import { Button, Snackbar } from "@material-ui/core";
import { withModal } from "../modal";

const SessionExpiringToast = (props: { onClick: () => void }) => {

    const action = (
        <Button
            color="primary"
            variant="contained"
            onClick={props.onClick}
        >Keep me logged in</Button>
    );

    return (
        <Snackbar open
                  onClick={props.onClick}
                  message={"Your Session is Expiring in 5 minutes"}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                  }}
                  action={action}/>)
};

const AuthRefreshModal = withModal((props: { onClick: () => void }) => (
    <div style={{
        display: "flex",
        flexDirection: "column",
    }}>
        <span>Your session has expired, login to continue.</span>
        <Button
            style={{marginTop: "5px"}}
            color="primary"
            variant="contained"
            onClick={props.onClick}
        >Log in</Button>
    </div>
));

const shouldDisplayExpiring = ( expiresOn: string | null) => {
    if( expiresOn ) {
      const expirationDate = moment(expiresOn)
      const fiveMinutesBeforeExpiration = expirationDate.subtract(5, 'minutes').toDate();

      return moment().isBetween( fiveMinutesBeforeExpiration, expirationDate )
    }

    return false;
};

export default function useSessionExpirationModals(onRefreshClicked: () => void) {
    const [intervalReference, setIntervalReference] = useState<NodeJS.Timeout | null>(null);
    const [expiresOn, setExpiresOn] = useState<string | null>(null);
    const [isSessionExpiring, setSessionExpiring] = useState(false);
    const [isSessionExpired, setSessionExpired] = useState(false);

    const handleLoginClicked = () => {
        setSessionExpired(false);
        onRefreshClicked();
    };

    useEffect(() => {
        if( intervalReference ) {
            clearInterval( intervalReference );
        }

        if( isSessionExpired ) {
            setIntervalReference(null);
        }
        else {
            setIntervalReference(setInterval(() => {
                if( shouldDisplayExpiring(expiresOn) && !isSessionExpiring ) {
                    setSessionExpiring(true);
                }
            }, 5000));
        }
    }, [expiresOn, isSessionExpiring, isSessionExpired]);

    const sessionExpiringToast = isSessionExpiring ? (
        <SessionExpiringToast onClick={() => onRefreshClicked()} />
    ) : null;

    const modal = isSessionExpired ? (
        <AuthRefreshModal
            open={true}
            onClose={() => {}}
            onClick={() => handleLoginClicked()}
        />
    ) : null;

    return { setSessionExpired, setExpiresOn, sessionExpiringToast, sessionExpiredModal: modal }
}
