import * as Yup from 'yup';
import Question from "../../models/Question";

export const questionSchema = Yup.object().shape({
    previousQuestionId: Yup.string().nullable(),
    topicId: Yup.string().required('Please select a topic'),
    email: Yup.string().email('Please enter a valid email.').nullable(),
    body: Yup.string()
        .min(20, 'Please enter a body (minimum 20 characters).')
        .max(1023, 'Please enter a body (maximum 1023 characters).')
        .required('Body is required'),
});

export const initialValuesForQuestion = (question?: Question) => ({
    previousQuestionId: question ? question.id : '',
    topicId: question ? question.topicId : '',
    email: question ? question.email : '',
    body: ''
});
