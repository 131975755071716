import React from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import timeZones from "../../../config/time-zones";

interface Props extends PickerPropsWithoutOptions {}

export default function TimeZonePicker({ ...PickerProps }: Props) {

    const options = timeZones.map(t => ({ id: t.value, label: t.name }));

    return <PickerInput label={'Time Zone'} options={options} {...PickerProps} />
}
