import {License} from "../License";
import LicenseModel from "./LicenseModel";
import {Application} from "../Application";

export interface LicenseGroup {
    application: Application;
    licenses: LicenseModel[];
}

export default class LicenseListModel {

    static fromLicenses(licenses: License[]): LicenseListModel {
        const models = licenses.map(license => new LicenseModel(license));
        return new LicenseListModel(models)
    }

    constructor(private licenses: LicenseModel[]) {}

    get hasCompanyLicenses(): boolean {
        return this.companyLicenses.length > 0
    }

    get hasAvailableLicenses(): boolean {
        return this.availableLicenses.length > 0;
    }

    get hasAssignableLicenses(): boolean {
        return this.assignableLicenses.length > 0;
    }

    get allLicenses(): LicenseModel[] {
        return this.licenses.filter(license => license.isActive);
    }

    get availableLicenses(): LicenseModel[] {
        return this.licenses.filter(license => license.isAvailable);
    }

    get companyLicenses(): LicenseModel[] {
        return this.licenses.filter(license => license.isActive && license.isCompanyLicense);
    }

    get assignableLicenses(): LicenseModel[] {
        return this.licenses.filter(license => license.isAssignable);
    }

    getFirstAvailableLicenseForApplicationById(applicationId: string): LicenseModel | null {
        // Take first license by index for this application
        const licensesForApplicationIdSortedByIndex = this.licenses
            .filter(license => license.isActive && license.applicationId === applicationId)
            .sort((lhs, rhs) => {
                if (lhs.index === rhs.index) {
                    return 0
                } else if (lhs.index > rhs.index) {
                    return 1
                } else {
                    return -1
                }
            });

        return licensesForApplicationIdSortedByIndex.length > 0 ? licensesForApplicationIdSortedByIndex[0] : null;
    }

    takeFirstLicenseForEachApplication(): License[] {
        const licensesByApplication: { [key: string]: License } = {};

        for (let licenseModel of this.licenses) {
            const license = licenseModel.license;
            const { applicationId } = license;

            if (!licensesByApplication[applicationId] || license.index < licensesByApplication[applicationId].index) {
                licensesByApplication[applicationId] = license;
            }
        }

        return Object.values(licensesByApplication);
    };

    groupLicensesByApplication(): LicenseGroup[] {
        return this.mapLicensesToApplicationLicenseModels(this.allLicenses);
    }

    groupAssignableLicensesByApplication(): LicenseGroup[] {
        return this.mapLicensesToApplicationLicenseModels(this.assignableLicenses);
    }

    groupAvailableLicensesByApplication(): LicenseGroup[] {
        return this.mapLicensesToApplicationLicenseModels(this.availableLicenses);
    }

    private mapLicensesToApplicationLicenseModels(licenses: LicenseModel[]): LicenseGroup[] {
        const models = licenses.reduce((object, nextLicense) => {
            if (object[nextLicense.applicationId]) {
                object[nextLicense.applicationId].licenses.push(nextLicense);
            } else {
                const application = nextLicense.application;

                if (application) {
                    object[nextLicense.applicationId] = {
                        application, // Safe due to filter
                        licenses: [nextLicense]
                    }
                }
            }

            return object;
        }, {} as { [key: string]: LicenseGroup } );

        return Object.values(models);
    }
}
