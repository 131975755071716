import React, {useContext, createContext, useState, useMemo,} from 'react';
import {AuthenticationResponse} from "@gsb/react-auth";
import UserInfo from "../../models/UserInfo";
import {User} from "../../models/User";

export interface AuthState {
    isAuthenticated?: boolean;
    isImpersonatingUser?:boolean;
    authResponse?: AuthenticationResponse;
    authError?: any;
    userInfo?: UserInfo;
    setAuthResponse: (authResponse?: AuthenticationResponse) => void;
    currentUser?: User;
    setCurrentUser: (user?: User) => void;
    setAuthError: (authError?: any) => void;
}

const AuthStateContext = createContext<AuthState>(null as unknown as AuthState);

export const useAuthState = () => useContext(AuthStateContext);

interface Props {

}

export const AuthStateProvider: React.FC<Props> = ({ children  }) => {
    const [authResponse, setAuthResponse] = useState<AuthenticationResponse>();
    const [currentUser, setCurrentUser] = useState<User>();
    const [authError, setAuthError] = useState<any>();

    const userInfo = useMemo(() => currentUser ? UserInfo.fromUser(currentUser) : undefined, [currentUser]);

    const {isAuthenticated, isImpersonatingUser} = useMemo(() => {
        if (authError) {
            return {isAuthenticated: false, isImpersonatingUser: false}
        } else if (authResponse) {
            let isImpersonatingUser;

            try {
                const state = authResponse.accountState && JSON.parse(authResponse.accountState);
                isImpersonatingUser = state.isImpersonatingUser ?? false;
            } catch {
                isImpersonatingUser = false;
            }

            return {isAuthenticated: true, isImpersonatingUser};
        } else {
            return {isAuthenticated: undefined, isImpersonatingUser: undefined};
        }
    }, [authError, authResponse]);


    const authState: AuthState = useMemo(() => ({
        isAuthenticated,
        isImpersonatingUser,
        authResponse,
        setAuthResponse,
        authError,
        setAuthError,
        userInfo,
        currentUser,
        setCurrentUser
    }), [isAuthenticated, authResponse, setAuthResponse, authError, setAuthError, userInfo, currentUser, setCurrentUser]);

    return (
        <AuthStateContext.Provider value={authState}>
            {children}
        </AuthStateContext.Provider>
    );
};

export function withAuthState<T>(Component: React.ComponentType<T>): React.FC<T> {
    return (props) => {

        return (
            <AuthStateProvider>
                <Component {...props}/>
            </AuthStateProvider>
        );
    };
}
