import {Form, Formik, FormikHelpers} from "formik";
import React, {useEffect, useState} from "react";
import {User} from "../../../models/User";
import {useRepo} from "../../../repos/useRepo";
import {withModal} from "../../modal";
import {
    assignUserToLicenseSchema,
    initialValuesForAssignUserToLicenseRequest
} from "../../../utils/schemas/license-schema";
import {License} from "../../../models/License";
import LoadingIndicator from "../../LoadingIndicator";
import UserPicker from "../../form/pickers/UserPicker";
import NoUsersAvailable from "../components/NoUsersAvailable";
import {AssignUserToLicenseRequest} from "../../../repos/UserRepo";
import {FormSubmitButton} from "../../form/buttons/FormSubmitButton";


interface Props {
    users?: User[];
    initialValues: AssignUserToLicenseRequest;
    onSubmit: (values: AssignUserToLicenseRequest, {}: FormikHelpers<AssignUserToLicenseRequest>) => void;
}

function AssignUserToLicenseForm({ initialValues, users, onSubmit }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={assignUserToLicenseSchema} onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <UserPicker name='userId' users={users || []}/>

                <FormSubmitButton variant='Update' label='Assign'/>
            </Form>
        </Formik>
    )
}

interface UpdateUserFormProps {
    license: License;
    onLicenseAssigned: (license: License) => void;
    onAddUserClicked: () => void;
}

export function AssignUserToLicenseForCurrentCompanyForm({ license, onLicenseAssigned, onAddUserClicked }: UpdateUserFormProps) {
    const { userRepo, companyRepo } = useRepo();

    const [availableUsers, setAvailableUsers] = useState<User[]>();

    useEffect(() => {
        companyRepo.listAvailableUsersForCompanyLicenseById('current', license.id).then(setAvailableUsers).catch(console.error);
    }, [companyRepo]);

    function onSubmit(values: AssignUserToLicenseRequest, { setSubmitting }: FormikHelpers<AssignUserToLicenseRequest>) {
        userRepo.assignLicensesForUserById(values.userId, { licenseIds: [license.id] })
            .then(onLicenseAssigned)
            .catch(console.error)
            .finally(() => setSubmitting(false));
    }

    if (availableUsers) {
        const content = availableUsers.length > 0
            ? <AssignUserToLicenseForm initialValues={initialValuesForAssignUserToLicenseRequest()} onSubmit={onSubmit} users={availableUsers}/>
            : <NoUsersAvailable onAddUserClicked={onAddUserClicked}/>;

        return (
            <div style={{ marginTop: 12, marginBottom: 12 }}>
                {content}
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export const AssignUserToLicenseForCurrentCompanyModal = withModal(AssignUserToLicenseForCurrentCompanyForm);

