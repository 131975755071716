import Entity from "./Entity";

export default interface QuestionStatus extends Entity {
    name: string;
    identifier: string;
    index: number;
}

export class QuestionStatusIdentifier {
    static submitted = "submitted";
    static researching = "researching";
    static onHold = "on-hold";
    static answered = "answered";
    static approved = "approved";
    static released = "released";
}
