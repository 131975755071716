import React, {forwardRef, Ref, useImperativeHandle, useMemo} from "react";
import Tabs from "@material-ui/core/Tabs";
import {RouteComponentProps, withRouter} from "react-router-dom";

export interface TabNavigationItem {
    name: string;
    identifier: string;
    render: () => JSX.Element;
    disabled?: boolean;
    disableContentPadding?: boolean;
    hidden?: boolean;
    className?: string;
}

export interface TabNavigationProps {
    items: TabNavigationItem[];
    disabledContentPadding?: boolean;
    vertical?: boolean;
    tabClassName?: string;
    tabItemClassName?: string;
    tabRef?: Ref<TabNavigationRef>;
}

export interface TabNavigationRef {
    selectTab: (identifier: string) => void;
}

const TabNavigationWithRef = (props: TabNavigationProps & RouteComponentProps) => {
    const { items, disabledContentPadding, vertical, tabClassName, tabItemClassName, tabRef } = props;

    const params = useMemo(() => new URLSearchParams(props.location.search), [props.location.search]);
    const tabIdentifier = useMemo(() => params.get('tab'), [params]);
    const initialTabIndex = useMemo(() => items.findIndex(i => i.identifier === tabIdentifier), [items, tabIdentifier]);
    const initialTab = initialTabIndex > -1 ? initialTabIndex : 0;

    const [tabIndex, setTabIndex] = React.useState(initialTab);

    const tabForIndex = (index: number) => tabItems.length > 0 ? items[index] : null;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        selectTab(newValue)
    };

    const selectTab = (index: number) => {
        const item = tabForIndex(index);

        if (item) {
            setTabIndex(index);
            props.history.replace({ search: `?tab=${item.identifier}` })
        }
    }

    useImperativeHandle(tabRef, () => ({
        selectTab: (identifier: string) => {
            console.log(`Selecting tab: ${identifier}`)
            const index = tabItems.findIndex(t => t.identifier === identifier)

            if (index !== -1) {
                selectTab(index)
            }
        }
    }));

    const tabItems = useMemo(() => items.filter(item => !item.hidden), [items]);
    const tabItem = useMemo(() => tabForIndex(tabIndex), [tabItems, tabIndex]);

    const getPaddingForTabItem = (tabItem: TabNavigationItem) => {
        if (tabItem.disableContentPadding !== undefined) {
            // If there is an explicit value for the tab item use that value
            return tabItem.disableContentPadding ? 0 : 16;
        } else {
            // Use the fallback for the control
            return disabledContentPadding ? 0 : 16;
        }
    };

    return (
        <>
            <Tabs value={tabIndex}
                  onChange={handleChange}
                  className={tabClassName}
                  orientation={vertical ? "vertical" : "horizontal"}>
                {tabItems.map((item, index) => (
                    <div key={`tab-${index}`} className={item.className || tabItemClassName} onClick={(e) => handleChange(e, index)}>
                        {item.name}
                    </div>
                ))}
            </Tabs>

            {tabItem && (
                <div style={{ padding: getPaddingForTabItem(tabItem), width: '100%' }}>
                    {tabItem.render()}
                </div>
            )}
        </>
    )
}

const TabNavigationWithRefAndRouter = withRouter(TabNavigationWithRef);

const TabNavigation = forwardRef<TabNavigationRef, TabNavigationProps>((props, ref) =>
    <TabNavigationWithRefAndRouter tabRef={ref} {...props}/>
)

export default TabNavigation;
