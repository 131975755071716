import Topic from "../models/Topic";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        padding: "12px 18px",
        flexWrap: "wrap"
    },
    info: {
        marginLeft: "32px",
        flex: "1",
        minWidth: "200px"
    },
}));

export default function FaqListHeader(props: { topic: Topic }) {
    const classes = useStyles();
    const { topic } = props;

    return (
        <div className={classes.container}>
            <img src={"img/topics/human-resources.png"} style={{
                width: "96px",
                height: "96px"
            }} alt={topic.name}/>
            <div className={classes.info}>
                <h1>{topic.name}</h1>
                <p>{topic.detail}</p>
            </div>

        </div>
    );
}
