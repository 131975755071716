import * as React from 'react';
import {Button, createStyles, makeStyles, Theme} from '@material-ui/core';
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import Question from "../models/Question";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {Routes} from "../Routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import Title from "./Title";
import Terms from "./Terms";
import DateFormatter, {DateFormat} from "../utils/DateFormatter";
import QuestionStatusBadge from "./QuestionStatusBadge";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import moment from "moment";
import TableBody from "@material-ui/core/TableBody";
import {useEffect, useState} from "react";
import {useRepo} from "../repos/useRepo";
import { customStyle } from '../theme';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        fontWeight: 900,
        fontSize: 12,
        textTransform: "uppercase",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        background: theme.palette.background.paper,
        '&:nth-of-type(even)': {
            background: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
        overflow: "auto",
        ...customStyle.home.root,
    },
    content: {
        margin: "0 auto",
        maxWidth: "1600px",
        padding: "36px",
    },
    loadingProgress: {
        margin: "24px auto",
        alignSelf: "stretch"
    },
    progressContainer: {
        padding: "32px",
        display: "flex",
        alignItems: "center"
    },
    tableContainer: {
        borderColor: theme.palette.secondary.light,
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "6px",
        padding: "0",
        overflow: "auto",
        maxWidth: "1200px"
    },
    table: {
    },
    tableHeader: {

    },
    tableContent: {

    },
    button: {
    },
    askButton: {
        marginBottom: "24px"
    },
    input: {

    },
    cellDefault: {

    },
    termsSection: {
        maxWidth: "1200px"
    },
    emptyData: {
        textAlign: "center",
        justifyContent: "center",
        margin: "0 auto",
        padding: "24px",
        width: "100%"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableSortLabel: {
        color: "white",
    },
    activeSortIcon: {
        color: "white !important",
        opacity: 1,
    },
    // Half visible for inactive icons
    inactiveSortIcon: {
        color: "white !important",
        opacity: 0,
    },
    headerCell: {

    }
}));

interface Props extends RouteComponentProps {

}

interface HeadRow {
    disablePadding: boolean;
    id: keyof Question;
    label: string;
    numeric: boolean;
    filter: Comparator<Question>;
    width?: string;
}


type Order = 'asc' | 'desc';
type Comparable = number | string;

function compare<T extends Comparable>(lhs: T, rhs: T): ComparisonResult {
    if (lhs === rhs) {
        return 0
    } else if (lhs > rhs) {
        return 1
    } else {
        return -1;
    }
}

const dateComparator = (lhs: Question, rhs: Question) => compare(moment(lhs.createdAt).valueOf(), moment(rhs.createdAt).valueOf());

const statusComparator = (lhs: Question, rhs: Question): ComparisonResult => {
    if (!lhs.status || !rhs.status) {
        return 0;
    }

    return compare(lhs.status.index, rhs.status.index);
};

const titleComparator = (lhs: Question, rhs: Question) => compare(lhs.title || lhs.body, rhs.title || rhs.body);

const topicComparator = (lhs: Question, rhs: Question): ComparisonResult => {
    if (!lhs.topic || !rhs.topic) {
        return 0;
    }

    return compare(lhs.topic.name, rhs.topic.name);
};

const headRows: HeadRow[] = [
    { id: 'createdAt', numeric: true, disablePadding: false, label: 'Date', filter: dateComparator, width: "72px" },
    { id: 'statusId', numeric: true, disablePadding: false, label: 'Status', filter: statusComparator, width: "72px" },
    { id: 'topicId', numeric: true, disablePadding: false, label: 'Topic', filter: topicComparator, width: "200px" },
    { id: 'title', numeric: false, disablePadding: true, label: 'Title', filter: titleComparator },
];

type ComparisonResult = -1 | 0 | 1;
type Comparator<T> = (lhs: T, rhs: T) => ComparisonResult;

function sortItems<T>(items: T[], sort: Sort<T>): T[] {
    return items.sort((a, b) => {
        const result = sort.filter(a, b);
        return sort.order === 'asc' ? result : -1 * result;
    });
}

interface Sort<T> {
    key: keyof T;
    order: Order;
    filter: Comparator<T>
}

function Home(props: Props) {
    const { history } = props;
    const { questionRepo } = useRepo();
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [sort, setSort] = useState<Sort<any>>({
        key: 'createdAt',
        order: 'desc',
        filter: dateComparator
    });

    const classes = useStyles();
    const hasQuestions = questions.length > 0 ;
    const shouldDisplayLoadingIndicator = loading;
    const shouldDisplayEmptyData = !loading && !hasQuestions;

    useEffect(() => {
        questionRepo.getMyQuestions()
            .then(setQuestions)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, []);

    const onAskQuestionPressed = () => {
        history.push(Routes.ask)
    };

    const onViewQuestionClicked = (question: Question) => {
        history.push(Routes.viewQuestionWithId(question.id))
    };

    const createSortHandler = (row: HeadRow) => (event: React.MouseEvent<unknown>) => {
        setSort({
            key: row.id,
            filter: row.filter,
            order: sort.order === 'asc' ? 'desc' : 'asc'
        });
    };

    return (
        <div className={classes.root}>

            {/*<Title title={"Small Business Consulting Services"}/>*/}

            <div className={classes.content}>
                <AskQuestionButton onClick={onAskQuestionPressed}/>

                <h3>My Questions</h3>

                <div className={classes.tableContainer}>

                    <Table className={classes.table}>
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                {headRows.map(row => (
                                        <StyledTableCell
                                            className={classes.headerCell}
                                            key={row.id}
                                            style={{
                                                width: row.width || 'auto',
                                                paddingLeft: "8px"
                                            }}
                                            align={'left'}
                                            padding={row.disablePadding ? 'none' : 'default'}
                                            sortDirection={sort.key === row.id ? sort.order : false}
                                        >
                                            <TableSortLabel
                                                className={classes.tableSortLabel}
                                                active={sort.key === row.id}
                                                direction={sort.order}
                                                onClick={createSortHandler(row)}
                                                classes={{
                                                    icon:  ((sort.key === row.id) ? classes.activeSortIcon : classes.inactiveSortIcon )
                                                }}
                                            >
                                                <div style={{color: "white"}}>
                                                    {row.label}
                                                    {sort.key === row.id ? (
                                                        <span className={classes.visuallyHidden}>{sort.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                                                    ) : null}
                                                </div>
                                            </TableSortLabel>
                                        </StyledTableCell>
                                    )
                                )}
                                <StyledTableCell align="right"/>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {!loading && hasQuestions && sortItems(questions, sort).map(question => (
                                <QuestionRow key={question.id} question={question} onViewQuestionClicked={onViewQuestionClicked}/>
                            ))}
                        </TableBody>
                    </Table>

                    {shouldDisplayEmptyData &&
                    <div className={classes.emptyData}>
                      You currently have no questions to display.
                      <br/>
                      <br/>
                      Ask a question to get started!
                    </div>
                    }

                    {shouldDisplayLoadingIndicator &&
                    <div className={classes.progressContainer}>
                      <CircularProgress size={72} className={classes.loadingProgress} color="secondary" />
                    </div>
                    }

                </div>

                <div className={classes.termsSection}>
                    <Terms/>
                </div>

            </div>

        </div>
    );
}

function AskQuestionButton(props: { onClick: () => void }) {
    const classes = useStyles();
    return (
        <Button className={classes.askButton}
                variant="contained"
                color="primary"
                onClick={props.onClick}>
            Ask a Question
        </Button>
    );
}

function QuestionRow(props: { question: Question, onViewQuestionClicked: (question: Question) => void }) {
    const { question, onViewQuestionClicked } = props;
    const topic = question.topic ? question.topic.name : '';
    const title = question.title || question.body || '';
    const createdAt = DateFormatter.formatDate(question.createdAt, DateFormat.slashDelimitedDate);
    const statusIdentifier = question.status ? question.status.identifier : null;
    return (
        <StyledTableRow key={question.id}>
            <StyledTableCell align="left">{createdAt}</StyledTableCell>
            <StyledTableCell align="left">
                {statusIdentifier &&
                <QuestionStatusBadge status={statusIdentifier}/>
                }
            </StyledTableCell>
            <StyledTableCell align="left">{topic}</StyledTableCell>
            <StyledTableCell scope="left">{title}</StyledTableCell>
            <StyledTableCell align="right">
                <Button variant="contained" color="primary" onClick={() => onViewQuestionClicked(question)}>
                    View
                </Button>
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default withRouter(Home);
