import { EntityType } from "../models/CompanyProfile";

export interface EntityTypeRepo {
  list(): Promise<EntityType[]>
}

export class HardcodedEntityTypeRepo implements EntityTypeRepo {
  private options = [
    "Sole Proprietorship",
    "Partnership",
    "LLC",
    "Corporation",
    "Other"
  ];

  async list(): Promise<EntityType[]> {
    return this.options
  }
}
