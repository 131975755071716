import {Form, Formik, FormikHelpers} from "formik";
import React, {useEffect, useMemo, useState} from "react";
import {License} from "../../../models/License";
import {useRepo} from "../../../repos/useRepo";
import {withModal} from "../../modal";
import {assignRoleSchema, initialValuesForAssignRoleRequest} from "../../../utils/schemas/role-schema";
import {Role} from "../../../models/Role";
import {AssignRolesRequest} from "../../../repos/UserRepo";
import {User} from "../../../models/User";
import { Typography,} from "@material-ui/core";
import LoadingIndicator from "../../LoadingIndicator";
import NoRolesAvailable from "../components/NoRolesAvailable";
import MultipleSelectInput from "../../form/select/MultipleSelectInput";
import {FormSubmitButton} from "../../form/buttons/FormSubmitButton";

export interface AssignRolesFormDependency {
    user: User;
    license?: License;
}

interface Props {
    roles: Role[];
    initialValues: AssignRolesRequest;
    onSubmit: (values: AssignRolesRequest, {}: FormikHelpers<AssignRolesRequest>) => void;
}

function AssignRolesForm({ initialValues, roles, onSubmit }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={assignRoleSchema} onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <MultipleSelectInput name='roleIds' label='Roles' items={roles.map(r => ({ id: r.id, name: r.name }))}/>

                <FormSubmitButton variant='Update' label='Assign'/>
            </Form>
        </Formik>
    )
}

interface UpdateLicenseFormProps {
    dependency: AssignRolesFormDependency;
    onRolesAssigned: (roles: Role[]) => void;
}

export function AssignRolesToUserForCurrentCompanyForm({ dependency, onRolesAssigned }: UpdateLicenseFormProps) {
    const { userRepo, companyRepo } = useRepo();
    const { user, license } = dependency;

    const [availableRoles, setAvailableRoles] = useState<Role[]>();

    const applicationId = useMemo(() => license ? license.applicationId : undefined, []);

    useEffect(() => {
        userRepo.listRolesForUserById(user.id, { status: 'available', applicationId }).then(setAvailableRoles).catch(console.error);
    }, [companyRepo]);

    function onSubmit(values: AssignRolesRequest, { setSubmitting }: FormikHelpers<AssignRolesRequest>) {
        userRepo.assignRolesForUserById(user.id, values)
            .then(onRolesAssigned)
            .catch(console.error)
            .finally(() => setSubmitting(false));
    }

    if (availableRoles) {
        const content = availableRoles.length > 0
            ? <AssignRolesForm initialValues={initialValuesForAssignRoleRequest()} onSubmit={onSubmit} roles={availableRoles || []}/>
            : <NoRolesAvailable/>;

        return (
            <div>
                <Typography>Assign Roles to {user.firstName} {user.lastName}</Typography>
                <div style={{ marginTop: 12, marginBottom: 12 }}>
                    {content}
                </div>
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export const AssignRolesToUserForCurrentCompanyModal = withModal(AssignRolesToUserForCurrentCompanyForm);
