import _ from "lodash";
import {NavigationGroup, NavigationItem, NavigationLink} from "../components/layout/Navigation";
import {applications} from "../config/applications";
import {SalesChannelApplication} from "../models/SalesChannelApplication";
import { NavigationLinkProvider } from ".";
import {config} from "../config";

type Application = { name: string, identifier: string }

export class GsbNavigationLinkProvider implements NavigationLinkProvider {

    constructor(private salesChannelApplications: SalesChannelApplication[], private licenses: string[]) {}

    getNavigationLinks = () => {
        const links: NavigationLink[] = [];

        links.push(new NavigationItem('dashboard', 'Dashboard', false, false, config.dashboardUrl,"_self" ));

        links.push(new NavigationGroup('help-group', 'Help', [
            new NavigationItem('support','Support',false, false, 'https://help.gosmallbiz.com'),
            new NavigationItem('chat','Chat',false, false),
            new NavigationItem('email','Email',false, false, 'mailto:support@gosmallbiz.com'),
            new NavigationItem('hours','Hours of Operations:\nM-F 9am - 7pm EST',false, false),
            new NavigationItem('phone','866.467.6249',false, false, 'tel:8664676249'),
        ]));

        const applicationsGroupItems = this.navigationItemsForApplications(_.values(applications));
        links.push(new NavigationGroup('applications-group', 'Apps', applicationsGroupItems.sort((a,b) => a.title > b.title ? 1 : (b.title > a.title ? -1 : 0))))

        return links;
    };

    private isApplicationAvailable = (application: Application) => {
        return this.salesChannelApplications.findIndex(s => s.application?.identifier === application.identifier) > -1 &&
              this.licenses.findIndex(l => l === application.identifier) > -1;
    };

    private navigationItemsForApplications = (applications: Application[]) => {
        return applications
            .filter(this.isApplicationAvailable)
            .map(this.navigationItemForApplication)
    };

    private navigationItemForApplication = (application: Application) => {
        const salesChannelApplication = this.salesChannelApplications.find(s => s.application?.identifier === application.identifier);
        const name = salesChannelApplication?.alias ?? application.name;
        return new NavigationItem(application.identifier, name, false, false)
    }
}
