import React from "react";
import {useStartupResult} from "./startup/useStartupResult";
import LicenseRequired from "../components/LicenseRequired";
import {config} from "../config";

function makeLicenseGuard(applicationIdentifier: string) {

    return function withLicenseGuard<T>(Component: React.ComponentType<T>): React.FC<T> {
        return (props) => {
            const { licenses } = useStartupResult();

            const hasAccess = licenses.includes(applicationIdentifier);

            if (hasAccess) {
                return <Component {...props}/>;
            } else {
                return <LicenseRequired application={applicationIdentifier}/>
            }
        };
    }
}

export const withLicenseGuard = makeLicenseGuard(config.applicationIdentifier);
