import React from 'react';
import {Formik, FormikConfig} from "formik";

type FormikConfigWithoutSubmit<Values> = Omit<FormikConfig<Values>, "onSubmit">;

export type FormProps<Values> = FormikConfigWithoutSubmit<Values> & {
    disabled?: boolean;
    onValuesChange?: (values: Values) => void;
    onErrorStateChange?: (hasError: boolean) => void;
    onDirtyStateChange?: (isDirty: boolean) => void;
    hasAttemptedSave?: boolean;
}

export default function Form<Values>(props: React.PropsWithChildren<FormProps<Values>>) {
    const formikProps: FormikConfig<Values> = ((props: FormProps<Values>) => {
      const p: any = {...props};
      p.onSubmit = () => {};
      delete p.disabled;
      delete p.onValuesChange;
      delete p.onErrorStateChange;
      return p;
    })(props);

    return (
        <Formik
            {...formikProps}
        >
            {({ values, errors, dirty, submitForm, submitCount }) => {
                props.hasAttemptedSave && submitCount === 0 && submitForm();
                props.onDirtyStateChange && props.onDirtyStateChange(dirty);
                props.onValuesChange && props.onValuesChange(values);
                const hasErrors = Object.values(errors).filter(Boolean).length > 0;
                props.onErrorStateChange && props.onErrorStateChange(hasErrors);

                return props.children
            }}
        </Formik>
    )
}
