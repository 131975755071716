import HttpService from "@gsb/react-http";
import {SalesChannel} from "../models/SalesChannel";
import {environment} from "../env";

export interface SalesChannelRepo {
    getSalesChannelForCurrentDomain(): Promise<SalesChannel>
}

export class HttpSalesChannelRepo implements SalesChannelRepo {

    constructor(private http: HttpService) {}

    getSalesChannelForCurrentDomain(): Promise<SalesChannel> {
        return this.getSalesChannelById(environment.salesChannelIdentifier);
    }

    private getSalesChannelById(id: string): Promise<SalesChannel> {
        return this.http.get(['mk', 'v1', 'sales-channels', id]);
    }
}
