import TabActionBar, {TabActionBarProps} from "./TabActionBar";
import React, {useMemo} from "react";
import {Typography} from "@material-ui/core";

interface Props extends TabActionBarProps {
    title: string;
}

const TabDetail: React.FunctionComponent<Props> = ({ title, leading, center, trailing, children }) => {

    const shouldShowActionBar = useMemo(() => !!leading || !!center || !!trailing, [leading, center, trailing]);

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h5' style={{ alignSelf: 'flex-start', marginBottom: 12 }}>{title}</Typography>

          {shouldShowActionBar &&
          <TabActionBar leading={leading} center={center} trailing={trailing}/>
          }

          <div>
              {children}
          </div>
      </div>
    )
};

export default TabDetail;