import {AssignLicenseToUserForCurrentCompanyModal} from "../forms/AssignLicenseToUserForm";
import * as React from "react";
import {User} from "../../../models/User";
import {useState} from "react";
import {License} from "../../../models/License";

export default function useAssignLicenseToUserForCurrentCompanyModal(onLicenseAssigned: (license: License) => void,
                                                                     onPurchaseLicenseClicked: () => void) {

    const [user, setUser] = useState<User>();
    const close = () => setUser(undefined);

    const modal = user ? (
        <AssignLicenseToUserForCurrentCompanyModal
            open={true}
            onClose={close}
            onLicenseAssigned={(license) => {
                close();
                onLicenseAssigned(license)
            }}
            onPurchaseLicenseClicked={() => {
                close();
                onPurchaseLicenseClicked();
            }}
            user={user}
        />
    ) : null;

    return {
        assignLicenseToUserModal: modal,
        openAssignLicenseToUserModal: (user: User) => setUser(user),
    }
}