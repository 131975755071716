import {Button, CircularProgress} from "@material-ui/core";
import * as React from "react";
import {CSSProperties} from "react";
import {useFormikContext} from "formik";
import {makeStyles} from "@material-ui/core/styles";

export type FormVariant = 'Create' | 'Update';

export interface FormSubmitButtonProps {
    label?: string;
    variant: FormVariant;
    disabled?: boolean;
    style?: CSSProperties;
}

export function FormSubmitButton({ label, variant, disabled, style }: FormSubmitButtonProps) {
    const { isSubmitting, isValid, dirty } = useFormikContext();
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Button variant='contained'
                    style={style}
                    color={variant === 'Create' ? 'secondary' : 'primary'}
                    type='submit'
                    disabled={disabled || isSubmitting || !dirty || !isValid}>{label || variant}</Button>
            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>

    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));
