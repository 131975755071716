import State from "../models/State";
import HttpService from "@gsb/react-http";

export interface StateRepo {
  list(): Promise<State[]>
}

export class HttpStateRepo implements StateRepo {

	constructor(private http: HttpService) {}
  
	list(): Promise<State[]> {
		return this.http.get(['mk', 'v1', 'states']);
	}
} 
