import {Brand, environment} from "../env";
import {AuthConfig} from "@gsb/react-auth";

const maxDropdownSize = 1000;

interface Regex {
    phoneNumber: RegExp
}

export interface Config {
    copyright: string;
    brandName: string;
    supportEmail: string;
    termsOfServiceUrl: string;
    privacyPolicyUrl: string;
    supportPhoneNumber: string;
    logoUrl: string;
    auth: AuthConfig;
    applicationIdentifier: string;
    maxDropdownSize: number;
    regex: Regex,
    googleAnalyticsKey: string;
    oneTrustKey: string,
    externalManageAccountUrl?: string,
    topicIds: { erc: string, taxAccounting: string, gsbFeatures: string },
    dashboardUrl: string
}

const regex: Regex = {
    phoneNumber: new RegExp('^\\s*(?:\\+?(\\d{1,3}))?([-. (]*(\\d{3})[-. )]*)?((\\d{3})[-. ]*(\\d{2,4})(?:[-.x ]*(\\d+))?)\\s*$'),
};

let configuration: Config;

let auth: AuthConfig = {
    clientId: environment.clientID,
    authorityUrl: environment.authorityUrl,
    passwordResetUrl: environment.passwordResetUrl,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
    redirectUri: environment.redirectUri,
    scopes: ["openid", "profile", "email"],
};

const copyright = `Copyright ${new Date().getFullYear()} © Tarkenton Companies.`;

if (environment.brand === Brand.upnetic) {
    auth.domainHints = ['marketing.adp.com'];
    configuration = {
        auth,
        copyright: copyright,
        brandName: "Upnetic",
        supportEmail: "support@upnetic.com",
        termsOfServiceUrl: "https://upnetic.com/terms/",
        privacyPolicyUrl: "https://upnetic.com/privacy-policy/",
        supportPhoneNumber: '1.888.303.6789',
        logoUrl: "/upnetic/img/upnetic_logo.png",
        applicationIdentifier: 'ask',
        maxDropdownSize,
        regex,
        googleAnalyticsKey: 'UA-130101743-6',
        oneTrustKey: '874afcde-58f7-4f07-b0bc-06029d8dfa69',
        topicIds: { erc: 'dfdffd72-47fc-4211-9595-1d07ade24c50', taxAccounting: 'e1bd1fc2-5a2d-49ce-a16f-0fb0bd6e3144', gsbFeatures: 'fea84587-a4aa-4aaf-bb59-afff55828b3d' },
        dashboardUrl: environment.dashboardUrl
    }
} else {
    configuration = {
        auth,
        copyright: copyright,
        brandName: "GoSmallBiz",
        supportEmail: "support@gosmallbiz.com",
        termsOfServiceUrl: "https://gosmallbiz.com/terms/",
        privacyPolicyUrl: "https://gosmallbiz.com/privacy/",
        supportPhoneNumber: '866.467.6249',
        logoUrl: "/gsb/img/logo.png",
        applicationIdentifier: 'ask',
        maxDropdownSize,
        regex,
        googleAnalyticsKey: 'UA-178659494-4',
        oneTrustKey: 'd388d484-7012-4598-8c8f-8f15ec0c4351',
        topicIds: { erc: 'dfdffd72-47fc-4211-9595-1d07ade24c50', taxAccounting: 'e1bd1fc2-5a2d-49ce-a16f-0fb0bd6e3144', gsbFeatures: 'fea84587-a4aa-4aaf-bb59-afff55828b3d' },
        dashboardUrl: environment.dashboardUrl
    }
}

export const config = configuration;
