import {Subscription} from "../../../../models/Subscription";
import StripeSubscriptionDetail from "./StripeSubscriptionDetail";
import NoChargeSubscriptionDetail from "./NoChargeSubscriptionDetail";
import * as React from "react";
import AppleAppStoreSubscriptionDetail from "./AppleAppStoreSubscriptionDetail";
import InvoiceDetail from "./InvoiceDetail";

interface Props {
    subscription: Subscription;
}

export function SubscriptionSourceDetail({ subscription }: Props) {
    if (subscription.stripeSource) {
        return <StripeSubscriptionDetail stripeSubscriptionSource={subscription.stripeSource}/>
    } else if (subscription.noChargeSource) {
        return <NoChargeSubscriptionDetail noChargeSource={subscription.noChargeSource}/>
    } else if (subscription.appleAppStoreSource) {
        return <AppleAppStoreSubscriptionDetail appleAppStoreSource={subscription.appleAppStoreSource}/>
    } else if (subscription.invoice) {
        return <InvoiceDetail invoice={subscription.invoice}/>
    } else {
        return null;
    }
}
