import {default as React, useState} from "react";
import {config} from "../../../config";
import {withModal} from "../../modal";

export function PurchaseLicenses() {
    return (
        <div>
            To add or remove licenses from your subscription, please contact our support staff at {config.supportPhoneNumber}
        </div>
    )
}

const PurchaseLicensesModal = withModal(PurchaseLicenses, 'Purchase Licenses');

export function usePurchaseLicensesModal() {
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = (
        <PurchaseLicensesModal open={open} onClose={close}/>
    );

    return {
        purchaseLicensesModal: modal,
        openPurchaseLicensesModal: () => setOpen(true),
    }
}
