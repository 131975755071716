import * as React from "react";
import {default as MaterialSelect, SelectProps as MaterialSelectProps} from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import {ErrorMessage, useField} from "formik";
import {FormControl} from "@material-ui/core";
import {useEffect, useRef, useState} from "react";
import {defaultMargins, pickerStyle} from "../styles/field";

interface PickerOption {
    id: string;
    label: string;
}

export interface PickerPropsWithoutOptions {
    name: string;
    label?: string;
    disabled?: boolean;
    allowEmpty?: boolean;
    emptyValue?: string | number | null;
    emptyValueDescription?: string;
    SelectProps?: MaterialSelectProps;
}

export interface PickerProps extends PickerPropsWithoutOptions {
    options: PickerOption[];
}

export default function PickerInput(props: PickerProps) {
    const [field, meta] = useField(props.name);

    const {options, disabled, name, label, allowEmpty, emptyValue, emptyValueDescription, SelectProps } = props;

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    return (
        <FormControl disabled={disabled} style={defaultMargins} variant='outlined' error={!!meta.error && meta.touched}>
            <InputLabel ref={inputLabel} htmlFor={name}>
                {label || ''}
            </InputLabel>
            <MaterialSelect
                labelWidth={labelWidth}
                style={pickerStyle}
                inputProps={{
                    id: name,
                }}
                {...field}
                {...SelectProps}
            >
                {allowEmpty &&
                <MenuItem key={'empty'} value={emptyValue || ''}>
                    {emptyValueDescription || 'None'}
                </MenuItem>
                }
                {options.map(o =>
                    <MenuItem key={o.id} value={o.id}>
                        {o.label}
                    </MenuItem>
                )}
            </MaterialSelect>
            <ErrorMessage name={name}/>
        </FormControl>
    );
}
