import * as React from 'react';
import {Button, createStyles, makeStyles, Theme} from '@material-ui/core';
import Topic from "../../models/Topic";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import Question from "../../models/Question";
import {Routes} from "../../Routes";
import Title from "../Title";
import Tips from "../Tips";
import {config} from "../../config";
import UserInfo from "../../models/UserInfo";
import TopicPicker, {TopicViewModel} from "./../TopicPicker";
import BackButton from "./../BackButton";
import {useRepo} from "../../repos/useRepo";
import AskQuestionForm from "./AskQuestionForm";
import LoadingIndicator from "../LoadingIndicator";
import useUpdateCompanyBackgroundDialog from "../CompanyBackgroundDialog";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
        overflow: "auto"
    },
    content: {
        margin: "0 auto",
        maxWidth: "1600px",
        padding: "36px",
    },
    section: {
        marginTop: "32px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    formSection: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginRight: "24px",
        minWidth: 300
    },
    faqSection: {
        flex: 1,
        margin: "0 auto",
        maxWidth: 600,
        minWidth: 300,
        textAlign: "center"
    },
    formControl: {
        marginBottom: theme.spacing(1)
    },
    topicSelect: {
        alignSelf: "flex-start",
        width: "200px",
        background: theme.palette.grey[100],
    },
    questionTextField: {
        width: "100%",
        color: theme.palette.secondary.light,
        background: theme.palette.grey[100],
    },
    submitButtonWrapper: {
        alignSelf: "flex-end",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "18px"
    },
    submitButton: {
    },
    submittedQuestionContainer: {
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    submittedQuestionCard: {

    },
    input: {
        background: theme.palette.grey[100],
    },
    submitButtonProgressSpinner: {
        marginRight: "12px",
        marginBottom: "6px"
    },
    cssFocused:{

    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: theme.palette.secondary.light
        },
        '&:hover $notchedOutline':{
            borderColor: theme.palette.secondary.light
        }
    },
    notchedOutline: {
    },
    bottomSection: {
        display: "flex",
        alignItems: "center"
    },
    emailFieldWrapper: {
        alignSelf: "start"
    },
    emailDescription: {
        flex: 1
    },
    emailSection: {
        paddingLeft: "4px",
        marginTop: "6px",
        marginBottom: "10px"
    },
    emailTextField: {
        color: theme.palette.secondary.light,
        background: theme.palette.grey[100],
        marginLeft: "14px"
    },
    defaultEmailSpan: {
        fontWeight: 700,
        fontSize: "small"
    },
    errorLabel: {

    }
}));

interface Params {
    previousQuestionId?: string;
}

interface Props extends RouteComponentProps<Params> {
    userInfo?: UserInfo;
}

function Ask({ userInfo, history, location }: Props) {
    const { questionRepo } = useRepo();
    const classes = useStyles();
    const [submittedQuestion, setSubmittedQuestion] = useState<Question>();
    const previousQuestionId = useMemo(() => new URLSearchParams(location.search).get("questionId"), [location.search]);
    const [previousQuestion, setPreviousQuestion] = useState<Question>();

    useEffect(() => {
        if (previousQuestionId) {
            questionRepo.getQuestion(previousQuestionId).then(setPreviousQuestion).catch(console.error);
        }
    }, [previousQuestionId]);

    const onHomePressed = () => {
        history.push(Routes.myQuestions)
    };

    const onTopicSelected = (topic: TopicViewModel) => {
        history.push(Routes.viewFaqsForTopicWithId(topic.id));
    };

    const { renderCompanyProfileDialog } = useUpdateCompanyBackgroundDialog(onHomePressed);

    const content = submittedQuestion
        ? <SubmittedQuestion question={submittedQuestion} onHomePressed={onHomePressed}/>
        : <QuestionSection previousQuestion={previousQuestion}
                           userInfo={userInfo}
                           onTopicSelected={onTopicSelected}
                           onQuestionAsked={setSubmittedQuestion}/>;

    return (
        <div className={classes.root}>

            <Title title={"Ask a Consultant"}/>

            <div className={classes.content}>

                {!submittedQuestion && <BackButton onClick={onHomePressed}/>}

                {content}

            </div>

            {renderCompanyProfileDialog}
        </div>
    )
}

function QuestionSection(props: { previousQuestion?: Question, userInfo?: UserInfo, onTopicSelected: (topic: TopicViewModel) => void, onQuestionAsked: (question: Question) => void }) {
    const {userInfo, previousQuestion, onTopicSelected, onQuestionAsked} = props;
    const classes = useStyles();

    return (
        <div className={classes.section}>

            <AskQuestionForm previousQuestion={previousQuestion} userInfo={userInfo} onQuestionAsked={onQuestionAsked}/>

            <FaqSection onTopicSelected={onTopicSelected}/>

            <Tips/>
        </div>

    );
}

function FaqSection(props: { onTopicSelected: (topic: TopicViewModel) => void }) {
    const classes = useStyles();
    const { onTopicSelected } = props;
    const { topicRepo } = useRepo();
    const [topics, setTopics] = useState<Topic[]>();

    useEffect(() => {
        topicRepo.listTopics().then(setTopics).catch(console.error)
    }, [topicRepo]);

    const sanitizedTopics = useMemo(() => {
        if (topics) {
            return topics
                .filter(topic => topic.isFeatured && !!topic.iconUrl)
                .map(topic => {
                    const vm: TopicViewModel = {
                        id: topic.id,
                        title: topic.name,
                        iconUrl: topic.iconUrl
                    };

                    return vm;
                })
        } else {
            return null;
        }
    }, [topics]);

    const content = sanitizedTopics ? <TopicPicker topics={sanitizedTopics} onTopicSelected={onTopicSelected}/> : <LoadingIndicator/>;

    return (
        <div className={classes.faqSection}>
            <h2>Frequently Asked Questions</h2>
            {content}
        </div>
    )
}

function SubmittedQuestion(props: { question: Question, onHomePressed: () => void }) {
    const classes = useStyles();
    const { question, onHomePressed } = props;

    return (
        <div className={classes.root}>
            <div className={classes.submittedQuestionContainer}>

                <h1>{config.brandName} Small Business Advisors</h1>

                <p>Thank you, your question has been received. You should receive an email confirmation shortly.</p>

                <h3>The question you submitted was:</h3>

                <p>{question.body}</p>

                <HomeButton onHomePressed={onHomePressed}/>

            </div>

        </div>
    );
}

function HomeButton(props: { onHomePressed: () => void }) {
    return <Button
        onClick={() => props.onHomePressed()}
        variant="contained"
        color="primary">
        Return home
    </Button>
}


export default withRouter(Ask);
