import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Faq from "../models/Faq";
import striptags from "striptags";
import truncate from 'truncate';
import HTML from "./form/HTML";

interface Props {
    faq: Faq,
    onSelected: (faq: Faq) => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: "15px",
        margin: "6px",
        borderColor: theme.palette.grey[300],
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "6px",
        '&:hover': {
            borderColor: theme.palette.secondary.light
        }
    },
    preview: {
        marginTop: "8px",
}
}));

export default function FaqListItem(props: Props) {
    const classes = useStyles();
    const { faq } = props;

    const title = faq.question;
    const preview = truncate(striptags(faq.answer), 300);

    return (
        <div className={classes.container} onClick={() => props.onSelected(props.faq)}>

            <b>{title}</b>

            {preview &&
            <div className={classes.preview}>
                <HTML rawHtml={preview}/>
            </div>
            }

        </div>
    );
}