import React, {ReactNode} from 'react';
import { RouteComponentProps, withRouter} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {config} from "../../config";

interface Props extends RouteComponentProps {
    children: ReactNode
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: "0 auto",
        alignContent: "center",
        width: "100%"
    },
    content: {
        borderTopColor: theme.palette.grey[300],
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "11px 4%",
        maxWidth: "1200px",
        margin: "0 auto"
    }
}));


function Footer(props: Props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                {props.children}
            </div>
        </div>
    );
}

export default withRouter(Footer);
