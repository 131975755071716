import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        width: "100%",
        height: "100%",
        flex: 1,
        alignSelf: "stretch",
        padding: "16px 32px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    },
    progress: {
    },
}));

export default function LoadingIndicator(props: { size?: number }) {
    const { size } = props;
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <CircularProgress size={size || 50}
                              className={classes.progress}
                              color="secondary" />
        </div>
    )
}
