import React from 'react';
import { Modal as MaterialModal, Paper, Typography, BackdropProps} from "@material-ui/core";

export interface ModalProps {
    title?: string;
    open: boolean;
    paperStyle?: React.CSSProperties;
    rootStyle?: React.CSSProperties;
    backdropProps?: BackdropProps;
    onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ title, open, onClose, children, paperStyle = {}, backdropProps = {}, rootStyle = {} }) => {
    return (
        <MaterialModal
          open={open}
          onClose={onClose}
          style={rootStyle}
          BackdropProps={{ open, ...backdropProps}}
        >
            <div onClick={onClose}
                 style={{ width: '100vw', height: '100vh', paddingTop: 24, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <Paper onClick={(event) => event.stopPropagation()}
                       style={{
                         maxWidth: '80vw',
                         minWidth: 200,
                         padding: 24,
                         margin: 'auto',
                         ...paperStyle,
                        }}>
                    {title &&
                    <Typography style={{ marginBottom: 12 }}>{title}</Typography>
                    }
                    <div style={{ width: '100%' }}>
                        {children}
                    </div>
                </Paper>
            </div>
        </MaterialModal>
    )
};

export default Modal;