import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import React, {useEffect, useMemo, useState} from "react";
import {User} from "../../../models/User";
import {useRepo} from "../../../repos/useRepo";

interface Props extends PickerPropsWithoutOptions {
    users: User[];
}

export default function UserPicker(props: Props) {
    const { users } = props;
    const userOptions = useMemo(() => users.map(u => ({ id: u.id, label: `${u.firstName} ${u.lastName}`})), [users]);

    const PickerProps = ((props: Props): PickerPropsWithoutOptions => {
        const p = {...props};
        delete p.users;
        return p;
    })(props);

    return <PickerInput
        name={props.name || "userId"}
        label={props.label || "User"}
        options={userOptions}
        {...PickerProps}
    />
}

export function CurrentCompanyUserPicker(props: PickerPropsWithoutOptions) {
    const { companyRepo } = useRepo();

    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        companyRepo.listUsersForCompanyById('current').then(setUsers).catch(console.warn);
    }, [companyRepo]);

    return <UserPicker users={users} {...props} />
}