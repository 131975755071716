export type BusinessType = {displayName: string, value: string};

export interface BusinessTypeRepo {
  list(): Promise<BusinessType[]>
}

export class HardcodedBusinessTypeRepo implements BusinessTypeRepo {
  private options = [
    {displayName: "Business-to-Business (B2B)", value: "B2B"},
    {displayName: "Business-to-Consumer (B2C)", value: "B2C"}
  ];

  async list(): Promise<BusinessType[]> {
    return this.options
  }
}
