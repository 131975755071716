import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "./CompanyBackgroundDialog.css";
import {UpdateCompanyProfileBody, CompanyProfile, isValidCompanyProfile} from "../models/CompanyProfile";
import {companyProfileSchema, initialValuesForCompanyProfile} from "../utils/schemas/company-schema";
import IndustryPicker from "./form/pickers/IndustryPicker";
import BusinessSizePicker from "./form/pickers/BusinessSizePicker";
import EntityTypePicker from "./form/pickers/EntityTypePicker";
import BusinessTypePicker from "./form/pickers/BusinessTypePicker";
import StatePicker from "./form/pickers/StatePicker";
import TextInput from "./form/inputs/TextInput";
import Form from "./form/Form";
import {useRepo} from "../repos/useRepo";

interface Props {
  isOpen: boolean
  onSave: (profile: UpdateCompanyProfileBody) => Promise<void>;
  onBack: () => void;
  existingProfile?: CompanyProfile;
}

function CompanyBackgroundDialog(props: Props) {
  const [shouldShakeSave, setShouldShakeSave] = useState(false);
  const [hasAttemptedSave, setHasAttemptedSave] = useState(false);

  const classes = useStyles({ shouldShakeSave });

  function onSave() {
    setHasAttemptedSave(true);

    if (hasErrors) {
      setShouldShakeSave(true);
      return;
    }

    if (!draft) {
      console.error("onSave called but no draft is present.");
      return;
    }

    props.onSave(draft);
  }

  const [isDirty, setIsDirty] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [draft, setDraft] = useState<CompanyProfile>();

  const { existingProfile } = props;

  return (
      <Dialog
          open={props.isOpen}
          disableEnforceFocus={true}
      >
        <DialogTitle id="form-dialog-title">Update Background</DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
          <DialogContentText>
            To help us deliver the best consulting experience, please provide the following background information about
            your company
          </DialogContentText>
          <Form<UpdateCompanyProfileBody>
              enableReinitialize={true}
              onValuesChange={setDraft}
              onErrorStateChange={setHasErrors}
              onDirtyStateChange={setIsDirty}
              hasAttemptedSave={hasAttemptedSave}
              initialValues={initialValuesForCompanyProfile(existingProfile)}
              validationSchema={companyProfileSchema}
          >
            <IndustryPicker name='industryId'/>

            <BusinessSizePicker name='businessSize'/>

            <TextInput name={"yearFounded"} label={"Year Founded"} type="number"/>

            <EntityTypePicker name='entityType'/>

            <BusinessTypePicker name='businessType'/>

            <StatePicker name='stateCode'/>

          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onBack} color="primary">
            Back
          </Button>
          <Button
              className={classes.saveButton}
              onClick={onSave}
              disabled={hasErrors || !isDirty}
              color="primary"
              onAnimationEnd={() => setShouldShakeSave(false)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      saveButton: (props: any) => ({
        position: "relative",
        animationName: props.shouldShakeSave ? "shakeAnim" : undefined,
        animationDuration: "5s",
        animationTimingFunction: "ease-in",
      })
    }),
);

export default function useUpdateCompanyBackgroundDialog(onBack: () => void) {

  const { companyProfileRepo } = useRepo();
  const [companyBackgroundDialogOpen, setCompanyBackgroundDialogOpen] = useState(false);
  const [companyProfile, setCompanyProfile] = useState<CompanyProfile>();

  useEffect(() => {
    companyProfileRepo.getCurrent().then(companyProfile => {
      setCompanyProfile(companyProfile);

      if (!companyProfile || !isValidCompanyProfile(companyProfile)) {
        setCompanyBackgroundDialogOpen(true);
      }
    }).catch(console.error);
  }, []);

  async function updateCompanyProfile(profile: UpdateCompanyProfileBody) {
    try {
      const updated = await companyProfileRepo.updateCurrent(profile);
      setCompanyBackgroundDialogOpen(false);
      setCompanyProfile(updated);
    } catch (err) {

    } finally {

    }
  }

  const dialog = <CompanyBackgroundDialog
      isOpen={companyBackgroundDialogOpen}
      onBack={onBack}
      onSave={updateCompanyProfile}
      existingProfile={companyProfile}
  />;

  return {
    renderCompanyProfileDialog: dialog
  }
}

