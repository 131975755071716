import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import Topic from "../../../models/Topic";

export default function TopicPicker(props: PickerPropsWithoutOptions) {
    const { topicRepo } = useRepo();

    const [topics, setTopics] = useState<Topic[]>([]);

    useEffect(() => {
        topicRepo.listTopics().then(setTopics).catch(console.error);
    }, [topicRepo]);

    const topicOptions = useMemo(() => topics.sort((a,b) => 
        (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 
        (b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0)
        .map(c => ({ id: c.id, label: c.name })), [topics]);

    return <PickerInput
        name={props.name || "topicId"}
        label={props.label || "Topic"}
        options={topicOptions}
        {...props}
    />
}
