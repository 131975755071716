import {Subscription} from "../../../../models/Subscription";
import {default as React, useMemo, useState} from "react";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {
    Button, createStyles,
    Divider,
    ExpansionPanel,
    ExpansionPanelActions,
    ExpansionPanelDetails,
    ExpansionPanelSummary, makeStyles, Theme
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SubscriptionStatusBadge from "../../../SubscriptionStatusBadge";
import SubscriptionDetail from "./SubscriptionDetail";
import SubscriptionViewModel from "../../../../models/vms/SubscriptionViewModel";

export default function SubscriptionRow(props: { subscription: Subscription, onCancelSubscription: (subscription: Subscription) => void }) {
    const [expanded, setExpanded] = useState(false);
    const { subscription, onCancelSubscription } = props;
    const classes = useStyles();

    const vm = useMemo(() => SubscriptionViewModel.fromSubscription(subscription), [subscription]);

    const { can } = useRbac();
    const canCancelSubscriptionForCompany = can(actions.company.cancelSubscription);

    return (
        <ExpansionPanel expanded={expanded} onChange={() => setExpanded(!expanded)} TransitionProps={{ unmountOnExit: true }} >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.panelSummary }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>

                    <div style={{ width: 72 }}>
                        {subscription.status &&
                        <SubscriptionStatusBadge status={subscription.status} style={{ alignSelf: 'center' }}/>
                        }
                    </div>

                    <p style={{ alignSelf: 'center', margin: 12 }}>{vm.productName}</p>

                    <p className={classes.secondaryHeading} style={{ alignSelf: 'center', margin: 12 }}>{vm.description}</p>

                </div>
            </ExpansionPanelSummary>
            <Divider />
            <ExpansionPanelDetails>
                <SubscriptionDetail subscription={subscription}/>
            </ExpansionPanelDetails>

            {canCancelSubscriptionForCompany && subscription.isActive &&
            <>
              <Divider/>

              <ExpansionPanelActions>
                <Button onClick={() => onCancelSubscription(subscription)} size='small'>Cancel</Button>
              </ExpansionPanelActions>
            </>
            }

        </ExpansionPanel>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        panelSummary: {
            backgroundColor: theme.palette.background.paper,
        },
        secondaryHeading: {
            marginLeft: theme.spacing(1),
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    }),
);


