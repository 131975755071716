import LoadingIndicator from "../LoadingIndicator";
import React from "react";

export default function AuthCallback() {
    return (
        <div style={{ margin: 'auto' }}>
            <LoadingIndicator/>
        </div>
    )
}
