import {StripeSubscriptionSource} from "../../../../models/Subscription";
import StripeSubscriptionSourceItemTable from "./StripeSubscriptionSourceItemTable";
import * as React from "react";

interface Props {
    stripeSubscriptionSource: StripeSubscriptionSource
}

export default function StripeSubscriptionDetail({ stripeSubscriptionSource }: Props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StripeSubscriptionSourceItemTable stripeSubscriptionSource={stripeSubscriptionSource}/>
        </div>
    )
}
