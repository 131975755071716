import * as React from "react";
import _ from "lodash";
import {useEffect, useState} from "react";
import {useRepo} from "../../../../repos/useRepo";
import { Subscription } from "../../../../models/Subscription";
import {Button, Typography} from "@material-ui/core";
import TabDetail from "../TabDetail";
import LoadingIndicator from "../../../LoadingIndicator";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {useCancelSubscriptionModal} from "../../modal/useCancelSubscriptionModal";
import {usePurchaseLicensesModal} from "../../modal/usePurchaseLicenseModal";
import SubscriptionRow from "./SubscriptionRow";
import { useNavigation } from "../../../../services/navigation/useNavigation";

type EmptyStateMessage = {
  isEmpty?: boolean;
  onClick: () => void;
}

const StateMessage = ({ onClick, isEmpty}: EmptyStateMessage) => isEmpty
  ? <EmptySubscriptionsMessage onClick={onClick} />
  : <InactiveSubscriptionsMessage onClick={onClick} />

const InactiveSubscriptionsMessage = ({ onClick }: EmptyStateMessage) => <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: 48, paddingBottom: 20 }}>
<Typography style={{ alignSelf: 'center', marginRight: "12px" }}>
  Looks like you don't have an active subscription!</Typography>
  <div>
    <Button color='primary'
      variant='contained'
      style={{ alignSelf: 'flex-end' }}
      onClick={onClick}>Start a Subscription</Button>
  </div>
</div>

const EmptySubscriptionsMessage = ({ onClick }: EmptyStateMessage) => <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 48 }}>
<Typography style={{ textAlign: 'center' }}>
  Looks like you don't have a subscription!</Typography>
  <div>
    <Button color='primary'
      variant='contained'
      style={{ alignSelf: 'flex-end', marginTop: 16 }}
      onClick={onClick}>Start a Subscription</Button>
  </div>
</div>

export function SubscriptionTab() {
    const { companyRepo, salesChannelRepo } = useRepo();
    const { can } = useRbac();
    const { navigateToUrl } = useNavigation();

    const [subscriptions, setSubscriptions] = useState<Subscription[]>();

    const startSubscriptionClick = () => {
        salesChannelRepo.getSalesChannelForCurrentDomain()
            .then(salesChannel => {
                navigateToUrl(salesChannel.salesDomain)
            })
            .catch(console.error)
    }

    const { cancelSubscriptionModal, openCancelSubscriptionModal } = useCancelSubscriptionModal();
    const { purchaseLicensesModal, openPurchaseLicensesModal } = usePurchaseLicensesModal();

    const canPurchaseSubscription = can(actions.company.createStripeCheckoutSession);

    const reloadData = () => {
        companyRepo.listSubscriptionsForCompanyById('current')
          .then(setSubscriptions)
          .catch(console.error)
    };

    useEffect(reloadData, []);

    if (subscriptions) {
        const hasActiveSubscription = subscriptions?.some(subscription => subscription.isActive) || false;
        const shouldShowPurchaseLicensesButton = canPurchaseSubscription && hasActiveSubscription
        const shouldDisplayMessage = _.isEmpty(subscriptions) || !hasActiveSubscription

        return (
            <>
                <TabDetail title='Subscription'
                           trailing={shouldShowPurchaseLicensesButton && (
                               <Button color='secondary'
                                       variant='contained'
                                       style={{ alignSelf: 'flex-end' }}
                                       onClick={openPurchaseLicensesModal}>Purchase Licenses</Button>
                           )}>

                    {shouldDisplayMessage && (
                        <StateMessage onClick={startSubscriptionClick} isEmpty={_.isEmpty(subscriptions)} />
                    )}

                    {subscriptions.map((subscription, index) => (
                        <SubscriptionRow key={`subscription-row-${index}`}
                                         subscription={subscription}
                                         onCancelSubscription={openCancelSubscriptionModal}/>
                    ))}
                </TabDetail>

                {cancelSubscriptionModal}
                {purchaseLicensesModal}
            </>
        );
    } else {
        return <LoadingIndicator/>
    }
}
