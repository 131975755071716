import * as React from 'react';
import {withRouter, RouteComponentProps} from "react-router-dom";
import {useServices} from "../services/useServices";
import {Routes} from "../Routes";

function Impersonation(props: RouteComponentProps) {
  const { authService } = useServices();
  const params = new URLSearchParams(props.location.search);
  const objectId = params.get('objectId') || undefined;

  authService.loginRedirectImpersonation({ redirectUri: Routes.myQuestions, isImpersonatingUser: true}, objectId);

  return null;
}

export default withRouter(Impersonation);
