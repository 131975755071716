import React, {useEffect, useState} from "react";
import {RbacProvider} from "@gsb/react-rbac";
import {useRepo} from "./repos/useRepo";
import {grants} from "./rbac";
import { withRouter } from "react-router-dom";
import {User} from "./models/User";
import {StartupProvider} from "./services/startup/useStartupResult";
import {SalesChannelApplication} from "./models/SalesChannelApplication";
import LoadingIndicator from "./components/LoadingIndicator";
import {useAuthState} from "./services/auth/useAuthState";
import {SalesChannel} from "./models/SalesChannel";
import {Reseller} from "./models/Reseller";

interface Props {

}

const AppStartup: React.FC<Props> = ({ children }) => {
    const { userRepo } = useRepo();
    const { isAuthenticated, setCurrentUser } = useAuthState();

    const [applications, setApplications] = useState<SalesChannelApplication[]>();
    const [user, setUser] = useState<User>();
    const [salesChannel, setSalesChannel] = useState<SalesChannel>();
    const [reseller, setReseller] = useState<Reseller>();
    const [roles, setRoles] = useState<string[]>();
    const [licenses, setLicenses] = useState<string[]>();

    const loadStartupResult = () => {
        userRepo.getCurrentUser().then(user => {
            const flattenedRoles = user.roles ? user.roles.map(role => role.identifier) : [];
            const flattedLicenses = user.licenses ? user.licenses.map(l => l.application!.identifier) : [];
            setCurrentUser(user);
            setRoles(flattenedRoles);
            setLicenses(flattedLicenses);
            setUser(user);
            setSalesChannel(user.salesChannel);
            setReseller(user.reseller);
            setApplications(user.applications || []);
        }).catch(console.error);
    };

    useEffect(() => {
        if (isAuthenticated === true) {
            loadStartupResult();
        }
    }, [isAuthenticated]);

    if (user && roles && licenses && applications && salesChannel && reseller) {
        return (
            <>
                <RbacProvider grants={grants} roleProvider={() => roles} onEvent={() => {}}>
                    <StartupProvider result={{ user, roles, licenses, applications, salesChannel, reseller, refresh: loadStartupResult }}>
                        {children}
                    </StartupProvider>
                </RbacProvider>
            </>
        )
    } else {
        return <LoadingIndicator/>
    }
};

export default withRouter(AppStartup);
