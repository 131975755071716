function sanitizedValue<T extends any>(value: T): any {
    if (value === null || value === undefined) {
        return value;
    } else if (typeof value === 'object') {
        for (let [k, v] of Object.entries(value)) {
            value[k] = sanitizedValue(v)
        }

        return value;

    } else if (typeof value === "string") {
        return value === '' ? null : value;
    } else {
        return value;
    }
}

export function sanitize<T extends any>(object: T): T {
    return sanitizedValue(object) as T;
}