import DateFormatter, {DateFormat} from "../../utils/DateFormatter";
import SubscriptionModel from "../domain/SubscriptionModel";
import {Subscription} from "../Subscription";

export default class SubscriptionViewModel {

    static fromSubscription(subscription: Subscription): SubscriptionViewModel {
        const model = new SubscriptionModel(subscription);
        return new SubscriptionViewModel(model);
    }

    constructor(private _subscriptionModel: SubscriptionModel) {}

    get formattedRenewsAt(): string | null {
        if (this._subscriptionModel.subscription.expiresAt) {
            return  DateFormatter.formatDate(this._subscriptionModel.subscription.expiresAt, DateFormat.slashDelimitedDate);
        } else {
            return null;
        }
    }

    get formattedExpiresAt(): string | null {
        if (this._subscriptionModel.subscription.expiresAt) {
            return  DateFormatter.formatDate(this._subscriptionModel.subscription.expiresAt, DateFormat.slashDelimitedDate);
        } else {
            return null;
        }
    }

    get formattedPurchasedAt(): string | null {
        if (this._subscriptionModel.subscription.purchasedAt) {
            return  DateFormatter.formatDate(this._subscriptionModel.subscription.purchasedAt, DateFormat.slashDelimitedDate);
        } else {
            return null;
        }
    }

    get formattedActivatedAt(): string | null {
        if (this._subscriptionModel.subscription.activatedAt) {
            return  DateFormatter.formatDate(this._subscriptionModel.subscription.activatedAt, DateFormat.slashDelimitedDate);
        } else {
            return null;
        }
    }

    get formattedCanceledAt(): string | null {
        if (this._subscriptionModel.subscription.canceledAt) {
            return  DateFormatter.formatDate(this._subscriptionModel.subscription.canceledAt, DateFormat.slashDelimitedDate);
        } else {
            return null;
        }
    }

    get expiresAtDescription(): string | null {
        if (this._subscriptionModel.subscription.expiresAtDescription) {
            return this._subscriptionModel.subscription.expiresAtDescription;
        } else if (this._subscriptionModel.subscription.expiresAt) {
            const formattedExpiry = DateFormatter.formatDate(this._subscriptionModel.subscription.expiresAt, DateFormat.slashDelimitedDate);
            const action = this._subscriptionModel.isExpired ? 'Expired' : 'Expires';
            return `${action} on ${formattedExpiry}`;
        } else {
            return null
        }
    };

    get purchasedAtDescription(): string {
        const startedAt = this._subscriptionModel.subscription.purchasedAt || this._subscriptionModel.subscription.createdAt;
        return DateFormatter.formatDate(startedAt, DateFormat.slashDelimitedDate);
    };

    get renewsAtDescription(): string | null {
        if (this._subscriptionModel.subscription.renewsAtDescription) {
            return this._subscriptionModel.subscription.renewsAtDescription
        } else if (this._subscriptionModel.subscription.renewsAt) {
            return DateFormatter.formatDate(this._subscriptionModel.subscription.renewsAt, DateFormat.slashDelimitedDate);
        } else {
            return null;
        }
    };

    get description(): string {
        const subscription = this._subscriptionModel.subscription;
        return subscription.statusReason || this.expiresAtDescription || this.renewsAtDescription || this.purchasedAtDescription;
    }

    get productName(): string | null {
        return this._subscriptionModel.product?.name || null;
    }
}