import React, {useContext, createContext, } from 'react';
import {UrlTargetType} from "../../config/Url";
import {SalesChannelApplication} from "../../models/SalesChannelApplication";

export interface NavigationHandler {
    navigateToRoute: (route: string) => void;
    navigateToUrl: (url: string, target?: UrlTargetType) => void;

    navigateToSalesChannelApplication: (salesChannelApplication: SalesChannelApplication) => void;
}

export class RouteNavigationHandler implements NavigationHandler {
    constructor(public navigateToRoute: (route: string) => void, public navigateToUrl: (url: string, target?: UrlTargetType) => void) {}

    navigateToSalesChannelApplication = (salesChannelApplication: SalesChannelApplication) => {
        const { urlType, urlTarget, url } = salesChannelApplication;
        switch (urlType) {
            case "subdomain":
                this.navigateToRoute(url);
                break;
            case "page":
            case "url":
                this.navigateToUrl(url, urlTarget);
                break
        }
    }
}

const NavigationContext = createContext<NavigationHandler>(null as unknown as NavigationHandler);

export const useNavigation = () => useContext(NavigationContext);

interface Props {
    handler: NavigationHandler;
}

export const NavigationProvider: React.FC<Props> = ({ children, handler }) => {
    return (
        <NavigationContext.Provider value={handler}>
            {children}
        </NavigationContext.Provider>
    );
};
