import * as Yup from "yup";
import {CreateUserRequest, UpdateUserRequest, User} from "../../models/User";
import {config} from "../../config";

const baseUserSchema = {
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phoneNumber: Yup.string()
        .matches(config.regex.phoneNumber, {
            message: "Please enter a valid phone number",
            excludeEmptyString: true })
        .nullable(),
    timeZone: Yup.string().nullable(),
}

export const updateUserSchema = Yup.object().shape({
    ...baseUserSchema
});

export const createUserSchema = Yup.object().shape({
    ...baseUserSchema,
    email: Yup.string()
        .email('Please enter a valid email')
        .required('Email is required'),
});

export const initialValuesForUserUpdate = (user: User): UpdateUserRequest => ({
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    phoneNumber: user.phoneNumber ?? '',
    timeZone: user.timeZone ?? ""
});

export const initialValuesForUserCreate = (): CreateUserRequest => ({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    timeZone: ""
});

