import * as React from "react";
import LicenseModel from "../../../models/domain/LicenseModel";
import {LicenseSection} from "./LicenseSection";

export interface Props {
    licenses: LicenseModel[];
}

export function CompanyLicenseSection({ licenses }: Props) {
    return (
        <LicenseSection title={"Company Licenses"}
                        caption={"These licenses are applied to every member of your company"}
                        licenses={licenses}/>
    )
}
