import React from 'react';
import TopicPickerItem from "./TopicPickerItem";
import {createStyles, makeStyles, Theme} from "@material-ui/core";


export interface TopicViewModel {
    id: any;
    title: string;
    iconUrl?: string;
}

interface Props {
    topics: TopicViewModel[];
    onTopicSelected: (topic: TopicViewModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: "wrap",
            justifyContent: "center",
            alignContent: "center",
        },
    }),
);

export default function TopicPicker(props: Props) {
    const classes = useStyles();
    const { topics, onTopicSelected } = props;

    return (
        <div className={classes.container}>
            {topics.map(topic => <TopicPickerItem
                key={topic.id}
                topic={topic}
                onSelected={onTopicSelected}
            />)}
        </div>
    )
}