import {Plan} from "./Plan";
import Entity from "./Entity";
import {License} from "./License";
import {Invoice} from "./Invoice";

export interface Subscription extends Entity {
    name: string;

    status: string;
    statusReason?: string;

    isActive: boolean;
    isTrial: boolean;
    isDemo: boolean;

    activatedAt?: string;
    activatedAtDescription?: string;

    purchasedAt?: string;
    purchasedAtDescription?: string;

    expiresAt?: string;
    expiresAtDescription?: string;

    renewsAt?: string;
    renewsAtDescription?: string;

    canceledAt?: string;
    canceledAtDescription?: string;

    // Foreign Keys
    stripeSourceId?: string;
    noChargeSourceId?: string;

    // Relationships
    stripeSource?: StripeSubscriptionSource;
    noChargeSource?: NoChargeSubscriptionSource;
    appleAppStoreSource?: AppleAppStoreSource;
    invoice?: Invoice;

    licenses?: License[];
}

export interface AppleAppStoreSource extends Entity {
    transactionId: string;
    originalTransactionId: string;
    appStoreProductId: string;
    planId: string;
    plan?: Plan;
}

export interface NoChargeSubscriptionSource extends Entity {
    legalShieldMemberId?: string;
    adpOrganizationId?: string;
    adpAssociateId?: string;
    adpClientId?: string;

    recordId?: string;
    planId: string;
    plan?: Plan;
}

export interface StripeSubscriptionSource extends Entity {
    stripeCustomerId: string;
    stripeSubscriptionId: string;
    items?: StripeSubscriptionSourceItem[];
}

export type Interval = "day" | "week" | "month" | "year";

export type Currency = "usd"

export interface StripeSubscriptionSourceItem extends Entity {
    stripeSubscriptionItemId: string;
    stripePlanId: string;
    planId: string;
    quantity: number;
    amount: number;
    interval: Interval;
    intervalCount: number;
    currency: Currency;
    billingScheme: string;
    plan?: Plan;
}

export class SubscriptionStatus {
    static active = "active";
    static trialing = "trialing";
    static pending = "pending";
    static failed = "failed";
    static expired = "expired";
    static canceled = "canceled";
}
