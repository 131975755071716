import * as React from "react";
import {UpdateCurrentUserForm} from "../forms/UserProfileForm";
import TabDetail from "./TabDetail";

export function ProfileTab() {
    return (
        <TabDetail title='User Profile'>
            <UpdateCurrentUserForm/>
        </TabDetail>
    );
}