import * as React from "react";
import TabDetail from "./TabDetail";
import {UpdateCurrentCompanyForm} from "../forms/CompanyProfileForm";

export function CompanyTab() {
    return (
        <TabDetail title='Company Profile'>
            <UpdateCurrentCompanyForm/>
        </TabDetail>
    );
}