import {default as React, useState} from "react";
import {config} from "../../../config";
import {withModal} from "../../modal";

function CancelSubscription() {
    return (
        <div>
            To cancel your subscription please call our support staff at {config.supportPhoneNumber}
        </div>
    )
}

const CancelSubscriptionModal = withModal(CancelSubscription, 'Cancel Subscription');

export function useCancelSubscriptionModal() {
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = (
        <CancelSubscriptionModal open={open} onClose={close}/>
    );

    return {
        cancelSubscriptionModal: modal,
        openCancelSubscriptionModal: () => setOpen(true)
    }
}
