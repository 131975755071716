export const applications = {
    annuity: {
        id: "798c1608-d485-48bd-85f3-8378d08e59da",
        identifier: "annuity",
        name: "Annuity",
    },
    ask: {
        id: "03bc56e3-50eb-442a-8620-e1e00a699713",
        identifier: "ask",
        name: "Ask",
    },
    businessPlanBuilder: {
        id: "90eb06f7-d11e-434a-a721-5188230cb6e5",
        identifier: "business-plan-builder",
        name: "Business Plan Builder",
    },
    businessRoadmap: {
        id: "33e668ed-eebf-4b18-8aeb-038b63248308",
        identifier: "business-roadmap",
        name: "Business Roadmap",
    },
    crm: {
        id: "7132fc90-c1bc-4b02-8ff0-4c4e3b07d546",
        identifier: "crm",
        name: "Contact Management (CRM)",
    },
    enhancedWebBuilder: {
        id: "1c72f49a-9b0b-437b-b883-c0ddf671a7a4",
        identifier: "enhanced-web-builder",
        name: "Enhanced Web Builder",
    },
    googleMyBusiness: {
        id: "b4629e10-2d3d-45fa-825d-59e421e6eca5",
        identifier: "google-my-business",
        name: "Google My Business",
    },
    hrDocBuilder: {
        id: "e4975e3d-5256-4f83-9872-89b6e0355db5",
        identifier: "hr-doc-builder",
        name: "HR Doc Builder",
    },
    insurProCrm: {
        id: "1beab92f-c147-4ceb-9586-72780452c287",
        identifier: "insur-pro-crm",
        name: "InsurPRO CRM",
    },
    learningLab: {
        id: "5ee6ffb3-cadd-433f-ab1c-525fbd1aa896",
        identifier: "learning-lab",
        name: "Learning Lab",
    },
    manageMyMinutes: {
        id: "8e678a69-fb35-436b-8c3d-a9d2f2cb6ada",
        identifier: "manage-my-minutes",
        name: "Manage My Minutes",
    },
    snapshot: {
        id: "7b61b0d1-0a2f-4116-afcf-a21c556a0b92",
        identifier: "snapshot",
        name: "Snapshot",
    },
    ssnc: {
        id: "22b47d0e-0a7d-4387-b6a6-4ed9a87edc7f",
        identifier: "ssnc",
        name: "Social Security Calculator",
    },
    taxWizard: {
        id: "d74c3066-ef68-4c22-8cdb-f5f5eea8097a",
        identifier: "tax-wizard",
        name: "TaxWizard",
    },
    training: {
        id: "ff38a5a6-7995-4a92-907d-0a83f84f3daf",
        identifier: "training",
        name: "Training",
    },
    websiteBuilder: {
        id: "1c46656b-0e5b-460e-a7af-26042f8d898d",
        identifier: "website-builder",
        name: "Website Builder",
    },
    websiteBuilderUpnetic: {
        id: "0488565e-fd36-490e-b52f-ba18ad06fa71",
        identifier: "upnetic-website-builder",
        name: "Website Builder",
    },
    tceBonus: {
        id: "96201ee7-30a8-4b22-848f-78fb4a6959b5",
        identifier: "tce-bonus",
        name: "TCE Bonus",
    },
    entr7515: {
        id: "ade6c1ef-290a-41b7-b42b-62f0bcb672c9",
        identifier: "entr7515",
        name: "entr7515",
    },
    tceAudit: {
        id: "e2cd6e33-052d-414b-b7b5-fbbe068e568c",
        identifier: "tce-audit",
        name: "TCE Audit",
    },
    tceAuditComplete: {
        id: "42c9d265-3b11-4f9e-8d92-34f05550047f",
        identifier: "tce-audit-complete",
        name: "TCE Audit Completion",
    },
    tceLearn: {
        id: "2a3e7e71-e75a-4c3e-96e8-b8b4ce2f05e8",
        identifier: "tce-learn",
        name: "Certificate in Entrepreneurship",
    },
    websiteAnalysis: {
        id: "d12bd4a9-e693-4888-bcad-f5c0aeba34e4",
        identifier: "website-analysis",
        name: "Website Analysis",
    },
    legalForms: {
        id: "9e279586-ef04-4317-9497-9f36aa8469ed",
        identifier: "legal-forms",
        name: "Legal Forms",
    },
    dashboard: {
        id: "52523aa2-74f0-4250-a10e-c9fff3d95d6f",
        identifier: "dashboard",
        name: "Dashboard",
    },
    legalClub: {
        id: "fb375ea8-379f-4f0d-a3d8-c7fd8757f310",
        identifier: "legal-club",
        name: "Legal Services"
    }
};
