import React, { useRef, useState } from "react";
import {loadStripe, StripeCardElementChangeEvent, PaymentMethod, StripeError} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { environment } from "../../../env";
import { Formik, Form, FormikHelpers } from "formik";
import { textInputStyle } from "../../form/styles/field";
import { Button } from "@material-ui/core";
import {FormSubmitButton} from "../../form/buttons/FormSubmitButton";

export interface CreditCardFormProps {
  onCreate: (paymentMethod: PaymentMethod, setAsDefault: boolean) => void;
  onError: (error: StripeError) => void;
}

function CardForm({ onCreate, onError }: CreditCardFormProps) {
  const [isComplete, setComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const cardElement = elements?.getElement(CardElement);

  const handleChange = (event: StripeCardElementChangeEvent) => {
    if( isComplete !== event.complete ) {
      setComplete(event.complete);
    }
  }

  const handleSubmit = (values: any, { setSubmitting }: FormikHelpers<any> ) => {
    if( cardElement ) {
      stripe?.createPaymentMethod({type: "card", card: cardElement})
        .then(({ error, paymentMethod}) => {
          if( error ) onError(error)
          else if( paymentMethod ) onCreate(paymentMethod, values.setAsDefault)
        })
        .catch(console.error)
        .finally(() => setSubmitting(false))
    }
  }
  
  return (
    <Formik initialValues={{ setAsDefault: false }} onSubmit={handleSubmit}>
      <Form style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={textInputStyle}>
          <CardElement onChange={handleChange} />
        </div>
        <Button variant='contained'
                color='primary'
                type='submit'
                style={{ marginTop: 12, alignSelf: 'flex-end' }}
                disabled={!isComplete}>Add Card</Button>
      </Form>
    </Formik>
  );
}

const stripePromise = loadStripe(environment.stripePublishableKey);

export default (props: CreditCardFormProps) => {
  return (
    <Elements stripe={stripePromise}>
      <CardForm {...props} />
    </Elements>
  );
}
