import moment from 'moment'

export enum DateFormat {
    slashDelimitedDate = "M/DD/YY"
}

export default class DateFormatter {

    static formatDate(value: string, format?: string): string {
        return moment(value).format(format)
    }

    static isExpired(date: string): boolean {
        const now = moment();
        return moment(date).isAfter(now);
    }
}