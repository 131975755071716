import {TextField, OutlinedTextFieldProps as MaterialTextFieldProps} from "@material-ui/core";
import {useField} from "formik";
import React from "react";
import {textInputStyle} from "../styles/field";

type Props = { name: string } & Omit<MaterialTextFieldProps, 'variant'>;

export default function TextInput({ name, style, ...TextFieldProps }: Props) {
    const [field, meta] = useField(name);
    const { value, ...fieldProps } = field;

    return (
        <TextField
            error={!!meta.error && meta.touched}
            helperText={meta.error}
            variant='outlined'
            style={{ ...textInputStyle, ...style }}
            value={value || ''}
            {...fieldProps}
            {...TextFieldProps}
        />
    )
}
