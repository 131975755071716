import {Subscription} from "../Subscription";
import DateFormatter from "../../utils/DateFormatter";
import {Product} from "../Product";

export default class SubscriptionModel {
    constructor(private _subscription: Subscription) {}

    get hasCanceledAt(): boolean {
        return !!this._subscription.canceledAt
    }

    get isExpired(): boolean {
        if (this._subscription.expiresAt) {
            return DateFormatter.isExpired(this._subscription.expiresAt)
        } else {
            return !this._subscription.isActive;
        }
    };

    get subscription(): Subscription {
        return this._subscription;
    }

    get product(): Product | null {
        if (this.subscription.noChargeSource) {
            return this.subscription.noChargeSource.plan?.product || null;
        } else if (this.subscription.stripeSource) {
            const items = this.subscription.stripeSource.items || [];
            return items
                .map(item => item.plan ? item.plan.product : null)
                .filter(product => !!product)[0] || null;
        } else {
            return null;
        }
        // TODO: - ADP Subscription Source and Apple Subscription Source
    };
}