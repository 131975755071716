import React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from '@material-ui/icons/ChevronRight';
import {Button, ListItemIcon, ListItemText} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { customStyle } from '../../theme';
import {UrlTargetType} from "../../config/Url";

export class NavigationItem {
    constructor(
        public id: any,
        public title: string,
        public isSelected?: boolean,
        public disabled?: boolean,
        public href?: string,
        public target: UrlTargetType = "_blank"
    ) {}
}

export class NavigationGroup {
    constructor(
        public id: string,
        public title: string,
        public items: NavigationItem[],
        public disabled?: boolean
    ) {}
}

export type NavigationLink = NavigationGroup | NavigationItem;

interface Props extends RouteComponentProps {
    links: NavigationLink[];
    onNavigationItemSelected: (item: NavigationItem) => void;
}


const useStyles = makeStyles(theme => ({
    root: {
        margin: "0 auto",
        alignContent: "center",
        ...customStyle.navigation.root,
    },
    content: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",

        maxWidth: "1600px",
        margin: "0 auto",
        minHeight: "48px",
        flexWrap: "wrap"
    },
    nav: {
        margin: "12px"
    },
    navigationGroup: {

    },
    navigationItem: {
      ...customStyle.navigation.navigationItem
    },
    menuItemText: {
        whiteSpace: "pre",
    },
    menuItemIcon: {
        justifyContent: "end",
    }
}));

function Navigation(props: Props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedGroup, setSelectedGroup] = React.useState<null | string>(null);
    const { links } = props;

    function handleClick(event: React.MouseEvent<HTMLButtonElement>, group: NavigationGroup) {
        setSelectedGroup(group.id);
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setSelectedGroup(null);
        setAnchorEl(null);
    }

    function handleItemSelected(item: NavigationItem) {
        handleClose();
        props.onNavigationItemSelected(item);
    }

    function renderMenu(group: NavigationGroup) {
        const menuKey = group.id + '-menu';
        return (
            <Menu
                key={menuKey}
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {group.items.map(item => {
                    const key = menuKey + '-item-' + item.id;
                    return (
                        <MenuItem key={key} onClick={() => handleItemSelected(item)}>
                            <ListItemText primary={item.title} className={classes.menuItemText}></ListItemText>
                            { item.id !== 'hours' &&
                            <ListItemIcon  className={classes.menuItemIcon}>
                                <ArrowForwardIcon/>
                            </ListItemIcon>
                            }
                        </MenuItem>
                    )
                })}

            </Menu>
        )
    }

    function renderNavigationGroup(group: NavigationGroup) {
        return (
            <div style={{display: "inline-block"}} key={group.id + '-container'}>
                <Button key={group.id + '-button'}
                        className={classes.navigationItem}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, group)}>{group.title}</Button>

                {selectedGroup === group.id && renderMenu(group)}
            </div>
        )
    }

    function renderNavigationItem(item: NavigationItem) {
        return (
            <Button key={'item-' + item.id}
                    className={classes.navigationItem}
                    disabled={item.isSelected || false}
                    onClick={() => handleItemSelected(item)}>{item.title}</Button>
        )
    }

    function renderLink(link: NavigationLink) {
        if (link instanceof NavigationItem) {
            return renderNavigationItem(link)
        } else {
            return renderNavigationGroup(link)
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <nav className={classes.nav}>

                    {links.map(link => renderLink(link))}

                </nav>

            </div>

            <Divider variant="middle" />

        </div>
    );
}

export default withRouter(Navigation);
