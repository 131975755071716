import {Subscription} from "../../../../models/Subscription";
import {default as React, useMemo} from "react";
import SubscriptionModel from "../../../../models/domain/SubscriptionModel";
import SubscriptionViewModel from "../../../../models/vms/SubscriptionViewModel";

type SubscriptionDateModel = {
    title: string,
    value: string;
    hidden?: boolean;
}

export default function SubscriptionDateTable(props: { subscription: Subscription }) {
    const { subscription } = props;
    const model = useMemo(() => new SubscriptionModel(subscription), [subscription]);
    const viewModel = useMemo(() => new SubscriptionViewModel(model), [model]);

    const emptyValue = "-";

    const models: SubscriptionDateModel[] = [
        {
            title: "Canceled",
            value: viewModel.formattedCanceledAt || emptyValue,
            hidden: !model.hasCanceledAt
        },
        {
            title: "Renews",
            value: viewModel.formattedRenewsAt || emptyValue,
            hidden: !subscription.isActive
        },
        {
            title: "Expires",
            value: viewModel.formattedExpiresAt || emptyValue,
            hidden: !subscription.isActive
        },
        {
            title: "Purchased",
            value: viewModel.formattedPurchasedAt || emptyValue,
            hidden: !subscription.isActive
        },
        {
            title: "Activated",
            value: viewModel.formattedActivatedAt || emptyValue
        },
    ].filter(model => !model.hidden);

    return (
        <table>
            <tbody>
            {models.map(model => (
                <tr key={model.title}>
                    <td style={{ padding: 4 }}><b>{model.title}</b></td>
                    <td style={{ padding: 4, textAlign: 'center' }}>{model.value}</td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}
