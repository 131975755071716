import {Form, Formik, FormikHelpers} from "formik";
import React, {useEffect, useState} from "react";
import {User} from "../../../models/User";
import {useRepo} from "../../../repos/useRepo";
import {withModal} from "../../modal";
import {assignLicenseSchema, initialValuesForAssignLicenseRequest} from "../../../utils/schemas/license-schema";
import {License} from "../../../models/License";
import {AssignLicensesForUserRequest} from "../../../repos/UserRepo";
import LoadingIndicator from "../../LoadingIndicator";
import {
    Typography
} from "@material-ui/core";
import NoLicensesAvailable from "../components/NoLicensesAvailable";
import MultipleSelectInput from "../../form/select/MultipleSelectInput";
import {FormSubmitButton} from "../../form/buttons/FormSubmitButton";
import LicenseListModel from "../../../models/domain/LicenseListModel";

interface Props {
    licenses: License[];
    initialValues: AssignLicensesForUserRequest;
    onSubmit: (values: AssignLicensesForUserRequest, {}: FormikHelpers<AssignLicensesForUserRequest>) => void;
}

function AssignLicenseToUserForm({ initialValues, licenses, onSubmit }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={assignLicenseSchema} onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <MultipleSelectInput name='licenseIds' label='Licenses' items={licenses.map(l => ({ id: l.id, name: l.application ? l.application.name : l.name }))}/>

                <FormSubmitButton variant='Update' label='Assign'/>
            </Form>
        </Formik>
    )
}

interface UpdateUserFormProps {
    user: User;
    onLicenseAssigned: (license: License) => void;
    onPurchaseLicenseClicked: () => void;
}

export function AssignLicenseToUserForCurrentCompanyForm({ user, onLicenseAssigned, onPurchaseLicenseClicked }: UpdateUserFormProps) {
    const { userRepo, companyRepo } = useRepo();

    const [availableLicenses, setAvailableLicense] = useState<License[]>();

    useEffect(() => {
        userRepo.listAvailableLicensesForUserById(user.id)
            .then(licenses => {
                const licenseListModel = LicenseListModel.fromLicenses(licenses);
                setAvailableLicense(licenseListModel.takeFirstLicenseForEachApplication())
            })
            .catch(console.error);
    }, [companyRepo]);

    function onSubmit(values: AssignLicensesForUserRequest, { setSubmitting }: FormikHelpers<AssignLicensesForUserRequest>) {
        userRepo.assignLicensesForUserById(user.id, values)
            .then(onLicenseAssigned)
            .catch(console.error)
            .finally(() => setSubmitting(false));
    }

    if (availableLicenses) {
        const content = availableLicenses.length > 0
            ? <AssignLicenseToUserForm initialValues={initialValuesForAssignLicenseRequest()} onSubmit={onSubmit} licenses={availableLicenses}/>
            : <NoLicensesAvailable onPurchaseLicensesClicked={onPurchaseLicenseClicked}/>;

        return (
            <div>
                <Typography>Assign Licenses to {user.firstName} {user.lastName}</Typography>
                <div style={{ marginTop: 12, marginBottom: 12 }}>
                    {content}
                </div>
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}


export const AssignLicenseToUserForCurrentCompanyModal = withModal(AssignLicenseToUserForCurrentCompanyForm);

