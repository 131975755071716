import * as React from "react";
import TabDetail from "./TabDetail";
import AccountForm from "./AccountForm";
import {config} from "../../../config";

export default function AccountTab() {
    return (
        <TabDetail title='Account'>
            <AccountForm/>

            <p style={{ margin: 8, maxWidth: 420 }}>To change your email or username please contact our support staff at {config.supportPhoneNumber}</p>
        </TabDetail>
    );
}