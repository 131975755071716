import {Chip} from "../chips/Chip";
import * as React from "react";
import {ChipProps as MaterialChipProps} from "@material-ui/core/Chip";

interface Props<T> {
    items: T[];
    onItemSelected?: (item: T) => void;
    label: (item: T) => string;
    ChipProps?: MaterialChipProps
}

export default function ChipSelect<T>({ items, onItemSelected, label, ChipProps }: Props<T>) {
    return (
        <div>
            {items.map((item, index) => (
                <Chip key={`${label}-chip-select-${index}`}
                      label={label(item)}
                      onClick={() => {
                          if (onItemSelected) {
                              onItemSelected(item);
                          }
                      }}
                      {...ChipProps}/>
            ))}
        </div>
    )
}
