import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useState} from "react";
import {User} from "../../../models/User";
import LoadingIndicator from "../../LoadingIndicator";
import {Form, Formik} from "formik";
import TextInput from "../../form/inputs/TextInput";

export default function AccountForm() {
    const { userRepo } = useRepo();

    const [user, setUser] = useState<User>();

    useEffect(() => {
        userRepo.getUserById('current').then(setUser).catch(console.error);
    }, []);

    if (user) {
        return (
            <Formik initialValues={{ email: user.email ?? '-', userName: user.userName ?? '-' }}
                    onSubmit={() => {}}>
                <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                    <TextInput name='email'
                               label='Email'
                               style={{ marginTop: 16 }}
                               disabled/>

                    <TextInput name='userName'
                               label='Username'
                               style={{ marginTop: 16 }}
                               disabled/>
                </Form>
            </Formik>
        )
    } else {
        return <LoadingIndicator/>
    }
}

