import State from "./State";
import Industry from "./Industry";

export type EntityType = string;

export type BusinessSize = string;

export interface CompanyProfile {
	industryId?: string;
	stateCode?: string;
	businessType?: string;
	entityType?: EntityType;
	businessSize?: BusinessSize;
	yearFounded?: number;

	industry?: Industry;
	state?: State;
	formattedBusinessAge?: string;
}

export type UpdateCompanyProfileBody = Pick<CompanyProfile,
  'industryId' |
  'businessType' |
  'businessSize' |
  'entityType' |
  'stateCode' |
  'yearFounded'
  >;

export function isValidCompanyProfile(companyProfile?: CompanyProfile) {
	const requiredFields = [
		"industryId",
		"businessType",
		"businessSize",
		"entityType",
		"stateCode",
		"yearFounded"
	];

	const isMissingProfileComponents = !companyProfile || Object.entries(companyProfile).some(([key, value]) => {
		return requiredFields.includes(key) && value === null;
	});
	return !isMissingProfileComponents;

}