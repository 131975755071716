import {AssignRolesFormDependency, AssignRolesToUserForCurrentCompanyModal} from "../forms/AssignRolesForm";
import * as React from "react";
import {useMemo, useState} from "react";
import {License} from "../../../models/License";
import {Role} from "../../../models/Role";
import {User} from "../../../models/User";

export default function useAssignRolesToUserForCurrentCompanyModal(onRolesAssigned: (roles: Role[], user: User) => void) {

    const [roleAssignDependency, setRoleAssignDependency] = useState<AssignRolesFormDependency>();
    const open = useMemo(() => !!roleAssignDependency, [roleAssignDependency]);
    const close = () => setRoleAssignDependency(undefined);

    const modal = roleAssignDependency ? (
        <AssignRolesToUserForCurrentCompanyModal
            open={open}
            onClose={close}
            onRolesAssigned={(roles) => {
                close();
                onRolesAssigned(roles, roleAssignDependency.user);
            }}
            dependency={roleAssignDependency}
        />
    ) : null;

    return {
        assignRolesToUserModal: modal,
        openAssignRoleToUserModal: (user: User, license?: License) => setRoleAssignDependency({ user, license })
    }
}