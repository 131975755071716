import {createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select, Theme} from "@material-ui/core";
import {Chip} from "../chips/Chip";
import {useFormikContext} from "formik";
import React, {useEffect, useRef, useState} from "react";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 240,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    }),
);

export interface SelectItem {
    id: string;
    name: string;
}

interface Props {
    name: string;
    label?: string;
    items: SelectItem[];
}

export default function MultipleSelectInput({ name, label, items }: Props) {
    const classes = useStyles();
    const {handleChange, values, setFieldValue } = useFormikContext<any>();

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    const nameForItemById = (id: string) => {
        const item = items.find(i => i.id === id);
        return item ? item.name : undefined;
    };

    const deselectItemById = (itemId: string) => {
        const itemIds = values[name] as unknown as string[];
        const updatedItemIds = itemIds.filter(id => id !== itemId);
        setFieldValue(name, updatedItemIds);
    };

    return (
        <FormControl className={classes.formControl} variant='outlined'>
            <InputLabel ref={inputLabel} htmlFor={name}>
                {label || ''}
            </InputLabel>
            <Select
                multiple
                name={name}
                labelWidth={labelWidth}
                value={values[name]}
                onChange={handleChange}
                renderValue={itemIds => (
                    <div className={classes.chips}>
                        {(itemIds as string[]).map((itemId, index) => (
                            <Chip key={`${name}-multi-select-item-${index}`}
                                  label={nameForItemById(itemId)}
                                  onDelete={() => deselectItemById(itemId)}/>
                        ))}
                    </div>
                )}
            >
                {items && items.map(item => (
                    <MenuItem key={`${name}-multi-select-item-${item.id}`} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}