export interface TimeZone {
    name: string;
    value: string;
}

const timeZones: TimeZone[] = [
    {
        name: "(UTC-12:00) International Date Line West",
        value: "Dateline Standard Time",
    },
    {
        name: "(UTC-11:00) Coordinated Universal Time-11",
        value: "UTC-11",
    },
    {
        name: "(UTC-10:00) Aleutian Islands",
        value: "Aleutian Standard Time",
    },
    {
        name: "(UTC-10:00) Hawaii",
        value: "Hawaiian Standard Time",
    },
    {
        name: "(UTC-09:30) Marquesas Islands",
        value: "Marquesas Standard Time",
    },
    {
        name: "(UTC-09:00) Alaska",
        value: "Alaskan Standard Time",
    },
    {
        name: "(UTC-09:00) Coordinated Universal Time-09",
        value: "UTC-09",
    },
    {
        name: "(UTC-08:00) Baja California",
        value: "Pacific Standard Time (Mexico)",
    },
    {
        name: "(UTC-08:00) Coordinated Universal Time-08",
        value: "UTC-08",
    },
    {
        name: "(UTC-08:00) Pacific Time (US & Canada",
        value: "Pacific Standard Time",
    },
    {
        name: "(UTC-07:00) Arizona",
        value: "US Mountain Standard Time",
    },
    {
        name: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
        value: "Mountain Standard Time (Mexico)",
    },
    {
        name: "(UTC-07:00) Mountain Time (US & Canada",
        value: "Mountain Standard Time",
    },
    {
        name: "(UTC-06:00) Central America",
        value: "Central America Standard Time",
    },
    {
        name: "(UTC-06:00) Central Time (US & Canada)",
        value: "Central Standard Time",
    },
    {
        name: "(UTC-06:00) Easter Island",
        value: "Easter Island Standard Time",
    },
    {
        name: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
        value: "Central Standard Time (Mexico)",
    },
    {
        name: "(UTC-06:00) Saskatchewan",
        value: "Canada Central Standard Time",
    },
    {
        name: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
        value: "SA Pacific Standard Time",
    },
    {
        name: "(UTC-05:00) Chetumal",
        value: "Eastern Standard Time (Mexico)",
    },
    {
        name: "(UTC-05:00) Eastern Time (US & Canada)",
        value: "Eastern Standard Time",
    },
    {
        name: "(UTC-05:00) Haiti",
        value: "Haiti Standard Time",
    },
    {
        name: "(UTC-05:00) Havana",
        value: "Cuba Standard Time",
    },
    {
        name: "(UTC-05:00) Indiana (East",
        value: "US Eastern Standard Time",
    },
    {
        name: "(UTC-05:00) Turks and Caicos",
        value: "Turks and Caicos Standard Time",
    },
    {
        name: "(UTC-04:00) Asuncion",
        value: "Paraguay Standard Time",
    },
    {
        name: "(UTC-04:00) Atlantic Time (Canada",
        value: "Atlantic Standard Time",
    },
    {
        name: "(UTC-04:00) Caracas",
        value: "Venezuela Standard Time",
    },
    {
        name: "(UTC-04:00) Cuiaba",
        value: "Central Brazilian Standard Time",
    },
    {
        name: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
        value: "SA Western Standard Time",
    },
    {
        name: "(UTC-04:00) Santiago",
        value: "Pacific SA Standard Time",
    },
    {
        name: "(UTC-03:30) Newfoundland",
        value: "Newfoundland Standard Time",
    },
    {
        name: "(UTC-03:00) Araguaina",
        value: "Tocantins Standard Time",
    },
    {
        name: "(UTC-03:00) Brasilia",
        value: "E. South America Standard Time",
    },
    {
        name: "(UTC-03:00) Cayenne, Fortaleza",
        value: "SA Eastern Standard Time",
    },
    {
        name: "(UTC-03:00) City of Buenos Aires",
        value: "Argentina Standard Time",
    },
    {
        name: "(UTC-03:00) Greenland",
        value: "Greenland Standard Time",
    },
    {
        name: "(UTC-03:00) Montevideo",
        value: "Montevideo Standard Time",
    },
    {
        name: "(UTC-03:00) Punta Arenas",
        value: "Magallanes Standard Time",
    },
    {
        name: "(UTC-03:00) Saint Pierre and Miquelon",
        value: "Saint Pierre Standard Time",
    },
    {
        name: "(UTC-03:00) Salvador",
        value: "Bahia Standard Time",
    },
    {
        name: "(UTC-02:00) Coordinated Universal Time-02",
        value: "UTC-02",
    },
    {
        name: "(UTC-02:00) Mid-Atlantic - Old",
        value: "Mid-Atlantic Standard Time",
    },
    {
        name: "(UTC-01:00) Azores",
        value: "Azores Standard Time",
    },
    {
        name: "(UTC-01:00) Cabo Verde Is",
        value: "Cabo Verde Standard Time",
    },
    {
        name: "(UTC) Coordinated Universal Time",
        value: "Coordinated Universal Time",
    },
    {
        name: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
        value: "GMT Standard Time",
    },
    {
        name: "(UTC+00:00) Monrovia, Reykjavik",
        value: "Greenwich Standard Time",
    },
    {
        name: "(UTC+00:00) Sao Tome",
        value: "Sao Tome Standard Time",
    },
    {
        name: "(UTC+01:00) Casablanca",
        value: "Morocco Standard Time",
    },
    {
        name: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        value: "W. Europe Standard Time",
    },
    {
        name: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        value: "Central Europe Standard Time",
    },
    {
        name: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
        value: "Romance Standard Time",
    },
    {
        name: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
        value: "Central European Standard Time",
    },
    {
        name: "(UTC+01:00) West Central Africa",
        value: "W. Central Africa Standard Time",
    },
    {
        name: "(UTC+02:00) Amman",
        value: "Jordan Standard Time",
    },
    {
        name: "(UTC+02:00) Athens, Bucharest",
        value: "GTB Standard Time",
    },
    {
        name: "(UTC+02:00) Beirut",
        value: "Middle East Standard Time",
    },
    {
        name: "(UTC+02:00) Cairo",
        value: "Egypt Standard Time",
    },
    {
        name: "(UTC+02:00) Chisinau",
        value: "E. Europe Standard Time",
    },
    {
        name: "(UTC+02:00) Damascus",
        value: "Syria Standard Time",
    },
    {
        name: "(UTC+02:00) Gaza, Hebron",
        value: "West Bank Gaza Standard Time",
    },
    {
        name: "(UTC+02:00) Harare, Pretoria",
        value: "South Africa Standard Time",
    },
    {
        name: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        value: "FLE Standard Time",
    },
    {
        name: "(UTC+02:00) Jerusalem",
        value: "Jerusalem Standard Time",
    },
    {
        name: "(UTC+02:00) Kaliningrad",
        value: "Russia TZ 1 Standard Time",
    },
    {
        name: "(UTC+02:00) Khartoum",
        value: "Sudan Standard Time",
    },
    {
        name: "(UTC+02:00) Tripoli",
        value: "Libya Standard Time",
    },
    {
        name: "(UTC+02:00) Windhoek",
        value: "Namibia Standard Time",
    },
    {
        name: "(UTC+03:00) Baghdad",
        value: "Arabic Standard Time",
    },
    {
        name: "(UTC+03:00) Istanbul",
        value: "Turkey Standard Time",
    },
    {
        name: "(UTC+03:00) Kuwait, Riyadh",
        value: "Arab Standard Time",
    },
    {
        name: "(UTC+03:00) Minsk",
        value: "Belarus Standard Time",
    },
    {
        name: "(UTC+03:00) Moscow, St. Petersburg",
        value: "Russia TZ 2 Standard Time",
    },
    {
        name: "(UTC+03:00) Nairobi",
        value: "E. Africa Standard Time",
    },
    {
        name: "(UTC+03:30) Tehran",
        value: "Iran Standard Time",
    },
    {
        name: "(UTC+04:00) Abu Dhabi, Muscat",
        value: "Arabian Standard Time",
    },
    {
        name: "(UTC+04:00) Astrakhan, Ulyanovsk",
        value: "Astrakhan Standard Time",
    },
    {
        name: "(UTC+04:00) Baku",
        value: "Azerbaijan Standard Time",
    },
    {
        name: "(UTC+04:00) Izhevsk, Samara",
        value: "Russia TZ 3 Standard Time",
    },
    {
        name: "(UTC+04:00) Port Louis",
        value: "Mauritius Standard Time",
    },
    {
        name: "(UTC+04:00) Saratov",
        value: "Saratov Standard Time",
    },
    {
        name: "(UTC+04:00) Tbilisi",
        value: "Georgian Standard Time",
    },
    {
        name: "(UTC+04:00) Volgograd",
        value: "Volgograd Standard Time",
    },
    {
        name: "(UTC+04:00) Yerevan",
        value: "Caucasus Standard Time",
    },
    {
        name: "(UTC+04:30) Kabul",
        value: "Afghanistan Standard Time",
    },
    {
        name: "(UTC+05:00) Ashgabat, Tashkent",
        value: "West Asia Standard Time",
    },
    {
        name: "(UTC+05:00) Ekaterinburg",
        value: "Russia TZ 4 Standard Time",
    },
    {
        name: "(UTC+05:00) Islamabad, Karachi",
        value: "Pakistan Standard Time",
    },
    {
        name: "(UTC+05:00) Qyzylorda",
        value: "Qyzylorda Standard Time",
    },
    {
        name: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        value: "India Standard Time",
    },
    {
        name: "(UTC+05:30) Sri Jayawardenepura",
        value: "Sri Lanka Standard Time",
    },
    {
        name: "(UTC+05:45) Kathmandu",
        value: "Nepal Standard Time",
    },
    {
        name: "(UTC+06:00) Astana",
        value: "Central Asia Standard Time",
    },
    {
        name: "(UTC+06:00) Dhaka",
        value: "Bangladesh Standard Time",
    },
    {
        name: "(UTC+06:00) Omsk",
        value: "Omsk Standard Time",
    },
    {
        name: "(UTC+06:30) Yangon (Rangoon",
        value: "Myanmar Standard Time",
    },
    {
        name: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
        value: "SE Asia Standard Time",
    },
    {
        name: "(UTC+07:00) Barnaul, Gorno-Altaysk",
        value: "Altai Standard Time",
    },
    {
        name: "(UTC+07:00) Hovd",
        value: "W. Mongolia Standard Time",
    },
    {
        name: "(UTC+07:00) Krasnoyarsk",
        value: "Russia TZ 6 Standard Time",
    },
    {
        name: "(UTC+07:00) Novosibirsk",
        value: "Novosibirsk Standard Time",
    },
    {
        name: "(UTC+07:00) Tomsk",
        value: "Tomsk Standard Time",
    },
    {
        name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        value: "China Standard Time",
    },
    {
        name: "(UTC+08:00) Irkutsk",
        value: "Russia TZ 7 Standard Time",
    },
    {
        name: "(UTC+08:00) Kuala Lumpur, Singapore",
        value: "Malay Peninsula Standard Time",
    },
    {
        name: "(UTC+08:00) Perth",
        value: "W. Australia Standard Time",
    },
    {
        name: "(UTC+08:00) Taipei",
        value: "Taipei Standard Time",
    },
    {
        name: "(UTC+08:00) Ulaanbaatar",
        value: "Ulaanbaatar Standard Time",
    },
    {
        name: "(UTC+08:45) Eucla",
        value: "Aus Central W. Standard Time",
    },
    {
        name: "(UTC+09:00) Chita",
        value: "Transbaikal Standard Time",
    },
    {
        name: "(UTC+09:00) Osaka, Sapporo, Tokyo",
        value: "Tokyo Standard Time",
    },
    {
        name: "(UTC+09:00) Pyongyang",
        value: "North Korea Standard Time",
    },
    {
        name: "(UTC+09:00) Seoul",
        value: "Korea Standard Time",
    },
    {
        name: "(UTC+09:00) Yakutsk",
        value: "Russia TZ 8 Standard Time",
    },
    {
        name: "(UTC+09:30) Adelaide",
        value: "Cen. Australia Standard Time",
    },
    {
        name: "(UTC+09:30) Darwin",
        value: "AUS Central Standard Time",
    },
    {
        name: "(UTC+10:00) Brisbane",
        value: "E. Australia Standard Time",
    },
    {
        name: "(UTC+10:00) Canberra, Melbourne, Sydney",
        value: "AUS Eastern Standard Time",
    },
    {
        name: "(UTC+10:00) Guam, Port Moresby",
        value: "West Pacific Standard Time",
    },
    {
        name: "(UTC+10:00) Hobart",
        value: "Tasmania Standard Time",
    },
    {
        name: "(UTC+10:00) Vladivostok",
        value: "Russia TZ 9 Standard Time",
    },
    {
        name: "(UTC+10:30) Lord Howe Island",
        value: "Lord Howe Standard Time",
    },
    {
        name: "(UTC+11:00) Bougainville Island",
        value: "Bougainville Standard Time",
    },
    {
        name: "(UTC+11:00) Chokurdakh",
        value: "Russia TZ 10 Standard Time",
    },
    {
        name: "(UTC+11:00) Magadan",
        value: "Magadan Standard Time",
    },
    {
        name: "(UTC+11:00) Norfolk Island",
        value: "Norfolk Standard Time",
    },
    {
        name: "(UTC+11:00) Sakhalin",
        value: "Sakhalin Standard Time",
    },
    {
        name: "(UTC+11:00) Solomon Is., New Caledonia",
        value: "Central Pacific Standard Time",
    },
    {
        name: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
        value: "Russia TZ 11 Standard Time",
    },
    {
        name: "(UTC+12:00) Auckland, Wellington",
        value: "New Zealand Standard Time",
    },
    {
        name: "(UTC+12:00) Coordinated Universal Time+12",
        value: "UTC+12",
    },
    {
        name: "(UTC+12:00) Fiji",
        value: "Fiji Standard Time",
    },
    {
        name: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
        value: "Kamchatka Standard Time",
    },
    {
        name: "(UTC+12:45) Chatham Islands",
        value: "Chatham Islands Standard Time",
    },
    {
        name: "(UTC+13:00) Coordinated Universal Time+13",
        value: "UTC+13",
    },
    {
        name: "(UTC+13:00) Nuku'alofa",
        value: "Tonga Standard Time",
    },
    {
        name: "(UTC+13:00) Samoa",
        value: "Samoa Standard Time",
    },
    {
        name: "(UTC+14:00) Kiritimati Island",
        value: "Line Islands Standard Time",
    },
];

export default timeZones;