import React from 'react';
import { RouteComponentProps, withRouter} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface Props extends RouteComponentProps {
    title: string;
    maxWidth?: number
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        margin: "0 auto",
        alignContent: "center",
        width: "100%",
        borderBottomColor: theme.palette.grey[300],
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
    },
    content: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "3px 36px",
        maxWidth: "1600px",
        margin: "0 auto"
    },
}));


function Title(props: Props) {
    const classes = useStyles();
    const { title } = props;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <h2>{title}</h2>
            </div>

            <div></div>
        </div>
    );
}

export default withRouter(Title);
