import {Typography} from "@material-ui/core";
import ChipSelect from "../../form/select/ChipSelect";
import * as React from "react";
import {LicenseGroup} from "../../../models/domain/LicenseListModel";

export interface Props {
    title: string;
    caption?: string;
    licenseGroups: LicenseGroup[];
    onGroupSelected?: (group: LicenseGroup) => void;
}

export function LicenseGroupSection({ title, caption, licenseGroups, onGroupSelected }: Props) {
    return (
        <div>
            <Typography variant='subtitle2'>{title}</Typography>

            {caption && (
                <Typography variant='caption'>{caption}</Typography>
            )}

            <div>
                <ChipSelect items={licenseGroups}
                            label={(group) => `${group.application.name} (${group.licenses.length})`}
                            onItemSelected={onGroupSelected}/>
            </div>
        </div>
    )
}
