import {Form, Formik, FormikHelpers} from "formik";
import {
    initialValuesForUserCreate,
    initialValuesForUserUpdate,
    updateUserSchema,
    createUserSchema
} from "../../../utils/schemas/user-schema";
import React, {useEffect, useState} from "react";
import {CreateUserRequest, UpdateUserRequest, User} from "../../../models/User";
import {useRepo} from "../../../repos/useRepo";
import LoadingIndicator from "../../LoadingIndicator";
import {withModal} from "../../modal";
import NoLicensesAvailable from "../components/NoLicensesAvailable";
import TimeZonePicker from "../../form/pickers/TimeZonePicker";
import TextInput from "../../form/inputs/TextInput";
import {FormSubmitButton} from "../../form/buttons/FormSubmitButton";
import {useAuthState} from "../../../services/auth/useAuthState";
import {License} from "../../../models/License";

interface UpdateUserFormProps {
    user: User;
    onUserUpdated: (user: User) => void;
}

export function UpdateUserForm({ user, onUserUpdated }: UpdateUserFormProps) {
    const { userRepo } = useRepo();

    function onSubmit(values: UpdateUserRequest, {}: FormikHelpers<UpdateUserRequest>) {
        userRepo.updateUserById(user.id, values).then(onUserUpdated).catch(console.error);
    }

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValuesForUserUpdate(user)}
                validationSchema={updateUserSchema}
                onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <TextInput name='firstName' label='First Name'/>

                <TextInput name='lastName' label='Last Name'/>

                <TextInput name='phoneNumber' label='Phone Number'/>

                <TimeZonePicker name='timeZone' allowEmpty={true} emptyValue='' emptyValueDescription='-'/>

                <FormSubmitButton variant={'Update'}/>
            </Form>
        </Formik>
    )
}

interface CreateUserFormProps {
    onUserCreated: (user: User) => void;
    onPurchaseLicensesClicked: () => void;
}

function CreateUserForCurrentCompanyForm({ onUserCreated, onPurchaseLicensesClicked }: CreateUserFormProps) {
    const { companyRepo } = useRepo();

    const [licenses, setLicenses] = useState<License[]>();

    useEffect(() => {
        companyRepo.listLicensesForCompanyById('current', { status: 'available' }).then(setLicenses).catch(console.error);
    }, [companyRepo]);

    function onSubmit(values: CreateUserRequest, {}: FormikHelpers<CreateUserRequest>) {
        companyRepo.createUserForCompanyById('current', values).then(onUserCreated).catch(console.error);
    }

    if (licenses) {
        const content = licenses.length > 0
            ? (
                <Formik initialValues={initialValuesForUserCreate()} validationSchema={createUserSchema} onSubmit={onSubmit}>
                    <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                        <TextInput name='email' label='Email'/>

                        <TextInput name='firstName' label='First Name'/>

                        <TextInput name='lastName' label='Last Name'/>

                        <TextInput name='phoneNumber' label='Phone Number'/>

                        <TimeZonePicker name='timeZone' allowEmpty={true} emptyValue='' emptyValueDescription='-'/>

                        <FormSubmitButton variant='Create'/>
                    </Form>
                </Formik>
            )
            : <NoLicensesAvailable onPurchaseLicensesClicked={onPurchaseLicensesClicked}/>;

        return (
            <div style={{ marginTop: 12, marginBottom: 12 }}>
                {content}
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }

}

export function UpdateCurrentUserForm() {
    const { userRepo } = useRepo();
    const { setCurrentUser } = useAuthState();

    const [user, setUser] = useState<User>();

    useEffect(() => {
        userRepo.getUserById('current').then(setUser).catch(console.error);
    }, []);

    const onUserUpdated = (user: User) => {
        setCurrentUser(user);
        setUser(user);
    };

    if (user) {
        return <UpdateUserForm user={user} onUserUpdated={onUserUpdated}/>
    } else {
        return <LoadingIndicator/>
    }
}

export const CreateUserForCurrentCompanyFormModal = withModal(CreateUserForCurrentCompanyForm);

