import * as React from 'react';
import {useServices} from "../services/useServices";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {Button, Card, Typography} from "@material-ui/core";

import ArrowIcon from '@material-ui/icons/ArrowRightAltRounded'

interface Params {
    planId?: string;
}

interface Props extends RouteComponentProps<Params> {
    onPurchasePlan: (planId: string) => void;
}

function Pricing(props: Props) {
    const { onPurchasePlan } = props;
    const { authService } = useServices();

    async function signup() {
        const isLoggedIn = await authService.isAuthenticated();
        const params = new URLSearchParams(props.location.search);
        const planId = params.get('planId');

        if (!planId) {
            return;
        }

        if (isLoggedIn) {
            onPurchasePlan(planId);
        } else {
            authService.loginRedirect({ planId });
        }
    }

    return (
        <div style={{ flex: 1 }}>
            <Button onClick={signup}>Sign up</Button>

            <PlanCard vm={new MockPlanCardViewModel()} onPurchase={signup}/>
        </div>
    );
}


interface PlanCardViewModel {
    readonly title: string;
    readonly subtitle: string;
    readonly features: string[];
    readonly buttonTitle: string;
}

class MockPlanCardViewModel implements PlanCardViewModel {
    buttonTitle = "Buy Now";
    title = "$47 / Month";
    subtitle = "No Contracts. Month-to-Month.";
    features = [
        "Small Business Consulting Services",
        "Website & Digital Marketing Analysis",
        "CRM with Email Marketing",
        "Digital Media Dashboard",
        "Legal Forms",
        "Business Plan Software",
        "Learning Lab",
    ]
}

function PlanCard(props: { vm: PlanCardViewModel, onPurchase: () => void }) {
    const { vm, onPurchase } = props;
    const { title, subtitle, buttonTitle, features } = vm;

    return (
        <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Typography variant='h3'>{title}</Typography>

            <Typography variant='h6'>{subtitle}</Typography>

            <div>
                {features.map(feature => (
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <ArrowIcon/>
                        {feature}
                    </div>
                ))}
            </div>

            <Button onClick={onPurchase}>{buttonTitle}</Button>
        </Card>
    )
}

export default withRouter(Pricing);
