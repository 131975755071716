import { Typography, Link } from '@material-ui/core';
import React from 'react';
import { config } from '../../config';
import withDialog from '../dialog/withDialog';

interface Props {
    topicId?: string,
    onRedirect: (result: boolean) => void
}

export function ERCText({ topicId, onRedirect }: Props) {
  return (
      <>
        <Typography gutterBottom>While the {config.brandName} Business Consultants are able to answer questions about how the ERC works, they are not able to provide direct assistance such as calculating the credit, determining eligibility, or preparing paperwork. { topicId && (<>For a detailed discussion on all aspects of the ERC and claim process, <Link href="#" onClick={() => onRedirect(true)}>please view our FAQ page here</Link>.</>)}</Typography>
        <Typography gutterBottom>If you need direct assistance, please contact your current business accountant or a local Certified Public Accountant (CPA).</Typography>
      </>
  );
}


export const ERCDialog = withDialog(ERCText);