import React from "react";
import Modal, {ModalProps} from "./Modal";

const withModal = <P extends object>(Component: React.ComponentType<P>, title?: string): React.FC<P & ModalProps> =>
    (props) => {
        const ModalProps: ModalProps = {
            title: props.title || title,
            onClose: props.onClose,
            open: props.open,
            paperStyle: props.paperStyle,
            backdropProps: props.backdropProps,
            rootStyle: props.rootStyle,
        };

        const ComponentProps = ((props: P & ModalProps): P => {
            const p = {...props};
            delete p.title;
            delete p.onClose;
            delete p.open;
            return p;
        })(props);

        return (
            <Modal {...ModalProps}>
                <Component {...ComponentProps}/>
            </Modal>
        );
    };

export default withModal;
