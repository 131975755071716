import {default as React, useState} from "react";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {
    Button, createStyles,
    Divider,
    ExpansionPanel, ExpansionPanelActions,
    ExpansionPanelDetails,
    ExpansionPanelSummary, makeStyles, Theme,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardDetailTable from "./CardDetailTable";
import { Chip } from "../../../form/chips/Chip";
import { PaymentMethod } from "@stripe/stripe-js";
import { Card } from "../../../../models/Card";

interface CardActions {
    setDefaultCard: (card: PaymentMethod) => void;
    removeCard: (card: PaymentMethod) => void;
}

interface Props extends CardActions {
    cards: PaymentMethod[];
    defaultCard: { id: string | undefined } | undefined;
}

interface CardRowProps {
    card?: Card;
    isDefault: boolean;
    onRemoveCardClick: () => void;
    onSetDefaultClick: () => void;
}

interface CardDetailProps {
    card: Card;
}

interface CardActionProps {
    isDefault: boolean;
    onSetDefaultClick: () => void;
    onRemoveCardClick: () => void;
}

export default function CardsSection({ cards, removeCard, setDefaultCard, defaultCard }: Props) {
    return (
        <div>
            {cards.map( (paymentMethod, index) => {
                const isDefault = defaultCard ? defaultCard.id === paymentMethod.id : false;

                return (
                    <CardRow key={`card-row-${index}`}
                             card={paymentMethod.card}
                             isDefault={isDefault}
                             onRemoveCardClick={() => removeCard(paymentMethod)}
                             onSetDefaultClick={() => setDefaultCard(paymentMethod)}
                    />
                )
            })}
        </div>
    )
}

function CardRow({ card, onRemoveCardClick, onSetDefaultClick, isDefault }: CardRowProps) {
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();

    if( !card ) {
        return null; // just in case payment method isn't a card
    }

    const { last4, brand } = card;

    return (
        <ExpansionPanel expanded={expanded} onChange={() => setExpanded(!expanded)} TransitionProps={{ unmountOnExit: true }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.panelSummary }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={classes.heading}>{`${brand.toUpperCase()} ending in ${last4}`}</Typography>
                    {isDefault && (
                        <Chip style={{ marginLeft: 12 }} label={'Default'} color='secondary' size='small'/>
                    )}
                </div>
            </ExpansionPanelSummary>
            <Divider/>
            <CardDetail card={card}/>
            <CardExpansionPanelActions
                isDefault={isDefault}
                onRemoveCardClick={onRemoveCardClick}
                onSetDefaultClick={onSetDefaultClick}
            />
        </ExpansionPanel>
    )
}

function CardDetail({ card }: CardDetailProps) {
    return (
        <ExpansionPanelDetails>
            <CardDetailTable card={card} />
        </ExpansionPanelDetails>
    )
}

function CardExpansionPanelActions({ onSetDefaultClick, onRemoveCardClick, isDefault }: CardActionProps) {
    const { can } = useRbac();

    const canDeleteCardForCompany = can(actions.company.removeCreditCard);
    const canSetCardDefaultForCompany = can(actions.company.updateDefaultCreditCard);

    const shouldShowSetDefaultButton = canSetCardDefaultForCompany && !isDefault;
    const shouldShowRemoveButton = canDeleteCardForCompany && !isDefault

    if (shouldShowRemoveButton || shouldShowSetDefaultButton) {
        return (
            <>
                <Divider />

                <ExpansionPanelActions>

                    { shouldShowSetDefaultButton && (
                        <Button onClick={onSetDefaultClick}
                                size='small'
                                color='secondary'
                                variant='contained'>Make Default</Button>
                    )}

                    {shouldShowRemoveButton && (
                        <Button onClick={onRemoveCardClick}
                                size='small'>Remove</Button>
                    )}

                </ExpansionPanelActions>
            </>
        )
    } else {
        return null;
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        panelSummary: {
            backgroundColor: theme.palette.background.paper,
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            marginLeft: theme.spacing(1),
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    }),
);
