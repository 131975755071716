import * as React from "react";
import _ from "lodash";
import {User} from "../../../../models/User";
import {useEffect, useMemo, useState} from "react";
import { Button, Divider, Typography } from "@material-ui/core";
import {useRepo} from "../../../../repos/useRepo";
import TabDetail from "../TabDetail";
import {License} from "../../../../models/License";
import {Application} from "../../../../models/Application";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import LoadingIndicator from "../../../LoadingIndicator";
import {useStartupResult} from "../../../../services/startup/useStartupResult";
import {CompanyLicenseSection} from "../../components/CompanyLicenseSection";
import useInviteUserForCurrentCompanyModal from "../../modal/useInviteUserForCurrentCompanyModal";
import useAssignRolesToUserForCurrentCompanyModal from "../../modal/useAssignRolesToUserForCurrentCompanyModal";
import useAssignLicenseToUserForCurrentCompanyModal from "../../modal/useAssignLicenseToUserForCurrentCompanyModal";
import useAssignUserToLicenseForCurrentCompanyModal from "../../modal/useAssignUserToLicenseForCurrentCompanyModal";
import AvailableLicensesSection from "../../components/AvailableLicensesSection";
import UsersSection from "./UsersSection";
import LicenseListModel from "../../../../models/domain/LicenseListModel";
import { Role } from "../../../../models/Role";

interface Props {
    navigateToManageSubscriptions: () => void;
}

type EmptyStateMessage = {
    onClick: () => void;
};

const EmptyUsersMessage = ({ onClick }: EmptyStateMessage) => <div style={{display: "flex", flexDirection: "column", alignItems: "center" }}>
<Typography style={{ textAlign: 'center' }}>
  Looks like there are no users!</Typography>
  <div>
    <Button color='primary'
      variant='contained'
      style={{ alignSelf: 'flex-0end', marginTop: 16 }}
      onClick={onClick}>Invite a User</Button>
  </div>
</div>

export function UsersTab({ navigateToManageSubscriptions }: Props) {

    const { refresh: reloadCurrentUser } = useStartupResult();

    const { companyRepo } = useRepo();
    const { can } = useRbac();

    const canAddUserToCompany = can(actions.company.createUser);

    const [users, setUsers] = useState<User[]>();
    const [licenses, setLicenses] = useState<License[]>();

    const model = useMemo(() => LicenseListModel.fromLicenses(licenses || []), [licenses]);

    useEffect(reloadData, []);

    function reloadData() {
        companyRepo.listUsersForCompanyById('current').then(setUsers).catch(console.error);
        companyRepo.listLicensesForCompanyById('current').then(setLicenses).catch(console.error);
    }

    const reload = () => {
        reloadData();
        reloadCurrentUser();
    };

    const reloadAfterUserInvite = (user: User) => {
      reload();
      openAssignRoleToUserModal(user);
    }

    const reloadAfterUserRole = (roles: Role[], user: User) => {
      reload();
      openAssignLicenseToUserModal(user);
    }

    function onApplicationSelected(application: Application) {
        const licenseModel = model.getFirstAvailableLicenseForApplicationById(application.id);

        if (licenseModel) {
            openAssignUserToLicenseModal(licenseModel.license);
        }
    }

    const { inviteUserModal, openInviteUserModal } = useInviteUserForCurrentCompanyModal(reloadAfterUserInvite, navigateToManageSubscriptions);
    const { assignRolesToUserModal, openAssignRoleToUserModal } = useAssignRolesToUserForCurrentCompanyModal(reloadAfterUserRole);
    const { assignLicenseToUserModal, openAssignLicenseToUserModal } = useAssignLicenseToUserForCurrentCompanyModal(reload, navigateToManageSubscriptions);
    const { assignUserToLicenseModal, openAssignUserToLicenseModal } = useAssignUserToLicenseForCurrentCompanyModal(reload, openInviteUserModal);

    const loading = useMemo(() => !users || !licenses, [users, licenses]);
    const shouldDisplayEmptyMessage = users && _.isEmpty(users);

    const content = !loading ? (
        <>
            {model.hasCompanyLicenses && (
                <>
                    <CompanyLicenseSection licenses={model.companyLicenses}/>

                    <Divider style={{ marginTop: 16, marginBottom: 16 }}/>
                </>
            )}

            {model.hasAvailableLicenses && (
                <>
                    <AvailableLicensesSection licenses={model.availableLicenses}
                                              onApplicationSelected={onApplicationSelected}
                                              onPurchaseLicenseClicked={navigateToManageSubscriptions}/>

                    <Divider style={{ marginTop: 16, marginBottom: 16 }}/>
                </>
            )}

            <Divider style={{ marginTop: 16, marginBottom: 16 }}/>

            <UsersSection users={users || []}
                          onAddLicense={openAssignLicenseToUserModal}
                          onAddRole={(user: User) => openAssignRoleToUserModal(user)}
                          onLicenseRevoked={reload}
                          onRoleRevoked={reload}
                          onUserDeleted={reload}/>
        </>
    ) : <LoadingIndicator/>;

    return (
        <>
            <TabDetail title='Users'
                       trailing={canAddUserToCompany && !loading && !shouldDisplayEmptyMessage && (
                           <Button color='primary'
                                   variant='contained'
                                   style={{ alignSelf: 'flex-end' }}
                                   onClick={openInviteUserModal}>Invite user</Button>
                       )}>

                {shouldDisplayEmptyMessage && (
                    <EmptyUsersMessage onClick={openInviteUserModal} />
                )}

                {content}
            </TabDetail>

            {inviteUserModal}
            {assignRolesToUserModal}
            {assignLicenseToUserModal}
            {assignUserToLicenseModal}
        </>
    );
}