import React from 'react';
import { RouteComponentProps, withRouter} from "react-router-dom";
import {config} from "../../config";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "./Footer";

const useStyles = makeStyles(theme => ({
  copyright: {
    color: theme.palette.grey[500],
    marginRight: "7px"
  },
  termsLink: {
    marginRight: "7px"
  }
}));

function DefaultFooter(props: RouteComponentProps) {
  const classes = useStyles();

  return (
    <Footer>
        <div className={classes.copyright}>{config.copyright}</div>

        <div>
          <a className={classes.termsLink} href={config.termsOfServiceUrl}>terms</a>

          <a href={config.privacyPolicyUrl}>privacy</a>
        </div>
    </Footer>
  );
}

export default withRouter(DefaultFooter);
