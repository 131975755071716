import React, {useMemo} from 'react';
import _ from "lodash";
import {withRouter, RouteComponentProps, Switch, Route} from "react-router-dom";
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {CssBaseline} from "@material-ui/core";
import {environment} from "./env";
import AuthService, {AzureAuthService, AuthenticationResponse} from "@gsb/react-auth";
import HttpService, {FetchHttpService} from "@gsb/react-http";
import {RepoProvider} from "./repos/useRepo";
import {AuthInterceptor} from "./interceptors/AuthInterceptor";
import {Services} from "./services";
import {ServiceProvider} from "./services/useServices";
import App from "./App";
import StripeService, {DefaultStripeService} from "./services/StripeService";
import {DefaultRepo, Repo} from "./repos/Repo";
import {config} from "./config";
import {theme} from "./theme";
import {Routes} from "./Routes";
import withNavigation from "./services/navigation/withNavigation";
import {useAuthState, withAuthState} from "./services/auth/useAuthState";
import AuthCallback from "./components/auth/Callback";
import useSessionExpirationModals from './components/modals/useSessionExpirationModals';
import {DefaultHeaderInterceptor} from "./interceptors/DefaultHeaderInterceptor";
import {useNavigation} from "./services/navigation/useNavigation";

interface Props extends RouteComponentProps {

}

function AppSetup(props: Props) {
  setFavIcons();

  const { setAuthResponse, setAuthError } = useAuthState();
  const { navigateToRoute } = useNavigation()

  const parseStateFromAuthResponse = (authResponse?: AuthenticationResponse) => {
    if (authResponse && authResponse.accountState) {
      try {
        return JSON.parse(authResponse.accountState);
      } catch (err) {
        console.warn(err)
      }
    }

    return null;
  };

  const authService: AuthService = useMemo(() => {
    return new AzureAuthService(config.auth, (err, res) => {
      if (err) {
        setAuthError(err);
      } else if (res) {
        setAuthResponse(res);
        const state = parseStateFromAuthResponse(res);

        if (state) {
          const { redirectUri, isImpersonatingUser } = state;

          if (isImpersonatingUser) {
            config.auth.authorityUrl = environment.impersonationAuthorityUrl;
          }

          navigateToRoute(redirectUri)
        }
      }
    });
  }, []);

  const onUnauthorized = () => {
    setSessionExpired(true);
  };

  const onAuthResponse = (token?: AuthenticationResponse) => {
    setExpiresOn(_.get(token, "expiresOn", null));
  }

  const triggerRefresh = () => {
    authService.loginRedirect({ redirectUri: window.location.href });
  }
  const {
    setSessionExpired,
    setExpiresOn,
    sessionExpiredModal,
    sessionExpiringToast
  } = useSessionExpirationModals(triggerRefresh);

  const httpService: HttpService = useMemo(() => {
    return new FetchHttpService({
      baseUrl: environment.apiBaseUrl,
      interceptors: [
        new AuthInterceptor(authService, onUnauthorized, onAuthResponse),
        new DefaultHeaderInterceptor()
      ],
    })
  }, [authService]);

  const stripeService: StripeService = new DefaultStripeService();

  const services: Services = useMemo(() => ({ authService, httpService, stripeService }), [authService, httpService]);
  const repo: Repo = useMemo(() => new DefaultRepo(httpService), [httpService]);

  return (
      <ServiceProvider services={services}>
        <RepoProvider repo={repo}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
              <Route path={Routes.authCallback} render={() => <AuthCallback/>} />
              <Route path={'*'} render={() => (
                <>
                  <App/>
                  { sessionExpiredModal }
                  { sessionExpiringToast }
                </>
              )} />
            </Switch>
          </ThemeProvider>
        </RepoProvider>
      </ServiceProvider>
  );

  function setFavIcons() {
    const smallIconLink = document.getElementById('small-icon') as HTMLLinkElement;
    const bigIconLink = document.getElementById('big-icon') as HTMLLinkElement;
    const appleIconLink = document.getElementById('apple-icon') as HTMLLinkElement;

    if (smallIconLink) {
      smallIconLink.href = environment.smallIconUrl;
    }
    if (bigIconLink) {
      bigIconLink.href = environment.bigIconUrl;
    }
    if (appleIconLink) {
      appleIconLink.href = environment.appleIconUrl;
    }
  }
}

export default withAuthState(withRouter(withNavigation(AppSetup)));
