import React from 'react';
import { Theme, WithTheme} from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";
import {green} from "@material-ui/core/colors";
import Badge, {BadgeProps} from "./Badge";
import {QuestionStatusIdentifier} from "../models/QuestionStatus";

interface Props extends WithTheme {
    status: string
}

function modelForStatusWithTheme(status: string, theme: Theme): BadgeProps {
    switch (status) {
        case QuestionStatusIdentifier.submitted:
            return { title: "Pending", color: "white", textColor: theme.palette.grey[500] };
        case QuestionStatusIdentifier.researching:
            return { title: "In Progress", color: "white", textColor: theme.palette.grey[500] };
        case QuestionStatusIdentifier.answered:
            return { title: "In Progress", color: "white", textColor: theme.palette.grey[500] };
        case QuestionStatusIdentifier.onHold:
            return { title: "On Hold", color: theme.palette.error.light, borderColor: theme.palette.error.dark };
        case QuestionStatusIdentifier.released:
            return { title: "Answered", color: green[300], borderColor: green[400] };
        default:
            return { title: status, color: "white", textColor: theme.palette.grey[500] };
    }
}

 function QuestionStatusBadge(props: Props) {
    const { status, theme } = props;
    const model = modelForStatusWithTheme(status, theme);

     return (
         <Badge color={model.color} title={model.title} borderColor={model.borderColor} textColor={model.textColor} />
     );
}

export default withTheme(QuestionStatusBadge)