import * as Yup from "yup";
import {AssignLicensesForUserRequest, AssignUserToLicenseRequest} from "../../repos/UserRepo";

export const assignLicenseSchema = Yup.object().shape({
    licenseIds: Yup.array().of(Yup.string()).required(),
});

export const initialValuesForAssignLicenseRequest = (): AssignLicensesForUserRequest  => ({
    licenseIds: []
});


export const assignUserToLicenseSchema = Yup.object().shape({
    userId: Yup.string().required(),
});

export const initialValuesForAssignUserToLicenseRequest = (): AssignUserToLicenseRequest  => ({
    userId: ''
});
