import Question from "../models/Question";
import HttpService from "@gsb/react-http";
import {sanitize} from "../utils/FormUtils";

export type AskQuestionRequest = { body: string, topicId: string, email?: string, previousQuestionId?: string };

export interface QuestionRepo {
    getQuestion(questionId: string) : Promise<Question>;
    getMyQuestions(): Promise<Question[]>;
    askQuestion(body: AskQuestionRequest): Promise<Question>;
}

export class HttpQuestionRepo implements QuestionRepo {

    constructor(private http: HttpService) {}

    askQuestion(body: AskQuestionRequest): Promise<Question> {
        return this.http.post(['ti', 'v1', 'members', 'current', 'questions'], sanitize(body));
    }

    getMyQuestions(): Promise<Question[]> {
        return this.http.get(['ti', 'v1', 'members', 'current', 'questions']);
    }

    getQuestion(questionId: string): Promise<Question> {
        return this.http.get(['ti', 'v1', 'members', 'current', 'questions', questionId]);
    }
}
