import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    tipsSection: {
        display: "flex",
        flexWrap: "wrap"
    },
    tip: {
        margin: "6px",
        maxWidth: "500px"
    }
}));

interface Tip {
    title: string;
    body: string;
}

const tips: Tip[] = [
    {
        title: "Include relevant details in your body",
        body: "The Small Business consulting team wants to clearly understand your business questions and requests. " +
            "Try to be clear and concise and limit the amount of words to 1,000 or less."
    },
    {
        title: "Include detailed background information",
        body: "Along with your body, please include relevant details and background information for our consultants to use in their research and response."
    },
    {
        title: "Contact us if you're not satisfied",
        body: "If the response you receive doesn't address your specific body or if you're overdue a response, let us know. " +
            "If a response to your body seems off base, submit a clarifying body or a statement regarding your concern. " +
            "Once your concern has been brought to our attention, you will receive a prompt review from our consultant center supervisors."
    },
];


export default function Tips() {
    const classes = useStyles();
    return (
        <div>
            <h2>Tips for working with your consulting team</h2>

            <div className={classes.tipsSection}>

                {tips.map((tip, index) =>
                    <div key={`tip-${index}`} className={classes.tip}>
                        <h3>{tip.title}</h3>
                        <p>{tip.body}</p>
                    </div>
                )}

            </div>
        </div>
    );
}