import { Typography } from '@material-ui/core';
import React from 'react';
import withDialog from '../dialog/withDialog';


export function GSBFeatureText() {
  return (
      <>
        <Typography gutterBottom>As a GoSmallBiz Member, you have access to a dedicated support team during standard operating hours. The GSB Support Team is available to answer any questions regarding your membership and the functionality of the included applications. For immediate assistance, you can contact the GSB Support Team directly at <a href='mailto:cservice@gosmallbiz.com'>cservice@gosmallbiz.com</a> or by phone at 866.467.6249 between the hours of 9AM and 7PM ET, Monday - Friday.</Typography>
        <Typography gutterBottom>The GoSmallBiz Consultation Service is designed to provide small business owners with a resource to ask any question large or small that would typically be asked of a business consultant. If you have any questions regarding your business please feel free to ask!</Typography>
      </>
  );
}

export const GSBFeatureDialog = withDialog(GSBFeatureText);