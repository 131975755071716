import * as React from "react";
import {useState} from "react";
import {ERCDialog} from "./ERCDialog";
import {useRepo} from '../../repos/useRepo';
import { User } from "../../models/User";
import {Routes} from "../../Routes";

export default function useGSBFeatureDialog() {
    const [open, setOpen] = useState(false);
    const [topicId, setTopicId] = useState<string|undefined>();
    const { userRepo } = useRepo();

    const handleOnOpen = (faqTopicId?: string) => {
        setOpen(true);
        setTopicId(faqTopicId);
    }

    const handleOnClose = (acknowledged:boolean, redirect: boolean = false) => {
        setOpen(false);
        if(acknowledged === true){
            userRepo.getUserById('current')
                .then((user) => acknowledgeERC(user, redirect))
                .catch(console.error);
        }
    }

    const acknowledgeERC = (user: User, redirect: boolean) => {
        const body = {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            acknowledgedERC: true
        }
        userRepo.updateUserById('current', body).then(() => {
            sessionStorage.setItem('acknowledgedERC', '1');
            if(topicId && redirect){
                window.location.assign('/faqs?topicId=' + topicId);
            }
        });
    }
    
    const modal =  (
        <ERCDialog
            open={open}
            topicId={topicId}
            title={'Looking for info on the Employee Retention Credit?'}
            hideCloseX={true}
            closeActionText={'I Understand'}
            disableBackdropClick={true}
            maxWidth={'md'}
            onRedirect={(r:boolean) => handleOnClose(r, true)}
            onClose={handleOnClose}
        />
    );

    return { openERCDialog: handleOnOpen, ercDialog: modal }
}
