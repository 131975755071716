import React, {CSSProperties} from 'react';

export interface BadgeProps {
    title: string;
    textColor?: string;
    color?: string;
    borderColor?: string;
    style?: CSSProperties;
}

export default function Badge(props: BadgeProps) {

    const { color, textColor, title, borderColor, style } = props;

    return (
        <div style={{
            textAlign: "center",
            backgroundColor: color,
            color: textColor || "white",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: borderColor,
            borderRadius: "7px",
            padding: "4px 7px",
            fontSize: "10px",
            fontWeight: 600,
            fontFamily: "Helvetica",
            whiteSpace: "nowrap",
            ...style
        }}>
            {title}
        </div>
    );
}
