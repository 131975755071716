import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {EntityType} from "../../../models/CompanyProfile";

export default function EntityTypePicker(props: PickerPropsWithoutOptions) {
    const { entityTypeRepo } = useRepo();

    const [entityTypes, setEntityTypes] = useState<EntityType[]>([]);

    useEffect(() => {
        entityTypeRepo.list().then(setEntityTypes).catch(console.error);
    }, [entityTypeRepo]);

    const entityTypeOptions = useMemo(() => entityTypes.map(t => ({ id: t, label: t })), [entityTypes]);

    return <PickerInput
        name={props.name || "entityTypeId"}
        label={props.label || "Entity Type"}
        options={entityTypeOptions}
        {...props}
    />
}
