import React, {useEffect} from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import {useAuthState} from "./useAuthState";
import {useServices} from "../useServices";
import {Routes} from "../../Routes";

export default function withAuthentication<T>(Component: React.ComponentType<T>): React.FC<T> {
    return (props) => {
        const { isAuthenticated, setAuthResponse, setAuthError } = useAuthState();
        const { authService } = useServices();

        useEffect(() => {
            if (isAuthenticated === undefined) {
                authService.getToken().then(setAuthResponse).catch(setAuthError)
            }
        }, [authService, isAuthenticated]);

        useEffect(() => {
            if (isAuthenticated === false) {
                const redirectUri = window.location.pathname || Routes.myQuestions;
                authService.loginRedirect({ redirectUri });
            }
        }, [isAuthenticated]);

        if (isAuthenticated === true) {
            return <Component {...props}/>;
        } else if (isAuthenticated === false) {
            return null;
        } else {
            return <LoadingIndicator/>;
        }
    };
}
