import React from "react";
import {Button} from "@material-ui/core";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";

interface Props {
    onAddUserClicked: () => void;
}

export default function NoUsersAvailable({ onAddUserClicked }: Props) {
    const { can } = useRbac();

    const canAddUserToCompany = can(actions.company.createUser);

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 8 }}>
            <div style={{ width: '100%', marginBottom: 18 }}>
                You currently have no users to which you can assign this license.
                This generally means that every user for your company already has access
                to this application.
            </div>

            {canAddUserToCompany && (
                <>
                    <div style={{ width: '100%', marginBottom: 18 }}>
                        You may add another user to your company by pressing
                        "Invite User".
                    </div>

                    <Button variant='contained'
                            color='primary'
                            style={{ alignSelf: 'flex-end' }}
                            onClick={onAddUserClicked}>Invite User</Button>
                </>
            )}
        </div>
    )
}

