import React, {useContext, createContext, } from 'react';
import {Services} from "./index";


const ServiceContext = createContext<Services>(null as unknown as Services);

export const useServices = () => useContext(ServiceContext);

interface Props {
    services: Services;
}

export const ServiceProvider: React.FC<Props> = ({ children, services }) => {
    return (
        <ServiceContext.Provider value={services}>
            {children}
        </ServiceContext.Provider>
    );
};
