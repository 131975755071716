import {AppleAppStoreSource} from "../../../../models/Subscription";
import * as React from "react";
import {Link, Typography} from "@material-ui/core";

interface Props {
  appleAppStoreSource: AppleAppStoreSource;
}

export default function AppleAppStoreSubscriptionDetail({ appleAppStoreSource }: Props) {
  return (
      <div>
          <Typography variant='subtitle2'>Apple App Store Subscription</Typography>
          <Typography variant='caption'>
            This subscription is managed through your Apple device.
          </Typography>
        <Link href="https://support.apple.com/en-us/HT202039" variant='caption'>
          See or cancel your Apple subscriptions
        </Link>
      </div>
  )
}
