import * as React from "react";
import {config} from "../config";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    paragraph: {
        fontFamily: "'Comfortaa', cursive",
        fontStyle: "italic",
        fontSize: "12px"
    }
}));

export default function Terms() {
    const classes = useStyles();
    const supportEmail = config.supportEmail;
    const supportEmailUrl = `mailto:${supportEmail}`;
    const termsOfServiceUrl = config.termsOfServiceUrl;

    return (
        <div>
            <p className={classes.paragraph}>
                Answered Questions have been prepared by a member of our consulting staff and reviewed by a second member of our consulting staff. There may be instances where our staff does not have complete background information or access to all of the circumstances pertaining to a particular question. Please read the consultants’ response carefully and, if we miss the point, please ask for clarification by submitting a follow-up question.
                <br/>
                <br/>
                You should also review our <a href={termsOfServiceUrl}>terms of service</a> which more fully explain the extent and limitations of our service. Questions about our consultation service should be directed to <a href={supportEmailUrl}>{supportEmail}</a>.
            </p>

        </div>
    )
}