import {Button} from "@material-ui/core";
import * as React from "react";

export default function BackButton(props: { onClick: () => void }) {
    return (
        <Button
            onClick={() => props.onClick()}
            variant="contained"
            color="primary">
            Back
        </Button>
    )
}
