import * as React from 'react';
import {createStyles, makeStyles, Paper, Theme} from '@material-ui/core';
import Title from "../Title";
import {ProfileTab} from "./tabs/ProfileTab";
import {UsersTab} from "./tabs/users/UsersTab";
import {CompanyTab} from "./tabs/CompanyTab";
import {SubscriptionTab} from "./tabs/subscriptions/SubscriptionTab";
import TabNavigation, {TabNavigationItem, TabNavigationRef} from "../tabs/TabNavigation";
import {useRef} from "react";
import { CreditCardsTab } from './tabs/cards/CreditCardsTab';
import { useRbac } from '@gsb/react-rbac';
import { actions } from '../../rbac';
import AccountTab from "./tabs/AccountTab";

export default function Manage() {
    const classes = useStyles();
    const { can } = useRbac();

    const tabRef = useRef<TabNavigationRef>(null)

    const subscriptionTabIdentifier = "subscription"

    const selectSubscriptionTab = () => {
        tabRef.current?.selectTab(subscriptionTabIdentifier)
    }

    const tabItems: TabNavigationItem[] = [
        {
            name: "Account",
            identifier: "account",
            render: () => <AccountTab/>,
        },
        {
            name: "Profile",
            identifier: "profile",
            render: () => <ProfileTab/>,
        },
        {
            name: "Company",
            identifier: "company",
            render: () => <CompanyTab/>,
        },
        {
            name: "Subscription",
            identifier: subscriptionTabIdentifier,
            render: () => <SubscriptionTab/>,
            hidden: !can(actions.company.listUsers)
        },
        {
            name: "Users",
            identifier: "users",
            render: () => <UsersTab navigateToManageSubscriptions={selectSubscriptionTab}/>,
            hidden: !can(actions.company.listUsers)
        },
        {
            name: "Cards",
            identifier: "cards",
            render: () => <CreditCardsTab/>,
            hidden: !can(actions.company.listCreditCards),
        }
    ];

    return (
        <div className={classes.root}>

            <Title title={"Manage"}/>

            <div className={classes.content}>
                <Paper className={classes.paper}>
                    <TabNavigation items={tabItems}
                                   ref={tabRef}
                                   tabClassName={classes.tabs}
                                   tabItemClassName={classes.tab}
                                   vertical/>
                </Paper>

            </div>

        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        minHeight: '480px',
        margin: 12,
        overflow: "auto",
    },
    paper: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '480px',
        maxWidth: "1400px",
        margin: '12px auto',
    },
    tabs: {
        paddingLeft: 12,
        paddingTop: 12,
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 144
    },
    tab: {
        padding: 12,
        opacity: 0.8,
        '&:hover': {
            opacity: 1,
        },
    }
}));


