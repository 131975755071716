import Faq from "../models/Faq";
import HttpService from "@gsb/react-http";

export interface FaqRepo {
    getFaqById(id: string): Promise<Faq>;
    listFaqsForTopic(topicId: string): Promise<Faq[]>
}

export class HttpFaqRepo implements FaqRepo {

    constructor(private http: HttpService) {}

    getFaqById(id: string): Promise<Faq> {
        return this.http.get(['ti', 'v1', 'members', 'current', 'faqs', id]);
    }

    listFaqsForTopic(topicId: string): Promise<Faq[]> {
        return this.http.get(['ti', 'v1', 'members', 'current', 'faqs'], {
            query: {
                topicId: topicId
            }
        });
    }
}
