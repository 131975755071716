import { BusinessSize } from "../models/CompanyProfile";

export interface BusinessSizeRepo {
  list(): Promise<BusinessSize[]>
}

export class HardcodedBusinessSizeRepo implements BusinessSizeRepo {
  private options = [
    "None (just me)",
    "1",
    "2",
    "3",
    "4",
    "5-10",
    "11-15",
    "16+"
  ];
  async list(): Promise<BusinessSize[]> {
    return this.options;
  }
}
