export class Routes {
    static authCallback = "/oauth2/callback";
    static pricing = "/pricing";
    static manage = "/manage";
    static subscription = "/subscription";
    static ask = "/questions/ask";
    static myQuestions = "/questions";
    static viewQuestion = "/questions/:questionId";
    static faqs = "/faqs";
    static viewFaq = "/faqs/:faqId";
    static impersonation = "/impersonation";

    static askFollowUpQuestion = (questionId: string) => `/questions/ask?questionId=${questionId}`;
    static viewQuestionWithId = (id: string) => `/questions/${id}`;
    static viewFaqWithId = (id: string) => `/faqs/${id}`;
    static viewFaqsForTopicWithId = (id: string) => `/faqs?topicId=${id}`;
}
