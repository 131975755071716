import * as React from "react";

export default function HTML(props: { rawHtml: string, style?: React.CSSProperties}) {
    const { rawHtml, style } = props;
    const html = { __html: rawHtml  };

    return (
        <div style={style} dangerouslySetInnerHTML={html}/>
    );
}
