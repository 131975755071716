import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

export default function BusinessSizePicker(props: PickerPropsWithoutOptions) {
    const { businessSizeRepo } = useRepo();

    const [businessSizes, setBusinessSizes] = useState<string[]>([]);

    useEffect(() => {
        businessSizeRepo.list().then(setBusinessSizes).catch(console.error);
    }, [businessSizeRepo]);

    const businessSizeOptions = useMemo(() => businessSizes.map(s => ({ id: s, label: s })), [businessSizes]);

    return <PickerInput
        name={props.name || "businessSizeId"}
        label={props.label || "Size"}
        options={businessSizeOptions}
        {...props}
    />
}