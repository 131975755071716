import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FaqTips from "./FaqTips";
import { Routes } from "../Routes";
import Faq from "../models/Faq";
import Topic from "../models/Topic";
import FaqListItem from "./FaqListItem";
import FaqListHeader from "./FaqListHeader";
import LoadingIndicator from "./LoadingIndicator";
import { useRepo } from "../repos/useRepo";
import useERCDialog from "./dialogs/useERCDialog";
import { config } from '../config';

interface Props extends RouteComponentProps {
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: "0 auto",
        alignContent: "center",
        width: "100%",
        /*overflow: "scroll"*/
    },
    content: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "start",
        padding: "12px",
        maxWidth: "1600px",
        margin: "0 auto",
        minHeight: "48px",
        flexWrap: "wrap"
    },
    leftSection: {
        marginTop: "16px",
        [theme.breakpoints.up('sm')]: {
            marginRight: "32px",
        },
    },
    rightSection: {
        marginTop: "16px",
    },
    faqContainer: {
        marginBottom: "12px",
        maxWidth: "1000px"
    }
}));

function FaqListPage(props: Props) {
    const classes = useStyles();

    const { faqRepo, topicRepo } = useRepo();

    const [faqs, setFaqs] = useState<Faq[]>();
    const [topic, setTopic] = useState<Topic>();

    const params = new URLSearchParams(props.location.search);
    const topicId = params.get('topicId');

    const { openERCDialog, ercDialog } = useERCDialog();

    const topicHandler = (topic: Topic) => {
        setTopic(topic);

        if (topic.id.toLowerCase() === config.topicIds.erc && !(sessionStorage.getItem('acknowledgedERC') === '1')) {
            openERCDialog();
        }
    }

    useEffect(() => {
        if (topicId) {
            topicRepo.getTopicById(topicId)
                .then(topic => topicHandler(topic))
                .catch(err => console.error(err))
        }
    }, [topicRepo, topicId]);

    useEffect(() => {
        if (topicId) {
            faqRepo.listFaqsForTopic(topicId)
                .then(faqs => setFaqs(faqs))
                .catch(err => console.error(err))
        }
    }, [faqRepo, topicId]);

    const onFaqSelected = (faq: Faq) => props.history.push(Routes.viewFaqWithId(faq.id));

    return (
        <div className={classes.root}>

            <div className={classes.content}>
                <div className={classes.leftSection}>
                    <FaqTips onAskQuestionClicked={() => props.history.push(Routes.ask)} />
                </div>

                <div className={classes.rightSection}>

                    {topic && <FaqListHeader topic={topic} />}

                    <FaqListContent faqs={faqs} onFaqSelected={onFaqSelected} />

                </div>
            </div>
            {ercDialog}
        </div>
    );
}

function FaqListContent(props: { faqs?: Faq[], onFaqSelected: (faq: Faq) => void }) {
    const { faqs, onFaqSelected } = props;
    const classes = useStyles();

    if (faqs) {
        if (faqs.length === 0) {
            return (
                <div>No FAQs to display</div>
            )
        } else {
            return (
                <>
                    {faqs.map(faq => {
                        return (
                            <div key={faq.id} className={classes.faqContainer}>
                                <FaqListItem faq={faq} onSelected={onFaqSelected} />
                            </div>
                        )
                    })}
                </>
            )
        }
    } else {
        return <LoadingIndicator />
    }
}


export default withRouter(FaqListPage);
