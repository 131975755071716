import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button} from "@material-ui/core";

interface Props {
    onAskQuestionClicked: () => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "block",
        maxWidth: "256px",
        margin: "8px",
        borderRadius: "6px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.palette.primary.light
    },
    topSection: {
        padding: "8px",
        textAlign: "center",
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px",
    },
    middleSection: {
        padding: "8px",
        textAlign: "center"
    },
    bottomSection: {
        padding: "8px",
        backgroundColor: theme.palette.grey[200],
        textAlign: "center",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: theme.palette.grey[300],
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
    }
}));

export default function FaqTips(props: Props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>

            <div className={classes.topSection}>
                Still Looking for Answers?
            </div>

            <div className={classes.middleSection}>
                If you can't find the information you need in our knowledge base, ask a question to one of our consultants.
            </div>

            <div className={classes.bottomSection}>
                <Button variant="contained"
                        color="primary"
                        onClick={() => props.onAskQuestionClicked()}>
                    Ask a Question
                </Button>

            </div>
        </div>
    );
}