import {User} from "../../../../models/User";
import {License} from "../../../../models/License";
import {Role} from "../../../../models/Role";
import {default as React, useState} from "react";
import {useRepo} from "../../../../repos/useRepo";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {
    Button, createStyles,
    Divider,
    ExpansionPanel,
    ExpansionPanelActions,
    ExpansionPanelDetails,
    ExpansionPanelSummary, makeStyles, Theme,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Chip} from "../../../form/chips/Chip";
import ChipButton from "../../../form/chips/ChipButton";

interface Props {
    users: User[];
    onAddLicense: (user: User) => void;
    onAddRole: (user: User) => void;
    onLicenseRevoked: (license: License) => void,
    onRoleRevoked: (role: Role) => void;
    onUserDeleted: (user: User) => void
}

export default function UsersSection({ users, onAddLicense, onAddRole, onLicenseRevoked, onRoleRevoked, onUserDeleted }: Props) {
    return (
        <div>
            {users.map((user, index) => (
                <UserRow key={`user-row-${index}`}
                         user={user}
                         onAddLicense={() => onAddLicense(user)}
                         onAddRole={() => onAddRole(user)}
                         onLicenseRevoked={onLicenseRevoked}
                         onRoleRevoked={onRoleRevoked}
                         onUserDeleted={onUserDeleted}
                />
            ))}
        </div>
    )
}


interface UserRowProps {
    user: User;
    onAddLicense: () => void;
    onAddRole: () => void;
    onLicenseRevoked: (license: License) => void;
    onRoleRevoked: (role: Role) => void;
    onUserDeleted: (user: User) => void;
}

function UserRow({ user, onAddLicense, onAddRole, onLicenseRevoked, onRoleRevoked, onUserDeleted }: UserRowProps) {
    const [expanded, setExpanded] = useState(false);
    const { companyRepo } = useRepo();
    const { email, firstName, lastName, roles, licenses } = user;
    const classes = useStyles();

    const { can } = useRbac();
    const canDeleteUserFromCompany = can(actions.company.deleteUser);

    function deleteUser() {
        companyRepo.deleteUserForCompanyById('current', { userId: user.id })
            .then(() => onUserDeleted(user))
            .catch(console.error)
    }

    return (
        <ExpansionPanel expanded={expanded} onChange={() => setExpanded(!expanded)} TransitionProps={{ unmountOnExit: true }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.panelSummary }}>
                <Typography className={classes.heading}>{`${firstName} ${lastName}`} </Typography>
                <Typography className={classes.secondaryHeading}>{email}</Typography>
            </ExpansionPanelSummary>
            <Divider/>
            <ExpansionPanelDetails>
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <UserRowLicensesSection user={user} licenses={licenses || []} onLicenseRevoked={onLicenseRevoked} onAddLicense={onAddLicense}/>

                    <UserRowRolesSection user={user} roles={roles || []} onRoleRevoked={onRoleRevoked} onAddRole={onAddRole}/>

                </div>

            </ExpansionPanelDetails>

            {canDeleteUserFromCompany &&
            <>
              <Divider/>

              <ExpansionPanelActions>
                <Button onClick={deleteUser} size='small'>Delete</Button>
              </ExpansionPanelActions>
            </>
            }

        </ExpansionPanel>
    )
}

function UserRowLicensesSection(props: { user: User, licenses: License[], onLicenseRevoked: (license: License) => void; onAddLicense: () => void }) {
    const { user, licenses, onLicenseRevoked, onAddLicense } = props;
    const { can } = useRbac();
    const { userRepo } = useRepo();

    const canAssignLicense = can(actions.user.assignLicense);
    const canRevokeLicense = can(actions.user.revokeLicense);

    function revokeLicense(license: License) {
        userRepo.revokeLicensesForUserById(user.id, { licenseIds: [license.id] })
            .then(() => onLicenseRevoked(license))
            .catch(console.error)
    }

    return (
        <div style={{ marginTop: 6, marginBottom: 6 }}>
            <Typography variant='caption' display='block'>Licenses</Typography>

            <div>
                {licenses.map((license, index) => (
                    <Chip key={`license-chip-${index}`}
                          disabled={!canRevokeLicense}
                          label={license.application ? license.application.name : license.name}
                          onDelete={canRevokeLicense ? () => revokeLicense(license) : undefined}/>
                ))}

                {canAssignLicense &&
                <ChipButton label='Add License' color='secondary' onClick={onAddLicense}/>
                }
            </div>

        </div>
    )
}


function UserRowRolesSection(props: { user: User, roles: Role[], onRoleRevoked: (role: Role) => void; onAddRole: () => void }) {
    const { user, roles, onRoleRevoked, onAddRole } = props;
    const { can } = useRbac();
    const { userRepo } = useRepo();

    const canAssignRole = can(actions.user.assignRole);
    const canRevokeRole = can(actions.user.revokeRole);

    function revokeRole(role: Role) {
        userRepo.revokeRolesForUserById(user.id, { roleIds: [role.id] })
            .then(() => onRoleRevoked(role))
            .catch(console.error)
    }

    return (
        <div style={{ marginTop: 6, marginBottom: 6 }}>
            <Typography variant='caption' display='block'>Roles</Typography>

            <div>
                {roles.map((role, index) => (
                    <Chip key={`role-chip-${index}`}
                          label={role.name}
                          disabled={!canRevokeRole}
                          onDelete={canRevokeRole ? () => revokeRole(role) : undefined}/>
                ))}

                {canAssignRole &&
                <ChipButton label='Assign Role' color='secondary' onClick={onAddRole}/>
                }

            </div>

        </div>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        panelSummary: {
            backgroundColor: theme.palette.background.paper,
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            marginLeft: theme.spacing(1),
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    }),
);
