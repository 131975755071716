import {CreateUserForCurrentCompanyFormModal} from "../forms/UserProfileForm";
import * as React from "react";
import {User} from "../../../models/User";
import {useState} from "react";
import { Modal } from "../../modal";
import {useStartupResult} from "../../../services/startup/useStartupResult";

type ForbiddenMessageProps = {
  name: string;
  open: boolean;
  close: () => void;
}

const ForbiddenMessageModal = ( { name, open, close }: ForbiddenMessageProps ) => (
  <Modal open={open} onClose={close} title="Action Forbidden">
    <div style={{ marginTop: 12, marginBottom: 12 }}>
        Users for your account are managed by your { name } subscription.
    </div>
  </Modal>
);

export default function useInviteUserForCurrentCompanyModal(onUserCreated: (user: User) => void, onPurchaseLicensesClicked: () => void) {

    const { reseller } = useStartupResult();
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = reseller.allowsUserInvitation
      ? (
        <CreateUserForCurrentCompanyFormModal
            title='Add a User'
            open={open}
            onClose={close}
            onPurchaseLicensesClicked={() => {
                close();
                onPurchaseLicensesClicked();
            }}
            onUserCreated={(user) => {
                close();
                onUserCreated(user)
            }} />
    ) : (
      <ForbiddenMessageModal
        name={reseller.name}
        open={open}
        close={close}
      />
    );

    return {
        inviteUserModal: modal,
        openInviteUserModal: () => setOpen(true)
    }
}