import {environment} from "../env";
const stripe = (window as any).Stripe(environment.stripePublishableKey);

export type StripeRedirectOptions = { sessionId: string };

export type StripeRedirectResponse = never | {  error: any; };

export default interface StripeService {
    redirectToCheckout(options: StripeRedirectOptions): Promise<StripeRedirectResponse>;
}

export class DefaultStripeService implements StripeService {
    redirectToCheckout(options: StripeRedirectOptions): Promise<StripeRedirectResponse> {
        return stripe.redirectToCheckout(options);
    }
}