import React, {CSSProperties} from 'react';
import { Theme, WithTheme} from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";
import Badge, {BadgeProps} from "./Badge";
import {SubscriptionStatus} from "../models/Subscription";

interface Props extends WithTheme {
    status: string
}

function modelForStatusWithTheme(status: string, theme: Theme): BadgeProps {
    switch (status) {
        case SubscriptionStatus.active:
        case SubscriptionStatus.trialing:
            return { title: "Active", color: 'white', borderColor: theme.palette.secondary.main, textColor: theme.palette.secondary.main };
        case SubscriptionStatus.failed:
        case SubscriptionStatus.expired:
        case SubscriptionStatus.canceled:
            return { title: status, color: theme.palette.error.light, borderColor: theme.palette.error.dark };
        default:
            return { title: status, color: "white", textColor: theme.palette.grey[500] };
    }
}

 function SubscriptionStatusBadge(props: Props & { style?: CSSProperties }) {
    const { status, theme, style } = props;
    const model = modelForStatusWithTheme(status, theme);

     return (
         <Badge color={model.color} title={model.title} borderColor={model.borderColor} textColor={model.textColor} style={style} />
     );
}

export default withTheme(SubscriptionStatusBadge)