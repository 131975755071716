import { GsbNavigationLinkProvider } from "./GsbNavigationLinkProvider";
import { UpneticNavigationLinkProvider } from "./UpneticNavigationLinkProvider";
import { NavigationLink } from "../components/layout/Navigation";
import { environment } from "../env";

export interface NavigationLinkProvider {
  getNavigationLinks: () => NavigationLink[];
}

export const DefaultNavigationLinkProvider = environment.brand === "upnetic"
  ? UpneticNavigationLinkProvider
  : GsbNavigationLinkProvider;