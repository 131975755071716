import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import {Dialog as MaterialDialog, DialogActions as MuiDialogActions, DialogContent as MuiDialogContent, DialogContentText, DialogTitle as MuiDialogTitle}from '@material-ui/core';

export interface DialogProps {
    title?: string;
    open: boolean;
    hideCloseX?: boolean;
    closeActionText?: string;
    disableBackdropClick?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    onClose: (result: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  showClose: boolean;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, showClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {showClose && onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Dialog: React.FC<DialogProps> = ({ title, open, onClose, children, maxWidth = "sm", hideCloseX = false, closeActionText = 'close', disableBackdropClick = false }) => {

    const handleDialogOnClose = (e:any, reason: string) => {
      if(!disableBackdropClick ){
        onClose(false);
      } else if(reason !== 'backdropClick') {
        onClose(false);
      }
    }

    return (
        <MaterialDialog
          open={open}
          onClose={handleDialogOnClose}
          fullWidth={true}
          maxWidth={maxWidth}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="customized-dialog-title"  onClose={() => onClose(false)} showClose={!hideCloseX}>{title}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">
                {children}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(true)} color="primary" autoFocus>
              {closeActionText}
            </Button>
          </DialogActions>
        </MaterialDialog>
    );
};

export default Dialog;