import * as React from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { AskQuestionRequest } from "../../repos/QuestionRepo";
import FormControl from "@material-ui/core/FormControl";
import Question from "../../models/Question";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import UserInfo from "../../models/UserInfo";
import { useRepo } from "../../repos/useRepo";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { initialValuesForQuestion, questionSchema } from "../../utils/schemas/question-schema";
import TopicPicker from "../form/pickers/TopicPicker";
import TextInput from "../form/inputs/TextInput";
import { theme } from "../../theme";
import useGSBFeatureDialog from "../dialogs/useGSBFeatureDialog";
import useERCDialog from "../dialogs/useERCDialog";
import { config } from '../../config';

const useStyles = makeStyles((theme: Theme) => createStyles({
    formSection: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginRight: "24px",
        minWidth: 300
    },
    formControl: {
        marginBottom: theme.spacing(1)
    },
    topicSelect: {
        alignSelf: "flex-start",
        width: "200px",
        background: theme.palette.grey[100],
    },
    submitButtonWrapper: {
        alignSelf: "flex-end",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "18px"
    },
    submitButton: {
    },
    input: {
        background: theme.palette.grey[100],
    },
    submitButtonProgressSpinner: {
        marginRight: "12px",
        marginBottom: "6px"
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: theme.palette.secondary.light
        },
        '&:hover $notchedOutline': {
            borderColor: theme.palette.secondary.light
        }
    },
    bottomSection: {
        display: "flex",
        alignItems: "center"
    },
    emailFieldWrapper: {
        alignSelf: "start"
    },
    emailDescription: {
        flex: 1
    },
    emailSection: {
        paddingLeft: "4px",
        marginTop: "6px",
        marginBottom: "10px"
    },
    defaultEmailSpan: {
        fontWeight: 700,
        fontSize: "small"
    },
    errorLabel: {

    }
}));

export function AskQuestionFields() {
    const { values, setFieldValue } = useFormikContext<AskQuestionRequest>();

    const { openGSBFeatureDialog, gsbFeatureDialog } = useGSBFeatureDialog();
    const { openERCDialog, ercDialog } = useERCDialog();

    function onChange(e: any, child?: any) {
        const { value: topicId } = e.target;
        setFieldValue('topicId', topicId);

        if (topicId === config.topicIds.gsbFeatures) {
            openGSBFeatureDialog();
        } else if (topicId === config.topicIds.erc || topicId === config.topicIds.taxAccounting) {
            openERCDialog(config.topicIds.erc);
        }

    }

    return (
        <>
            <TopicPicker name='topicId' SelectProps={{ onChange }} />

            <QuestionBodyControl />

            {gsbFeatureDialog}
            {ercDialog}
        </>
    )
}

export default function AskQuestionForm(props: { userInfo?: UserInfo, previousQuestion?: Question, onQuestionAsked: (question: Question) => void }) {
    const { questionRepo } = useRepo();
    const { userInfo, onQuestionAsked, previousQuestion } = props;
    const classes = useStyles();
    const defaultEmail = userInfo ? userInfo.email : null;

    function onSubmit(values: AskQuestionRequest, { setSubmitting }: FormikHelpers<AskQuestionRequest>) {

        setSubmitting(true);

        questionRepo.askQuestion(values)
            .then(onQuestionAsked)
            .catch(console.error)
            .finally(() => setSubmitting(false))
    }

    return (
        <Formik<AskQuestionRequest> enableReinitialize={true}
            initialValues={initialValuesForQuestion(previousQuestion)}
            validationSchema={questionSchema}
            onSubmit={onSubmit}>
            <Form className={classes.formSection}>

                <AskQuestionFields />

                <div className={classes.emailSection}>

                    {defaultEmail &&
                        <div>
                            A submission confirmation and answer notification will be sent to the following email: <span className={classes.defaultEmailSpan}>{defaultEmail}</span>
                        </div>
                    }

                    <div className={classes.bottomSection}>

                        <div className={classes.emailDescription}>

                            <div>
                                If you wish for it to be sent to a different email, please enter that email:
                            </div>

                            <QuestionEmailControl />

                        </div>

                    </div>

                </div>

                <Divider variant="middle" />

                <SubmitButton />

            </Form>
        </Formik>
    )
}

function SubmitButton() {
    const { isSubmitting } = useFormikContext();
    const classes = useStyles();

    return (
        <div className={classes.submitButtonWrapper}>

            {isSubmitting && <CircularProgress size={24} className={classes.submitButtonProgressSpinner} color="secondary" />}

            <FormControl className={classes.formControl}>

                <Button className={classes.submitButton}
                    type='submit'
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary">
                    Submit question
                </Button>

            </FormControl>

        </div>
    )
}


function QuestionBodyControl() {
    const classes = useStyles();
    return (
        <TextInput
            name='body'
            label="Enter your question"
            style={{
                width: "100%",
                color: theme.palette.secondary.light,
            }}
            multiline
            rows={20}
            rowsMax={20}
            InputProps={{
                style: {
                    background: theme.palette.grey[100],
                },
                classes: {
                    root: classes.cssOutlinedInput,
                },
            }}
        />

    )
}

function QuestionEmailControl() {
    const classes = useStyles();
    return (
        <TextInput
            name='email'
            style={{
                marginLeft: "14px",
                color: theme.palette.secondary.light,
            }}
            label="Enter your email"
            InputProps={{
                style: {
                    background: theme.palette.grey[100],
                },
                classes: {
                    root: classes.cssOutlinedInput,
                },
            }}
        />
    )
}
