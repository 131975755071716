import {AssignUserToLicenseForCurrentCompanyModal} from "../forms/AssignUserToLicenseForm";
import * as React from "react";
import {useState} from "react";
import {License} from "../../../models/License";


export default function useAssignUserToLicenseForCurrentCompanyModal(onLicenseAssigned: (license: License) => void, onInviteUserClicked: () => void) {

    const [license, setLicense] = useState<License>();
    const close = () => setLicense(undefined);

    const modal = license ? (
        <AssignUserToLicenseForCurrentCompanyModal
            title='Assign User to License'
            open={true}
            onClose={close}
            onLicenseAssigned={(license) => {
                close();
                onLicenseAssigned(license)
            }}
            onAddUserClicked={() => {
                close();
                onInviteUserClicked();
            }}
            license={license}
        />
        ): null;

    return {
        assignUserToLicenseModal: modal,
        openAssignUserToLicenseModal: setLicense
    }
}