import * as React from "react";
import _ from "lodash";
import {useEffect, useState} from "react";
import { Button, Typography } from "@material-ui/core";
import {useRepo} from "../../../../repos/useRepo";
import TabDetail from "../TabDetail";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import LoadingIndicator from "../../../LoadingIndicator";
import CardsSection from "./CardsSection";
import useCreateCardForCurrentCompanyModal from "../../modal/useCreateCardForCurrentCompanyModal";
import {PaymentMethod} from '@stripe/stripe-js';

type DefaultPaymentMethod = {
    id: string | undefined;
}

type EmptyStateMessage = {
    onClick: () => void;
};

const EmptyCardsMessage = ({ onClick }: EmptyStateMessage) => <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 48 }}>
<Typography style={{ textAlign: 'center' }}>
  Looks like you don't have a card!</Typography>
  <div>
    <Button color='primary'
      variant='contained'
      style={{ alignSelf: 'flex-0end', marginTop: 16 }}
      onClick={onClick}>Add a Credit Card</Button>
  </div>
</div>

export function CreditCardsTab() {
    const [loading, setLoading] = useState(false);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<DefaultPaymentMethod | undefined>(undefined);
    const { companyRepo } = useRepo();
    const { can } = useRbac();

    function removeCard(paymentMethod: PaymentMethod) {
        companyRepo.deleteCardForCompanyById('current', paymentMethod.id).then(reloadData);
    }

    function setDefaultCard(paymentMethod: PaymentMethod) {
        companyRepo.setDefaultPaymentMethodForCompanyById('current', paymentMethod.id).then(reloadData);
    }

    function attachCardToCompany(paymentMethod: PaymentMethod, setAsDefault: boolean) {
        companyRepo.addPaymentMethodForCompanyById('current', paymentMethod.id).then(reloadData);
    }

    const { openCreateCardModal, createCardModal } = useCreateCardForCurrentCompanyModal(attachCardToCompany);

    const canAddCardToCompany = can(actions.company.addCreditCard);

    const [cards, setCards] = useState<PaymentMethod[]>();

    useEffect(reloadData, []);

    function reloadData() {
        setLoading(true);

        Promise.all([
            companyRepo.getPaymentMethodForCompanyById('current'),
            companyRepo.listCardsForCompanyById('current')
        ])
            .then( ([defaultPaymentMethod, cards]) => {
                setDefaultPaymentMethod(defaultPaymentMethod);
                setCards(cards);
            } )
            .catch(console.error)
            .finally(() => setLoading(false))
    }

    const content = !loading ? (
        <>
            <CardsSection cards={cards || []}
                          defaultCard={defaultPaymentMethod}
                          removeCard={removeCard}
                          setDefaultCard={setDefaultCard}/>
        </>
    ) : <LoadingIndicator/>;

    const shouldDisplayEmptyMessage = _.isEmpty(cards);

    return (
        <>
            <TabDetail  title='Credit Cards'
                        trailing={canAddCardToCompany && cards && !shouldDisplayEmptyMessage && (
                            <Button color='primary'
                                    variant='contained'
                                    style={{ alignSelf: 'flex-end' }}
                                    onClick={openCreateCardModal}>Add Credit Card</Button>
                        )}>

                {shouldDisplayEmptyMessage && (
                    <EmptyCardsMessage onClick={openCreateCardModal} />
                )}

                {content}
            </TabDetail>
            { createCardModal }
        </>
    );
}