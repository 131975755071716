import {License} from "../License";
import {Application} from "../Application";

export default class LicenseModel {
    constructor(private _license: License) {}

    get index(): number {
        return this._license.index;
    }

    get application(): Application | null {
        return this.license.application || null;
    }

    get applicationId(): string {
        return this._license.applicationId;
    }

    get applicationName(): string | null {
        return this._license.application ? this._license.application.name : null;
    }

    get isActive(): boolean {
        return this._license.isActive
    }

    get isAvailable(): boolean {
        return this.isAssignable && !this._license.userId;
    }

    get isAssignable(): boolean {
        return this._license.isActive && !this._license.isCompanyLicense;
    }

    get isAssigned(): boolean {
        return this._license.isActive && !this.isAssignable && !!this._license.userId;
    }

    get isCompanyLicense(): boolean {
        return this._license.isCompanyLicense;
    }

    get license(): License {
        return this._license;
    }
}

