import React from "react";
import Dialog, {DialogProps} from "./Dialog";

const withDialog = <P extends object>(Component: React.ComponentType<P>, title?: string): React.FC<P & DialogProps> =>
    (props) => {
        const ModalProps: DialogProps = {
            title: props.title || title,
            onClose: props.onClose,
            open: props.open,
            hideCloseX: props.hideCloseX === true,
            closeActionText: props.closeActionText,
            maxWidth: props.maxWidth,
            disableBackdropClick: props.disableBackdropClick
        };

        const ComponentProps = ((props: P & DialogProps): P => {
            const p = {...props};
            delete p.title;
            delete p.onClose;
            delete p.open;
            return p;
        })(props);

        return (
            <Dialog {...ModalProps}>
                <Component {...ComponentProps}/>
            </Dialog>
        );
    };

export default withDialog;
