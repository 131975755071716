import * as React from "react";
import {Application} from "../../../models/Application";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import {Typography} from "@material-ui/core";
import NoLicensesAvailable from "./NoLicensesAvailable";
import ChipSelect from "../../form/select/ChipSelect";
import {useMemo} from "react";
import LicenseModel from "../../../models/domain/LicenseModel";

interface Props {
    licenses: LicenseModel[];
    onApplicationSelected: (application: Application) => void;
    onPurchaseLicenseClicked: () => void;
}

interface ApplicationLicenseModel {
    application: Application;
    availableLicenseCount: number;
}

type ApplicationLicenseModelMap = { [key: string]: ApplicationLicenseModel };

export default function AvailableLicensesSection({ licenses, onApplicationSelected, onPurchaseLicenseClicked }: Props) {
    const { can } = useRbac();

    const canAssignLicense = can(actions.user.assignLicense);

    // Group available licenses by application with count
    const availableApplicationLicenses: ApplicationLicenseModel[] = useMemo(() => {
        return Object.values(
            licenses
                .filter(license => license.isAvailable && !!license.application)
                .reduce((object, nextLicense) => {

                    if (object[nextLicense.applicationId]) {
                        object[nextLicense.applicationId].availableLicenseCount += 1;
                    } else {
                        object[nextLicense.applicationId] = {
                            application: nextLicense.application!, // Safe due to filter
                            availableLicenseCount: 1,
                        }
                    }

                    return object;
                }, {} as ApplicationLicenseModelMap )
        );
    }, [licenses]);

    const labelForAvailableApplicationModel = (availableApplicationModel: ApplicationLicenseModel) => {
        return `${availableApplicationModel.application.name} (${availableApplicationModel.availableLicenseCount})`
    }

    return (
        <div>
            <Typography variant='subtitle2'>Available Licenses</Typography>

            <div>
                {availableApplicationLicenses && availableApplicationLicenses.length > 0 && (
                    <>
                        <ChipSelect items={availableApplicationLicenses}
                                    ChipProps={{ disabled: !canAssignLicense }}
                                    label={labelForAvailableApplicationModel}
                                    onItemSelected={(model) => onApplicationSelected(model.application)}/>
                        <Typography variant='caption'>Select a license to assign it to a user.</Typography>
                    </>
                )}

                {availableApplicationLicenses.length === 0 && (
                    <div>
                        <NoLicensesAvailable onPurchaseLicensesClicked={onPurchaseLicenseClicked}/>
                    </div>
                )}
            </div>

        </div>
    )
}

