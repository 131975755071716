import _ from "lodash";
import createMuiTheme from "@material-ui/core/styles/createTheme";
import {PaletteColor} from "@material-ui/core/styles/createPalette";
import {environment} from "./env";

function makePaletteColor(light: string, dark: string): PaletteColor {
    return {
        light: light,
        dark: dark,
        main: light,
        contrastText: "#ffffff"
    }
}

const upneticTheme = createMuiTheme({
    palette: {
        primary: makePaletteColor("#5655a5", "#5655a5"),
        secondary: makePaletteColor("#3ab0e4", "#3ab0e4")
    },
    breakpoints:{
        values: {
            xs: 600,
            sm: 960,
            md: 1200,
            lg: 1600,
            xl: 1920,
        }
    }
});

const gsbTheme = createMuiTheme({
    palette: {
        primary: makePaletteColor("#2bd191", "#2bd191"),
        secondary: makePaletteColor("#3573e0", "#3573e0")
    },
    breakpoints:{
        values: {
            xs: 600,
            sm: 960,
            md: 1200,
            lg: 1600,
            xl: 1920,
        }
    }
});

const customGsbStyle = {
    header: {
        root: {
            backgroundColor: gsbTheme.palette.secondary.main,
            boxShadow: "none",
        },
    },
    home: {
        root: {
            backgroundColor: "#ffffff",
        },
    },
    navigation: {
        root: {
            backgroundColor: gsbTheme.palette.secondary.main,
        },
        navigationItem: {
            color: "#ffffff",
            "&:disabled": {
                color: gsbTheme.palette.primary.light,
            },
        },
    },
};

const customUpneticStyle = {
    header: {
        root: {
            backgroundColor: upneticTheme.palette.primary.main,
        }
    },
    home: {
        root: {}
    },
    navigation: {
        root: {
            backgroundColor: upneticTheme.palette.primary.main,
        },
        navigationItem: {
            color: "#ffffff",
            "&:disabled": {
                color: upneticTheme.palette.secondary.light
            },
        }
    }
};

const customBrandStyle = {
  gsb: customGsbStyle,
  upnetic: customUpneticStyle,
};

export const theme = environment.brand === 'upnetic' ? upneticTheme : gsbTheme;
export const customStyle = customBrandStyle[environment.brand];
