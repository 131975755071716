import {Form, Formik, FormikHelpers} from "formik";
import {Company, UpdateCompanyRequest} from "../../../models/Company";
import React, {useEffect, useState} from "react";
import {companySchema, initialValuesForCompany} from "../../../utils/schemas/company-schema";
import {useRepo} from "../../../repos/useRepo";
import LoadingIndicator from "../../LoadingIndicator";
import IndustryPicker from "../../form/pickers/IndustryPicker";
import BusinessSizePicker from "../../form/pickers/BusinessSizePicker";
import EntityTypePicker from "../../form/pickers/EntityTypePicker";
import BusinessTypePicker from "../../form/pickers/BusinessTypePicker";
import StatePicker from "../../form/pickers/StatePicker";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import TimeZonePicker from "../../form/pickers/TimeZonePicker";
import TextInput from "../../form/inputs/TextInput";
import {FormSubmitButton, FormVariant} from "../../form/buttons/FormSubmitButton";
import {CurrentCompanyUserPicker} from "../../form/pickers/UserPicker";

interface Props {
    disabled?: boolean;
    initialValues: UpdateCompanyRequest;
    onSubmit: (values: UpdateCompanyRequest, {}: FormikHelpers<UpdateCompanyRequest>) => void;
    variant: FormVariant;
}

function CompanyProfileForm({ disabled, initialValues, onSubmit, variant }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={companySchema} onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <TextInput name='name' label='Company Name' disabled={disabled}/>

                <TimeZonePicker name='timeZone' disabled={disabled} allowEmpty={true} emptyValue='' emptyValueDescription='-'/>

                <CurrentCompanyUserPicker name='primaryContactId' label='Primary Contact' disabled={disabled}/>

                <IndustryPicker name='profile.industryId' disabled={disabled}/>

                <BusinessSizePicker name='profile.businessSize' disabled={disabled}/>

                <TextInput name='profile.yearFounded' label="Year Founded" type="number" disabled={disabled}/>

                <EntityTypePicker name='profile.entityType' disabled={disabled}/>

                <BusinessTypePicker name='profile.businessType' disabled={disabled}/>

                <StatePicker name='profile.stateCode' disabled={disabled}/>

                <TextInput name='website' label="Website" type="url" disabled={disabled}/>

                <FormSubmitButton variant={variant} disabled={disabled}/>

            </Form>
        </Formik>
    )
}

interface UpdateCompanyFormProps {
    company: Company;
    onCompanyUpdated: (company: Company) => void;
}

export function UpdateCompanyForm({ company, onCompanyUpdated }: UpdateCompanyFormProps) {
    const { companyRepo } = useRepo();
    const { can } = useRbac();

    const canUpdateCompany = can(actions.company.update);

    function onSubmit(values: UpdateCompanyRequest, { setSubmitting }: FormikHelpers<UpdateCompanyRequest>) {
        setSubmitting(true);

        companyRepo.updateCompanyById(company.id, values)
            .then(onCompanyUpdated)
            .catch(console.error)
            .finally(() => setSubmitting(false));
    }

    return <CompanyProfileForm initialValues={initialValuesForCompany(company)}
                               variant='Update'
                               onSubmit={onSubmit}
                               disabled={!canUpdateCompany}/>
}


export function UpdateCurrentCompanyForm() {
    const { companyRepo } = useRepo();

    const [company, setCompany] = useState<Company>();

    useEffect(() => {
        companyRepo.getCompanyById('current').then(setCompany).catch(console.error);
    }, []);

    if (company) {
        return <UpdateCompanyForm company={company} onCompanyUpdated={setCompany}/>
    } else {
        return <LoadingIndicator/>
    }
}

