import {Typography} from "@material-ui/core";
import ChipSelect from "../../form/select/ChipSelect";
import * as React from "react";
import LicenseModel from "../../../models/domain/LicenseModel";

export interface Props {
    title: string;
    caption?: string;
    licenses: LicenseModel[];
    onLicenseSelected?: (license: LicenseModel) => void;
}

export function LicenseSection({ title, caption, licenses, onLicenseSelected }: Props) {
    return (
        <div>
            <Typography variant='subtitle2'>{title}</Typography>

            {caption && (
                <Typography variant='caption'>{caption}</Typography>
            )}

            <div>
                <ChipSelect items={licenses}
                            label={license => license.applicationName || ''}
                            onItemSelected={onLicenseSelected}/>
            </div>
        </div>
    )
}
