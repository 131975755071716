import {NoChargeSubscriptionSource} from "../../../../models/Subscription";
import * as React from "react";

interface Props {
    noChargeSource: NoChargeSubscriptionSource;
}

export default function NoChargeSubscriptionDetail({ noChargeSource }: Props) {
    // TODO: - Low priority but maybe include information about the file upload here
    return (
        <div></div>
    )
}
