export enum Brand {
    upnetic = "upnetic",
    gsb = "gsb",
}

export interface Environment {
    brand: Brand;
    clientID: string;
    redirectUri: string;
    authorityUrl: string;
    impersonationAuthorityUrl: string;
    apiBaseUrl: string;
    stripePublishableKey: string;
    passwordResetUrl: string;
    postLogoutRedirectUri: string;
    salesChannelIdentifier: string;
    stripeAccountIdentifier: string;
    smallIconUrl: string;
    bigIconUrl: string;
    appleIconUrl: string;
    dashboardUrl: string;
}

const env = (window as any)._env_;

const isUpnetic = window.location.hostname.toLowerCase().includes("upnetic");
const redirectUri = `${window.location.origin}/oauth2/callback`;

const userFlow = isUpnetic ?
  env.REACT_APP_UPNETIC_USER_FLOW || process.exit(1) :
  env.REACT_APP_GSB_USER_FLOW || process.exit(1);
const passwordResetUserFlow = isUpnetic ?
  env.REACT_APP_UPNETIC_PASSWORD_RESET_USER_FLOW || process.exit(1):
  env.REACT_APP_GSB_PASSWORD_RESET_USER_FLOW || process.exit(1);

if (!env.REACT_APP_AUTHORITY_URL) {
    process.exit(1)
}

const clientID = env.REACT_APP_CLIENT_ID || process.exit(1);

const brand = isUpnetic ? Brand.upnetic : Brand.gsb;
const authorityUrl = `${env.REACT_APP_AUTHORITY_URL}/${userFlow}`;
const impersonationAuthorityUrl = `${env.REACT_APP_AUTHORITY_URL}/${env.REACT_APP_IMPERSONATION_USER_FLOW}`;
const passwordResetUrl = `${env.REACT_APP_AUTHORITY_URL}/${passwordResetUserFlow}/oauth2/v2.0/authorize?client_id=${clientID}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(redirectUri)}&scope=openid&response_type=id_token&prompt=login&state=password-reset`
const salesChannelIdentifier = isUpnetic ? "upnetic" : "gsb";
const stripeAccountIdentifier = isUpnetic ? "upnetic": "gsb";
const stripePublishableKey = isUpnetic ? env.REACT_APP_UPNETIC_STRIPE_PUBLISHABLE_KEY: env.REACT_APP_GSB_STRIPE_PUBLISHABLE_KEY;

const smallIconUrl = isUpnetic ?
  "https://gsbstore.blob.core.windows.net/assets/cropped-upnetic-icon-32x32.png" :
  "https://gsbstore.blob.core.windows.net/assets/cropped-gsb-fav-32x32.png";
const bigIconUrl = isUpnetic ?
  "https://gsbstore.blob.core.windows.net/assets/cropped-upnetic-icon-192x192.png" :
  "https://gsbstore.blob.core.windows.net/assets/cropped-gsb-fav-192x192.png";
const appleIconUrl = isUpnetic ?
  "https://gsbstore.blob.core.windows.net/assets/cropped-upnetic-icon-180x180.png" :
  "https://gsbstore.blob.core.windows.net/assets/cropped-gsb-fav-180x180.png";
const dashboardUrl = env[`REACT_APP_${brand.toString().toUpperCase()}_DASHBOARD_URL`];


export const environment: Environment = {
    brand,
    redirectUri,
    clientID,
    authorityUrl,
    impersonationAuthorityUrl,
    passwordResetUrl,
    postLogoutRedirectUri: window.location.href,
    apiBaseUrl: env.REACT_APP_API_BASE_URL || process.exit(1),
    stripePublishableKey: stripePublishableKey || process.exit(1),
    salesChannelIdentifier,
    stripeAccountIdentifier,
    smallIconUrl,
    bigIconUrl,
    appleIconUrl,
    dashboardUrl
};
