import * as React from "react";
import {useState} from "react";
import {GSBFeatureDialog} from "./GSBFeatureDialog";

export default function useGSBFeatureDialog() {
    const [open, setOpen] = useState(false);
    
    const modal =  (
        <GSBFeatureDialog
            open={open}
            title={'Have a question about the features included within your membership?'}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
        />
    );

    return { openGSBFeatureDialog: () => setOpen(true), gsbFeatureDialog: modal }
}
