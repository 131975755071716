import {CompanyProfile, UpdateCompanyProfileBody} from "../models/CompanyProfile";
import HttpService from "@gsb/react-http";

export interface CompanyProfileRepo {
  getCurrent(): Promise<CompanyProfile | undefined>;
  updateCurrent(body: UpdateCompanyProfileBody): Promise<CompanyProfile>;
}

export class HttpCompanyProfileRepo implements CompanyProfileRepo {

  constructor(private http: HttpService) {}

  getCurrent(): Promise<CompanyProfile | undefined> {
    return this.http.get<{ profile: CompanyProfile }>(['mk', 'v1', 'companies', 'current'])
      .then(company => company.profile !== null ? company.profile : undefined);
  }

  updateCurrent(body: UpdateCompanyProfileBody): Promise<CompanyProfile> {
    const fullBody = {
      industryId: body.industryId ? body.industryId : null,
      businessType: body.businessType ? body.businessType : null,
      businessSize: body.businessSize ? body.businessSize : null,
      entityType: body.entityType ? body.entityType : null,
      stateCode: body.stateCode ? body.stateCode : null,
      yearFounded: body.yearFounded ? body.yearFounded : null
    };

    return this.http.put<{ profile: CompanyProfile }>(['mk', 'v1', 'companies', 'current', 'profile'], fullBody)
      .then(company => company.profile);
  }
} 
