import * as Yup from 'yup';
import {Company} from "../../models/Company";
import {UpdateCompanyProfileBody} from "../../models/CompanyProfile";
import moment from "moment";

export const companyProfileSchema = Yup.object().shape({
    industryId: Yup.string().required('Please select an industry'),
    businessSize: Yup.string().required('Please select a business size'),
    businessType: Yup.string().required('Please select a business type'),
    entityType: Yup.string().required('Please select an entity type'),
    stateCode: Yup.string().required('Please select a state'),
    yearFounded: Yup.number().integer()
        .min(1970, 'Please enter a year between 1970 and 2099. If the company was founded prior to 1970 then enter 1970.')
        .max(2099, 'Please enter a year between 1970 and 2099. If the company was founded prior to 1970 then enter 1970.')
        .nullable()
});

export const companySchema = Yup.object().shape({
    name: Yup.string().required('Please enter company name'),
    timeZone: Yup.string().nullable(),
    primaryContactId: Yup.string().required('Primary contact is required'),
    profile: companyProfileSchema
});

export const initialValuesForCompany = (company?: Company) => ({
    name: company?.name || '',
    timeZone: company?.timeZone || '',
    primaryContactId: company?.primaryContactId || '',
    profile: initialValuesForCompanyProfile(company ? company.profile : undefined),
    website: company?.website || ''
});

export const initialValuesForCompanyProfile = (companyProfile?: UpdateCompanyProfileBody) => ({
    industryId: companyProfile && companyProfile.industryId ? companyProfile.industryId : '',
    businessSize: companyProfile && companyProfile.businessSize ? companyProfile.businessSize : '',
    businessType: companyProfile && companyProfile.businessType ? companyProfile.businessType : '',
    entityType: companyProfile && companyProfile.entityType ? companyProfile.entityType : '',
    stateCode: companyProfile && companyProfile.stateCode ? companyProfile.stateCode : '',
    yearFounded: companyProfile && companyProfile.yearFounded ? companyProfile.yearFounded : moment().year()
});
