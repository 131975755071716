import {CSSProperties} from "react";

export const defaultMargins: CSSProperties = {
    marginTop: 6,
    marginBottom: 14,
    marginLeft: 6,
    marginRight: 6
};

export const defaultSize: CSSProperties = {
    width: 400,
};

export const textInputStyle: CSSProperties = {
    ...defaultMargins,
    ...defaultSize
};

export const pickerStyle: CSSProperties = {
    ...defaultSize
};