import React, {useContext, createContext, } from 'react';
import {Repo} from "./Repo";

const RepoContext = createContext<Repo>(null as unknown as Repo);

export const useRepo = () => useContext(RepoContext);

interface Props {
    repo: Repo;
}

export const RepoProvider: React.FC<Props> = ({ children, repo }) => {
    return (
        <RepoContext.Provider value={repo}>
            {children}
        </RepoContext.Provider>
    );
};
