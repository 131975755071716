import {ChipProps as MaterialChipProps} from "@material-ui/core/Chip";
import {Chip, PropTypes} from "@material-ui/core";
import AddIcon from '@material-ui/icons/AddCircleOutlined';
import React from "react";

interface Props {
    color?: Exclude<PropTypes.Color, 'inherit'>;
    label: string;
    onClick: () => void,
    ChipProps?: MaterialChipProps;
}

export default function ChipButton(props: Props) {
    return (
        <Chip
            label={props.label}
            clickable
            color={props.color || "primary"}
            onClick={props.onClick}
            deleteIcon={<AddIcon />}
            onDelete={props.onClick}
            {...props.ChipProps}
        />
    )
}
