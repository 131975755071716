import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import State from "../../../models/State";

export default function StatePicker(props: PickerPropsWithoutOptions) {
    const { stateRepo } = useRepo();

    const [states, setStates] = useState<State[]>([]);

    useEffect(() => {
        stateRepo.list().then(setStates).catch(console.error);
    }, [stateRepo]);

    const stateOptions = useMemo(() => states.map(s => ({ id: s.code, label: s.name })), [states]);

    return <PickerInput
        name={props.name || "stateCode"}
        label={props.label || "State"}
        options={stateOptions}
        {...props}
    />
}
