import React from "react";
import {NavigationHandler, NavigationProvider, RouteNavigationHandler} from "./useNavigation";
import { RouteComponentProps } from 'react-router-dom'
import {UrlTargetType} from "../../config/Url";

const withNavigation = <P extends RouteComponentProps>(Component: React.ComponentType<P>): React.FC<P> =>
    (props) => {

        const navigateToRoute = (route: string) => props.history.push(route);
        const navigateToUrl = (url: string, target?: UrlTargetType) => window.open(url, target || '_self');

        const handler: NavigationHandler = new RouteNavigationHandler(navigateToRoute, navigateToUrl);

        return (
            <NavigationProvider handler={handler}>
                <Component {...props}/>
            </NavigationProvider>
        );
    };

export default withNavigation;
