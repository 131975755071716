import HttpService from "@gsb/react-http";
import {CreateUserRequest, UpdateUserRequest, User} from "../models/User";
import {License, LicenseStatus} from "../models/License";
import {Role, RoleStatus} from "../models/Role";
import {sanitize} from "../utils/FormUtils";

export type AssignUserToLicenseRequest = { userId: string }
export type AssignLicensesForUserRequest = { licenseIds: string[] }
export type RevokeLicensesRequest = { licenseIds: string[] }

export type AssignRolesRequest = { roleIds: string[] }
export type RevokeRoleRequest = { roleIds: string[] }

type ListLicenseQuery = {
    status?: LicenseStatus;
    applicationId?: string;
    include?: string;
}

type ListRoleQuery = {
    status?: RoleStatus;
    applicationId?: string | null;
}

export interface UserRepo {
    createUser(body: CreateUserRequest) : Promise<User>;
    getUserById(id: string) : Promise<User>;
    updateUserById(id: string, body: UpdateUserRequest) : Promise<User>;
    deleteUserById(id: string) : Promise<void>;

    listAvailableLicensesForUserById(id: string): Promise<License[]>;
    assignLicensesForUserById(id: string, body: AssignLicensesForUserRequest): Promise<License>;
    revokeLicensesForUserById(id: string, query?: RevokeLicensesRequest): Promise<void>;

    listRolesForCurrentUser(): Promise<Role[]>
    listRolesForUserById(id: string, query?: ListRoleQuery): Promise<Role[]>
    assignRolesForUserById(id: string, body: AssignRolesRequest): Promise<Role[]>;
    revokeRolesForUserById(id: string, query?: RevokeRoleRequest): Promise<void>;

    getCurrentUser(): Promise<User>;
}

export class HttpUserRepo implements UserRepo {

    private basePath = ['mk', 'v1', 'users'];

    constructor(private http: HttpService) {}

    createUser(body: CreateUserRequest): Promise<User> {
        return this.http.post(this.basePath, sanitize(body));
    }

    updateUserById(id: string, body: UpdateUserRequest): Promise<User> {
        return this.http.put([...this.basePath, id], sanitize(body));
    }

    getUserById(id: string): Promise<User> {
        return this.http.get([...this.basePath, id]);
    }

    getCurrentUser(): Promise<User> {
        const query = { include: 'company,roles,licenses,applications,reseller,salesChannel' };
        return this.http.get([...this.basePath, 'current'], { query });
    }

    deleteUserById(id: string): Promise<void> {
        return this.http.delete([...this.basePath, id]);
    }

    listAvailableLicensesForUserById(id: string): Promise<License[]> {
        const query: ListLicenseQuery = {
            status: 'available'
        }
        return this.http.get([...this.basePath, id, 'licenses'], { query });
    }

    assignLicensesForUserById(id: string, body: AssignLicensesForUserRequest): Promise<License> {
        return this.http.post([...this.basePath, id, 'licenses'], body);
    }

    revokeLicensesForUserById(id: string, query?: RevokeLicensesRequest): Promise<void> {
        return this.http.delete([...this.basePath, id, 'licenses'], { query });
    }

    listRolesForCurrentUser(): Promise<Role[]> {
        return this.listRolesForUserById('current');
    }

    listRolesForUserById(id: string, query?: ListRoleQuery): Promise<Role[]> {
        return this.http.get([...this.basePath, id, 'roles'], { query });
    }

    assignRolesForUserById(id: string, body: AssignRolesRequest): Promise<Role[]> {
        return this.http.post([...this.basePath, id, 'roles'], body);
    }

    revokeRolesForUserById(id: string, query?: RevokeRoleRequest): Promise<void> {
        return this.http.delete([...this.basePath, id, 'roles'], { query });
    }
}
