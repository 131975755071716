export const roles = {
  system: "system",
  guest: "guest",
  companyUser: "companyUser",
  companyAdmin: "companyAdmin",
  companyManager: "companyManager",
  companyContractor: "companyContractor",
  companyReviewer: "companyReviewer",
  gsbSysAdmin: "gsbSysAdmin",
  gsbAdmin: "gsbAdmin",
  gsbConsultAdmin: "gsbConsultAdmin",
  gsbUser: "gsbUser",
  gsbConsultant: "gsbConsultant",
  gsbQuestionMaster: "gsbQuestionMaster",
  gsbAccountant: "gsbAccountant",
  gsbSupport: "gsbSupport",
  gsbSupportAdmin: "gsbSupportAdmin",
  sidApplicationMaster: "sidApplicationMaster",
  sidConsultant: "sidConsultant"
}

export const resources = {
  adpUser: "adpUser",
  adpSubscription: "adpSubscription",
  application: "application",
  company: "company",
  industry: "industry",
  license: "license",
  product: "product",
  productLicense: "productLicense",
  plan: "plan",
  reseller: "reseller",
  role: "role",
  salesChannel: "salesChannel",
  salesChannelApplication: "salesChannelApplication",
  state: "state",
  subscription: "subscription",
  subscriptionFileType: "subscriptionFileType",
  subscriptionFileUpload: "subscriptionFileUpload",
  subscriptionFileRecord: "subscriptionFileRecord",
  report: "report",
  user: "user",
  businessProfile: "businessProfile",
  employee: "employee",
  employeeCompany: "employeeCompany",
  employeeRole: "employeeRole",
  sender: "sender",
  template: "template",
  trigger: "trigger",
  consultant: "consultant",
  faq: "faq",
  faqStatus: "faqStatus",
  member: "member",
  question: "question",
  questionStatus: "questionStatus",
  site: "site",
  topic: "topic",
  siteTopic: "siteTopic",
  stripeAccount: "stripeAccount",
  stripePlan: "stripePlan",
  stripeProduct: "stripeProduct",
  pantheonDomain: "pantheonDomain",
  metadataType: "metadataType",
  mailchimpAccount: "mailchimpAccount",
  sendgridAccount: "sendgridAccount",
  stateIdApplication: "stateIdApplication",
  stateIdMember: "stateIdMember",
  stateIdConsultant: "stateIdConsultant",
  stateApplication: "stateApplication",
  stateIdApplicationStatus: "stateIdApplicationStatus",
  stateIdFieldType: "stateIdFieldType",
  stateIdSubscription: "stateIdSubscription",
  cronJob: "cronJob"
}

export const actions = {
  adpUser: {
    create: "createAdpUser"
  },
  adpSubscription: {
    activate: "activateAdpSubscription",
    cancel: "cancelAdpSubscription"
  },
  application: {
    create: "createApplication",
    get: "getApplicationById",
    update: "updateApplicationById",
    delete: "deleteApplicationById",
    list: "listApplications"
  },
  company: {
    create: "createCompany",
    list: "listCompanies",
    get: "getCompanyById",
    update: "updateCompanyById",
    delete: "deleteCompanyById",
    createUser: "createUserForCompanyById",
    listUsers: "listUsersForCompanyById",
    deleteUser: "deleteUserForCompanyById",
    updateProfile: "updateProfileForCompanyById",
    updateReseller: "updateResellerForCompanyById",
    updateSmtpSettings: "updateSmtpSettingsForCompanyById",
    getSmtpSettings: "getSmtpSettingsForCompanyById",
    updateMailChimpSettings: "updateMailChimpSettingsForCompanyById",
    getMailChimpSettings: "getMailChimpSettingsForCompanyById",
    listLicenses: "listLicensesForCompanyById",
    listPlans: "listPlansForCompanyById",
    listUsersForLicense: "listUsersForCompanyLicenseById",
    createStripeCheckoutSession: "createStripeCheckoutSessionForCompanyById",
    createAppleAppStoreSubscription:
      "createAppleAppStoreSubscriptionForCompanyById",
    createNoChargeSubscription: "createNoChargeSubscriptionForCompanyById",
    listSubscriptions: "listSubscriptionsForCompanyById",
    updateSubscription: "updateSubscriptionForCompanyById",
    cancelSubscription: "cancelSubscriptionForCompanyById",
    listWebsiteAnalysis: "listWebsiteAnalysisForCompanyById",
    updateWebsiteAnalysis: "updateWebsiteAnalysisForCompanyById",
    createWebsiteAnalysis: "createWebsiteAnalysisForCompanyById",
    listMetadata: "listMetadataForCompanyById",
    updateMetadata: "updateMetadataForCompanyById",
    createMetadata: "createMetadataForCompanyById",
    removeMetadata: "removeMetadataForCompanyById",
    addDomain: "addDomainForCompanyById",
    listDomains: "listDomainsForCompanyById",
    removeDomain: "removeDomainForCompanyById",
    createNote: "createNoteForCompanyById",
    deleteNote: "deleteNoteForCompanyById",
    updateNote: "updateNoteForCompanyById",
    listNotes: "listNotesForCompanyById",
    linkStripeCustomer: "linkStripeCustomerForCompanyById",
    listStripeCustomers: "listStripeCustomersForCompanyById",
    addCreditCard: "addCreditCardForCompanyById",
    listCreditCards: "listCreditCardsForCompanyById",
    updateCreditCard: "updatedCreditCardForCompanyById",
    removeCreditCard: "removeCreditCardForCompanyById",
    updateDefaultCreditCard: "updateDefaultCreditCardForCompanyById",
    listReferrals: "listReferralsForCompanyById"
  },
  businessProfile: {
    create: "createBusinessProfile",
    list: "listBusinessProfiles",
    get: "getBusinessProfileById",
    update: "updateBusinessProfileById",
    delete: "deleteBusinessProfileById"
  },
  industry: {
    create: "createIndustry",
    list: "listIndustries",
    get: "getIndustryById",
    update: "updateIndustryById",
    delete: "deleteIndustryById"
  },
  license: {
    create: "createLicense",
    list: "listLicenses",
    get: "getLicenseById",
    update: "updateLicenseById",
    delete: "deleteLicenseById"
  },
  report: {
    subscription: "subscriptionReport",
    stats: "statsReport",
    companyMetadata: "companyMetadataReport",
    companyNotes: "companyNotesReport",
    signIn: "userSignInReport",
    websiteAnalysis: "websiteAnalysisReport",
    websiteAnalysisSendReminder: "sendWebsiteAnalysisReminder",
    adpCompanySignIn: "adpCompanySignInReport"
  },
  plan: {
    create: "createPlan",
    get: "getPlanById",
    update: "updatePlanById",
    delete: "deletePlanById",
    list: "listPlans"
  },
  product: {
    create: "createProduct",
    get: "getProductById",
    update: "updateProductById",
    delete: "deleteProductById",
    list: "listProducts"
  },
  productLicense: {
    create: "createProductLicense",
    get: "getProductLicenseById",
    update: "updateProductLicenseById",
    delete: "deleteProductLicenseById",
    list: "listProductLicenses"
  },
  reseller: {
    create: "createReseller",
    list: "listResellers",
    get: "getResellerById",
    update: "updateResellerById",
    delete: "deleteResellerById"
  },
  role: {
    create: "createRole",
    list: "listRoles",
    get: "getRoleById",
    update: "updateRoleById",
    delete: "deleteRoleById"
  },
  salesChannel: {
    create: "createSalesChannel",
    list: "listSalesChannels",
    get: "getSalesChannelById",
    update: "updateSalesChannelById",
    delete: "deleteSalesChannelById"
  },
  salesChannelApplication: {
    create: "createSalesChannelApplication",
    list: "listSalesChannelApplications",
    get: "getSalesChannelApplicationById",
    update: "updateSalesChannelApplicationById",
    delete: "deleteSalesChannelApplicationById"
  },
  state: {
    create: "createState",
    get: "getStateById",
    update: "updateStateById",
    delete: "deleteStateById",
    list: "listStates"
  },
  subscription: {
    list: "listSubscriptions",
    get: "getSubscriptionById",
    cancel: "cancelSubscriptionById"
  },
  subscriptionFileType: {
    create: "createSubscriptionFileType",
    list: "listSubscriptionFileTypes",
    get: "getSubscriptionFileTypeById",
    update: "updateSubscriptionFileTypeById",
    delete: "deleteSubscriptionFileTypeById"
  },
  subscriptionFileUpload: {
    create: "createSubscriptionFileUpload",
    list: "listSubscriptionFileUploads",
    get: "getSubscriptionFileUploadById",
    update: "updateSubscriptionFileUploadById",
    delete: "deleteSubscriptionFileUploadById"
  },
  subscriptionFileRecord: {
    create: "createSubscriptionFileRecord",
    list: "listSubscriptionFileRecords",
    get: "getSubscriptionFileRecordById",
    update: "updateSubscriptionFileRecordById",
    delete: "deleteSubscriptionFileRecordById"
  },
  user: {
    create: "createUser",
    list: "listUsers",
    get: "getUserById",
    update: "updateUserById",
    delete: "deleteUserById",
    move: "moveUserById",
    sendPasswordResetEmail: "sendPasswordResetEmailForUserById",
    updateEmail: "updateEmailForUserById",
    updateUsername: "updateUsernameForUserById",
    updatePassword: "updatePasswordForUserById",
    updateProfile: "updateProfileForUserById",
    updateSubjectId: "updateSubjectIdForUserById",
    assignLicense: "assignLicensesForUserById",
    revokeLicense: "revokeLicenseForUserById",
    listLicenses: "listLicensesForUserById",
    assignRole: "assignRoleForUserById",
    revokeRole: "revokeRoleForUserById",
    listRoles: "listRolesForUserById",
    logAnalytics: "logAnalyticsForUserById",
    logSignIn: "logSignInForUserById",
    createReferral: "createReferralForUserById",
    getLegalClubStatus: "getLegalClubStatusForUserById",
    insertLegalClubClient: "insertLegalClubClientForUserById",
    withdrawlLegalClubClient: "withdrawlLegalClubClientForUserById",
    createB2C: "createB2CForUserById"
  },
  employee: {
    create: "createEmployee",
    list: "listEmployees",
    get: "getEmployeeById",
    update: "updateEmployeeById",
    delete: "deleteEmployeeById",
    assignRole: "assignRoleForEmployeeById",
    revokeRole: "revokeRoleForEmployeeById",
    listRoles: "listRolesForEmployeeById",
    assignSysAdminRole: "assignSysAdminRoleForEmployeeById",
    revokeSysAdminRole: "revokeSysAdminRoleForEmployeeById"
  },
  employeeCompany: {
    create: "createEmployeeCompany",
    list: "listEmployeeCompanies",
    get: "getEmployeeCompanyById",
    update: "updateEmployeeCompanyById",
    delete: "deleteEmployeeCompanyById"
  },
  employeeRole: {
    create: "createEmployeeRole",
    list: "listEmployeeRoles",
    get: "getEmployeeRoleById",
    update: "updateEmployeeRoleById",
    delete: "deleteEmployeeRoleById"
  },
  sender: {
    create: "createSender",
    list: "listSenders",
    get: "getSenderById",
    update: "updateSenderById",
    delete: "deleteSenderById"
  },
  template: {
    create: "createTemplate",
    list: "listTemplates",
    get: "getTemplateById",
    update: "updateTemplateById",
    delete: "deleteTemplateById"
  },
  trigger: {
    create: "createTrigger",
    list: "listTriggers",
    get: "getTriggerById",
    update: "updateTriggerById",
    delete: "deleteTriggerById",
    sendMail: "sendMailForTriggerByIdentifier"
  },
  topic: {
    create: "createTopic",
    list: "listTopics",
    get: "getTopicById",
    update: "updateTopicById",
    delete: "deleteTopicById"
  },
  consultant: {
    create: "createConsultant",
    list: "listConsultants",
    get: "getConsultantById",
    update: "updateConsultantById",
    delete: "deleteConsultantById",
    listQuestions: "listQuestionsForConsultantById"
  },
  faq: {
    create: "createFaq",
    list: "listFaqs",
    get: "getFaqById",
    update: "updateFaqById",
    delete: "deleteFaqById",
    review: "reviewFaqById",
    release: "releaseFaqById"
  },
  faqStatus: {
    create: "createFaqStatus",
    list: "listFaqStatuses",
    get: "getFaqStatusById",
    update: "updateFaqStatusById",
    delete: "deleteFaqStatusById"
  },
  member: {
    askQuestion: "askQuestionForMemberById",
    getFaq: "getFaqByIdForMemberById",
    getQuestion: "getQuestionByIdForMemberById",
    listFaqs: "listFaqsForMemberById",
    listQuestions: "listQuestionsForMemberById",
    listTopics: "listTopicsForMemberById"
  },
  questionStatus: {
    create: "createQuestionStatus",
    list: "listQuestionStatuses",
    get: "getQuestionStatusById",
    update: "updateQuestionStatusById",
    delete: "deleteQuestionStatusById"
  },
  question: {
    list: "listQuestions",
    get: "getQuestionById",
    update: "updateQuestionById",
    delete: "deleteQuestionById",
    answer: "answerQuestionById",
    assignAnswerer: "assignAnswererForQuestionById",
    assignReviewer: "assignReviewerForQuestionById",
    review: "reviewQuestionById",
    release: "releaseQuestionById",
    updateStatus: "updateStatusForQuestionById",
    submitForReview: "submitQuestionByIdForReview"
  },
  site: {
    create: "createSite",
    list: "listSites",
    get: "getSiteById",
    update: "updateSiteById",
    delete: "deleteSiteById",
    listTopics: "listTopicsForSiteById"
  },
  siteTopic: {
    create: "createSiteTopic",
    list: "listSiteTopics",
    get: "getSiteTopicById",
    update: "updateSiteTopicById",
    delete: "deleteSiteTopicById"
  },
  stripeAccount: {
    list: "listStripeAccounts"
  },
  stripePlan: {
    list: "listStripePlans"
  },
  stripeProduct: {
    list: "listStripeProducts"
  },
  pantheonDomain: {
    add: "addPantheonDomain",
    list: "listPantheonDomains",
    remove: "removePantheonDomain"
  },
  metadataType: {
    create: "createMetadataType",
    list: "listMetadataTypes",
    update: "updateMetadataType"
  },
  mailchimpAccount: {
    create: "createMailchimpAccount",
    list: "listMailchimpAccounts",
    update: "updateMailchimpAccountById",
    get: "getMailchimpAccountById",
    delete: "deleteMailchimpAccountById"
  },
  sendgridAccount: {
    create: "createSendGridAccount",
    list: "listSendGridAccounts",
    update: "updateSendGridAccountById",
    get: "getSendGridAccountById",
    delete: "deleteSendGridAccountById"
  },
  stateApplication: {
    create: "createStateApplication",
    list: "listStateApplications",
    listStates: "listStateApplicationStates",
    update: "updateStateApplicationById",
    get: "getStateApplicationById",
    delete: "deleteStateApplicationById",
    createSection: "createStateApplicationSection",
    listSections: "listStateApplicationSections",
    updateSection: "updateStateApplicationSectionById",
    getSection: "getStateApplicationSectionById",
    deleteSection: "deleteStateApplicationSectionById",
    createField: "createStateApplicationField",
    listFields: "listStateApplicationFields",
    updateField: "updateStateApplicationFieldById",
    getField: "getStateApplicationFieldByIdById",
    deleteField: "deleteStateApplicationFieldById"
  },
  stateIdConsultant: {
    create: "createStateIdConsultant",
    list: "listStateIdConsultants",
    update: "updateStateIdConsultantById",
    listApplications: "listApplicationsForConsultantById"
  },
  stateIdMember: {
    createApplication: "createStateIdApplicationForMember",
    listApplications: "listStateIdApplicationsForMemberById",
    updateApplication: "updateStateIdApplicationForMemberById",
    getApplication: "getStateIdApplicationForMemberById"
  },
  stateIdApplication: {
    list: "listStateIdApplications",
    update: "updateStateIdApplicationById",
    get: "getStateIdApplicationById",
    delete: "deleteStateIdApplicationById",
    assignAgent: "assignAgentToStateIdApplication",
    createNote: "createStateIdApplicationNote",
    updateNote: "updateStateIdApplicationNoteById",
    deleteNote: "deleteStateIdApplicationNoteById",
    listNotes: "listStateIdApplicationNotes"
  },
  stateIdApplicationStatus: {
    list: "listStateIdApplicationStatuses",
    create: "createStateIdApplicationStatus",
    update: "updateStateIdApplicationStatusById",
    get: "getStateIdApplicationStatusById",
    delete: "deleteStateIdApplicationStatusById"
  },
  stateIdFieldType: {
    create: "createStateIdFieldType",
    list: "listStateIdFieldTypes",
    update: "updateStateIdFieldTypeById",
    get: "getStateIdFieldTypeById",
    delete: "deleteStateIdFieldTypeById"
  },
  stateIdSubscription: {
    create: "createStateIdSubscription",
    delete: "deleteStateIdSubscription"
  },
  cronJob: {
    qaFollowUp: "questionAnswerFollowUpJob"
  }
}

export const grants = {
  system: {
    adpUser: [actions.adpUser.create],
    company: [
      actions.company.get,
      actions.company.list,
      actions.company.getSmtpSettings,
      actions.company.getMailChimpSettings,
      actions.company.listUsers,
      actions.company.listLicenses,
      actions.company.addDomain,
      actions.company.listDomains,
      actions.company.removeDomain,
      actions.company.createMetadata,
      actions.company.listMetadata,
      actions.company.updateMetadata,
      actions.company.removeMetadata
    ],
    reseller: [actions.reseller.list],
    user: [
      actions.user.get,
      actions.user.create,
      actions.user.list,
      actions.user.logSignIn
    ],
    employee: [actions.employee.create, actions.employee.list],
    trigger: [actions.trigger.sendMail],
    consultant: [actions.consultant.create, actions.consultant.update],
    pantheonDomain: [
      actions.pantheonDomain.add,
      actions.pantheonDomain.list,
      actions.pantheonDomain.remove
    ],
    metadataType: [actions.metadataType.list],
    stateIdConsultant: [
      actions.stateIdConsultant.create,
      actions.stateIdConsultant.update
    ],
    stateIdSubscription: [
      actions.stateIdSubscription.create,
      actions.stateIdSubscription.delete
    ],
    cronJob: [actions.cronJob.qaFollowUp]
  },
  guest: {
    topic: [actions.topic.list, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list]
  },
  gsbSysAdmin: {
    topic: [
      actions.topic.list,
      actions.topic.get,
      actions.topic.create,
      actions.topic.list,
      actions.topic.get,
      actions.topic.update,
      actions.topic.delete,
      actions.topic.create,
      actions.topic.list,
      actions.topic.get,
      actions.topic.update,
      actions.topic.delete
    ],
    siteTopic: [
      actions.siteTopic.list,
      actions.siteTopic.get,
      actions.siteTopic.create,
      actions.siteTopic.list,
      actions.siteTopic.get,
      actions.siteTopic.update,
      actions.siteTopic.delete,
      actions.siteTopic.create,
      actions.siteTopic.list,
      actions.siteTopic.get,
      actions.siteTopic.update,
      actions.siteTopic.delete
    ],
    salesChannel: [
      actions.salesChannel.list,
      actions.salesChannel.get,
      actions.salesChannel.create,
      actions.salesChannel.list,
      actions.salesChannel.get,
      actions.salesChannel.update,
      actions.salesChannel.delete
    ],
    reseller: [
      actions.reseller.list,
      actions.reseller.get,
      actions.reseller.create,
      actions.reseller.list,
      actions.reseller.get,
      actions.reseller.update,
      actions.reseller.delete
    ],
    state: [
      actions.state.list,
      actions.state.create,
      actions.state.list,
      actions.state.get,
      actions.state.update,
      actions.state.delete
    ],
    employee: [
      actions.employee.get,
      actions.employee.create,
      actions.employee.list,
      actions.employee.get,
      actions.employee.update,
      actions.employee.delete,
      actions.employee.listRoles,
      actions.employee.assignRole,
      actions.employee.revokeRole,
      actions.employee.assignSysAdminRole,
      actions.employee.revokeSysAdminRole
    ],
    consultant: [
      actions.consultant.list,
      actions.consultant.listQuestions,
      actions.consultant.create,
      actions.consultant.list,
      actions.consultant.get,
      actions.consultant.update,
      actions.consultant.delete
    ],
    faq: [
      actions.faq.create,
      actions.faq.get,
      actions.faq.list,
      actions.faq.create,
      actions.faq.get,
      actions.faq.list,
      actions.faq.update,
      actions.faq.delete,
      actions.faq.review,
      actions.faq.release,
      actions.faq.create,
      actions.faq.list,
      actions.faq.get,
      actions.faq.update,
      actions.faq.delete
    ],
    question: [
      actions.question.list,
      actions.question.get,
      actions.question.answer,
      actions.question.review,
      actions.question.release,
      actions.question.assignReviewer,
      actions.question.updateStatus,
      actions.question.submitForReview,
      actions.question.assignAnswerer,
      actions.question.update,
      actions.question.delete
    ],
    site: [
      actions.site.get,
      actions.site.list,
      actions.site.listTopics,
      actions.site.create,
      actions.site.list,
      actions.site.get,
      actions.site.update,
      actions.site.delete,
      actions.site.create,
      actions.site.list,
      actions.site.get,
      actions.site.update,
      actions.site.delete,
      actions.site.listTopics
    ],
    user: [
      actions.user.get,
      actions.user.create,
      actions.user.list,
      actions.user.get,
      actions.user.update,
      actions.user.listRoles,
      actions.user.listLicenses,
      actions.user.assignLicense,
      actions.user.revokeLicense,
      actions.user.listRoles,
      actions.user.assignRole,
      actions.user.revokeRole,
      actions.user.sendPasswordResetEmail,
      actions.user.updateEmail,
      actions.user.updateUsername,
      actions.user.updatePassword,
      actions.user.createReferral,
      actions.user.createB2C,
      actions.user.insertLegalClubClient,
      actions.user.withdrawlLegalClubClient,
      actions.user.getLegalClubStatus,
      actions.user.updateSubjectId,
      actions.user.move,
      actions.user.delete
    ],
    company: [
      actions.company.get,
      actions.company.create,
      actions.company.list,
      actions.company.get,
      actions.company.update,
      actions.company.updateProfile,
      actions.company.createUser,
      actions.company.listUsers,
      actions.company.deleteUser,
      actions.company.listLicenses,
      actions.company.listUsersForLicense,
      actions.company.listPlans,
      actions.company.getSmtpSettings,
      actions.company.updateSmtpSettings,
      actions.company.getMailChimpSettings,
      actions.company.updateMailChimpSettings,
      actions.company.listSubscriptions,
      actions.company.createNoChargeSubscription,
      actions.company.updateSubscription,
      actions.company.cancelSubscription,
      actions.company.listWebsiteAnalysis,
      actions.company.createWebsiteAnalysis,
      actions.company.updateWebsiteAnalysis,
      actions.company.linkStripeCustomer,
      actions.company.listStripeCustomers,
      actions.company.addDomain,
      actions.company.listDomains,
      actions.company.removeDomain,
      actions.company.createMetadata,
      actions.company.listMetadata,
      actions.company.removeMetadata,
      actions.company.updateMetadata,
      actions.company.listNotes,
      actions.company.createNote,
      actions.company.updateNote,
      actions.company.deleteNote,
      actions.company.listReferrals,
      actions.company.updateReseller,
      actions.company.delete
    ],
    faqStatus: [
      actions.faqStatus.list,
      actions.faqStatus.create,
      actions.faqStatus.list,
      actions.faqStatus.get,
      actions.faqStatus.update,
      actions.faqStatus.delete
    ],
    member: [],
    questionStatus: [
      actions.questionStatus.create,
      actions.questionStatus.list,
      actions.questionStatus.get,
      actions.questionStatus.update,
      actions.questionStatus.delete
    ],
    adpUser: [actions.adpUser.create],
    adpSubscription: [
      actions.adpSubscription.activate,
      actions.adpSubscription.cancel
    ],
    application: [
      actions.application.create,
      actions.application.list,
      actions.application.get,
      actions.application.update,
      actions.application.delete
    ],
    businessProfile: [
      actions.businessProfile.create,
      actions.businessProfile.list,
      actions.businessProfile.get,
      actions.businessProfile.update,
      actions.businessProfile.delete
    ],
    industry: [
      actions.industry.create,
      actions.industry.list,
      actions.industry.get,
      actions.industry.update,
      actions.industry.delete
    ],
    license: [
      actions.license.create,
      actions.license.list,
      actions.license.get,
      actions.license.update,
      actions.license.delete
    ],
    plan: [
      actions.plan.create,
      actions.plan.list,
      actions.plan.get,
      actions.plan.update,
      actions.plan.delete
    ],
    product: [
      actions.product.create,
      actions.product.list,
      actions.product.get,
      actions.product.update,
      actions.product.delete
    ],
    productLicense: [
      actions.productLicense.create,
      actions.productLicense.list,
      actions.productLicense.get,
      actions.productLicense.update,
      actions.productLicense.delete
    ],
    role: [
      actions.role.create,
      actions.role.list,
      actions.role.get,
      actions.role.update,
      actions.role.delete
    ],
    salesChannelApplication: [
      actions.salesChannelApplication.create,
      actions.salesChannelApplication.list,
      actions.salesChannelApplication.get,
      actions.salesChannelApplication.update,
      actions.salesChannelApplication.delete
    ],
    subscription: [
      actions.subscription.list,
      actions.subscription.get,
      actions.subscription.cancel
    ],
    subscriptionFileType: [
      actions.subscriptionFileType.create,
      actions.subscriptionFileType.list,
      actions.subscriptionFileType.get,
      actions.subscriptionFileType.update,
      actions.subscriptionFileType.delete
    ],
    subscriptionFileUpload: [
      actions.subscriptionFileUpload.create,
      actions.subscriptionFileUpload.list,
      actions.subscriptionFileUpload.get,
      actions.subscriptionFileUpload.update,
      actions.subscriptionFileUpload.delete
    ],
    subscriptionFileRecord: [
      actions.subscriptionFileRecord.create,
      actions.subscriptionFileRecord.list,
      actions.subscriptionFileRecord.get,
      actions.subscriptionFileRecord.update,
      actions.subscriptionFileRecord.delete
    ],
    report: [
      actions.report.subscription,
      actions.report.stats,
      actions.report.companyMetadata,
      actions.report.companyNotes,
      actions.report.signIn,
      actions.report.websiteAnalysis,
      actions.report.adpCompanySignIn,
      actions.report.websiteAnalysisSendReminder
    ],
    employeeCompany: [
      actions.employeeCompany.list,
      actions.employeeCompany.get,
      actions.employeeCompany.create,
      actions.employeeCompany.update,
      actions.employeeCompany.delete
    ],
    employeeRole: [
      actions.employeeRole.create,
      actions.employeeRole.list,
      actions.employeeRole.get,
      actions.employeeRole.update,
      actions.employeeRole.delete
    ],
    sender: [
      actions.sender.create,
      actions.sender.list,
      actions.sender.get,
      actions.sender.update,
      actions.sender.delete
    ],
    template: [
      actions.template.create,
      actions.template.list,
      actions.template.get,
      actions.template.update,
      actions.template.delete
    ],
    trigger: [
      actions.trigger.create,
      actions.trigger.list,
      actions.trigger.get,
      actions.trigger.update,
      actions.trigger.delete
    ],
    stripeAccount: [actions.stripeAccount.list],
    stripePlan: [actions.stripePlan.list],
    stripeProduct: [actions.stripeProduct.list],
    metadataType: [
      actions.metadataType.create,
      actions.metadataType.list,
      actions.metadataType.update
    ],
    mailchimpAccount: [
      actions.mailchimpAccount.create,
      actions.mailchimpAccount.get,
      actions.mailchimpAccount.list,
      actions.mailchimpAccount.update,
      actions.mailchimpAccount.delete
    ],
    sendgridAccount: [
      actions.sendgridAccount.create,
      actions.sendgridAccount.get,
      actions.sendgridAccount.list,
      actions.sendgridAccount.update,
      actions.sendgridAccount.delete
    ],
    stateApplication: [
      actions.stateApplication.create,
      actions.stateApplication.list,
      actions.stateApplication.get,
      actions.stateApplication.update,
      actions.stateApplication.createSection,
      actions.stateApplication.listSections,
      actions.stateApplication.getSection,
      actions.stateApplication.updateSection,
      actions.stateApplication.createField,
      actions.stateApplication.listFields,
      actions.stateApplication.getField,
      actions.stateApplication.updateField,
      actions.stateApplication.delete,
      actions.stateApplication.deleteSection,
      actions.stateApplication.deleteField
    ],
    stateIdApplication: [
      actions.stateIdApplication.list,
      actions.stateIdApplication.get,
      actions.stateIdApplication.update,
      actions.stateIdApplication.listNotes,
      actions.stateIdApplication.createNote,
      actions.stateIdApplication.updateNote,
      actions.stateIdApplication.assignAgent,
      actions.stateIdApplication.delete,
      actions.stateIdApplication.deleteNote
    ],
    stateIdFieldType: [
      actions.stateIdFieldType.list,
      actions.stateIdFieldType.get,
      actions.stateIdFieldType.get,
      actions.stateIdFieldType.update,
      actions.stateIdFieldType.create,
      actions.stateIdFieldType.delete
    ],
    stateIdConsultant: [
      actions.stateIdConsultant.list,
      actions.stateIdConsultant.create,
      actions.stateIdConsultant.update
    ],
    stateIdApplicationStatus: [
      actions.stateIdApplicationStatus.list,
      actions.stateIdApplicationStatus.get,
      actions.stateIdApplicationStatus.create,
      actions.stateIdApplicationStatus.update,
      actions.stateIdApplicationStatus.delete
    ]
  },
  gsbAdmin: {
    topic: [
      actions.topic.list,
      actions.topic.get,
      actions.topic.create,
      actions.topic.list,
      actions.topic.get,
      actions.topic.update,
      actions.topic.delete,
      actions.topic.create,
      actions.topic.list,
      actions.topic.get,
      actions.topic.update,
      actions.topic.delete
    ],
    siteTopic: [
      actions.siteTopic.list,
      actions.siteTopic.get,
      actions.siteTopic.create,
      actions.siteTopic.list,
      actions.siteTopic.get,
      actions.siteTopic.update,
      actions.siteTopic.delete,
      actions.siteTopic.create,
      actions.siteTopic.list,
      actions.siteTopic.get,
      actions.siteTopic.update,
      actions.siteTopic.delete
    ],
    salesChannel: [
      actions.salesChannel.list,
      actions.salesChannel.get,
      actions.salesChannel.create,
      actions.salesChannel.list,
      actions.salesChannel.get,
      actions.salesChannel.update,
      actions.salesChannel.delete
    ],
    reseller: [
      actions.reseller.list,
      actions.reseller.get,
      actions.reseller.create,
      actions.reseller.list,
      actions.reseller.get,
      actions.reseller.update,
      actions.reseller.delete
    ],
    state: [
      actions.state.list,
      actions.state.create,
      actions.state.list,
      actions.state.get,
      actions.state.update,
      actions.state.delete
    ],
    employee: [
      actions.employee.get,
      actions.employee.create,
      actions.employee.list,
      actions.employee.get,
      actions.employee.update,
      actions.employee.delete,
      actions.employee.listRoles,
      actions.employee.assignRole,
      actions.employee.revokeRole
    ],
    consultant: [
      actions.consultant.list,
      actions.consultant.listQuestions,
      actions.consultant.create,
      actions.consultant.list,
      actions.consultant.get,
      actions.consultant.update,
      actions.consultant.delete
    ],
    faq: [
      actions.faq.create,
      actions.faq.get,
      actions.faq.list,
      actions.faq.create,
      actions.faq.get,
      actions.faq.list,
      actions.faq.update,
      actions.faq.delete,
      actions.faq.review,
      actions.faq.release,
      actions.faq.create,
      actions.faq.list,
      actions.faq.get,
      actions.faq.update,
      actions.faq.delete
    ],
    question: [
      actions.question.list,
      actions.question.get,
      actions.question.answer,
      actions.question.review,
      actions.question.release,
      actions.question.assignReviewer,
      actions.question.updateStatus,
      actions.question.submitForReview,
      actions.question.assignAnswerer,
      actions.question.update,
      actions.question.delete
    ],
    site: [
      actions.site.get,
      actions.site.list,
      actions.site.listTopics,
      actions.site.create,
      actions.site.list,
      actions.site.get,
      actions.site.update,
      actions.site.delete,
      actions.site.create,
      actions.site.list,
      actions.site.get,
      actions.site.update,
      actions.site.delete,
      actions.site.listTopics
    ],
    user: [
      actions.user.get,
      actions.user.create,
      actions.user.list,
      actions.user.get,
      actions.user.update,
      actions.user.listRoles,
      actions.user.listLicenses,
      actions.user.assignLicense,
      actions.user.revokeLicense,
      actions.user.listRoles,
      actions.user.assignRole,
      actions.user.revokeRole,
      actions.user.sendPasswordResetEmail,
      actions.user.updateEmail,
      actions.user.updateUsername,
      actions.user.updatePassword,
      actions.user.createReferral,
      actions.user.createB2C
    ],
    company: [
      actions.company.get,
      actions.company.create,
      actions.company.list,
      actions.company.get,
      actions.company.update,
      actions.company.updateProfile,
      actions.company.createUser,
      actions.company.listUsers,
      actions.company.deleteUser,
      actions.company.listLicenses,
      actions.company.listUsersForLicense,
      actions.company.listPlans,
      actions.company.getSmtpSettings,
      actions.company.updateSmtpSettings,
      actions.company.getMailChimpSettings,
      actions.company.updateMailChimpSettings,
      actions.company.listSubscriptions,
      actions.company.createNoChargeSubscription,
      actions.company.updateSubscription,
      actions.company.cancelSubscription,
      actions.company.listWebsiteAnalysis,
      actions.company.createWebsiteAnalysis,
      actions.company.updateWebsiteAnalysis,
      actions.company.linkStripeCustomer,
      actions.company.listStripeCustomers,
      actions.company.addDomain,
      actions.company.listDomains,
      actions.company.removeDomain,
      actions.company.createMetadata,
      actions.company.listMetadata,
      actions.company.removeMetadata,
      actions.company.updateMetadata,
      actions.company.listNotes,
      actions.company.createNote,
      actions.company.updateNote,
      actions.company.deleteNote,
      actions.company.listReferrals
    ],
    faqStatus: [
      actions.faqStatus.list,
      actions.faqStatus.create,
      actions.faqStatus.list,
      actions.faqStatus.get,
      actions.faqStatus.update,
      actions.faqStatus.delete
    ],
    member: [],
    questionStatus: [
      actions.questionStatus.create,
      actions.questionStatus.list,
      actions.questionStatus.get,
      actions.questionStatus.update,
      actions.questionStatus.delete
    ],
    adpUser: [actions.adpUser.create],
    adpSubscription: [
      actions.adpSubscription.activate,
      actions.adpSubscription.cancel
    ],
    application: [
      actions.application.create,
      actions.application.list,
      actions.application.get,
      actions.application.update,
      actions.application.delete
    ],
    businessProfile: [
      actions.businessProfile.create,
      actions.businessProfile.list,
      actions.businessProfile.get,
      actions.businessProfile.update,
      actions.businessProfile.delete
    ],
    industry: [
      actions.industry.create,
      actions.industry.list,
      actions.industry.get,
      actions.industry.update,
      actions.industry.delete
    ],
    license: [
      actions.license.create,
      actions.license.list,
      actions.license.get,
      actions.license.update,
      actions.license.delete
    ],
    plan: [
      actions.plan.create,
      actions.plan.list,
      actions.plan.get,
      actions.plan.update,
      actions.plan.delete
    ],
    product: [
      actions.product.create,
      actions.product.list,
      actions.product.get,
      actions.product.update,
      actions.product.delete
    ],
    productLicense: [
      actions.productLicense.create,
      actions.productLicense.list,
      actions.productLicense.get,
      actions.productLicense.update,
      actions.productLicense.delete
    ],
    role: [
      actions.role.create,
      actions.role.list,
      actions.role.get,
      actions.role.update,
      actions.role.delete
    ],
    salesChannelApplication: [
      actions.salesChannelApplication.create,
      actions.salesChannelApplication.list,
      actions.salesChannelApplication.get,
      actions.salesChannelApplication.update,
      actions.salesChannelApplication.delete
    ],
    subscription: [
      actions.subscription.list,
      actions.subscription.get,
      actions.subscription.cancel
    ],
    subscriptionFileType: [
      actions.subscriptionFileType.create,
      actions.subscriptionFileType.list,
      actions.subscriptionFileType.get,
      actions.subscriptionFileType.update,
      actions.subscriptionFileType.delete
    ],
    subscriptionFileUpload: [
      actions.subscriptionFileUpload.create,
      actions.subscriptionFileUpload.list,
      actions.subscriptionFileUpload.get,
      actions.subscriptionFileUpload.update,
      actions.subscriptionFileUpload.delete
    ],
    subscriptionFileRecord: [
      actions.subscriptionFileRecord.create,
      actions.subscriptionFileRecord.list,
      actions.subscriptionFileRecord.get,
      actions.subscriptionFileRecord.update,
      actions.subscriptionFileRecord.delete
    ],
    report: [
      actions.report.subscription,
      actions.report.stats,
      actions.report.companyMetadata,
      actions.report.companyNotes,
      actions.report.signIn,
      actions.report.websiteAnalysis,
      actions.report.adpCompanySignIn
    ],
    employeeCompany: [
      actions.employeeCompany.list,
      actions.employeeCompany.get,
      actions.employeeCompany.create,
      actions.employeeCompany.update,
      actions.employeeCompany.delete
    ],
    employeeRole: [
      actions.employeeRole.create,
      actions.employeeRole.list,
      actions.employeeRole.get,
      actions.employeeRole.update,
      actions.employeeRole.delete
    ],
    sender: [
      actions.sender.create,
      actions.sender.list,
      actions.sender.get,
      actions.sender.update,
      actions.sender.delete
    ],
    template: [
      actions.template.create,
      actions.template.list,
      actions.template.get,
      actions.template.update,
      actions.template.delete
    ],
    trigger: [
      actions.trigger.create,
      actions.trigger.list,
      actions.trigger.get,
      actions.trigger.update,
      actions.trigger.delete
    ],
    stripeAccount: [actions.stripeAccount.list],
    stripePlan: [actions.stripePlan.list],
    stripeProduct: [actions.stripeProduct.list],
    metadataType: [
      actions.metadataType.create,
      actions.metadataType.list,
      actions.metadataType.update
    ],
    mailchimpAccount: [
      actions.mailchimpAccount.create,
      actions.mailchimpAccount.get,
      actions.mailchimpAccount.list,
      actions.mailchimpAccount.update,
      actions.mailchimpAccount.delete
    ],
    sendgridAccount: [
      actions.sendgridAccount.create,
      actions.sendgridAccount.get,
      actions.sendgridAccount.list,
      actions.sendgridAccount.update,
      actions.sendgridAccount.delete
    ],
    stateApplication: [
      actions.stateApplication.create,
      actions.stateApplication.list,
      actions.stateApplication.get,
      actions.stateApplication.update,
      actions.stateApplication.createSection,
      actions.stateApplication.listSections,
      actions.stateApplication.getSection,
      actions.stateApplication.updateSection,
      actions.stateApplication.createField,
      actions.stateApplication.listFields,
      actions.stateApplication.getField,
      actions.stateApplication.updateField
    ],
    stateIdApplication: [
      actions.stateIdApplication.list,
      actions.stateIdApplication.get,
      actions.stateIdApplication.update,
      actions.stateIdApplication.listNotes,
      actions.stateIdApplication.createNote,
      actions.stateIdApplication.updateNote,
      actions.stateIdApplication.assignAgent
    ],
    stateIdFieldType: [
      actions.stateIdFieldType.list,
      actions.stateIdFieldType.get
    ],
    stateIdConsultant: [
      actions.stateIdConsultant.list,
      actions.stateIdConsultant.create,
      actions.stateIdConsultant.update
    ],
    stateIdApplicationStatus: [
      actions.stateIdApplicationStatus.list,
      actions.stateIdApplicationStatus.get
    ]
  },
  gsbConsultAdmin: {
    topic: [
      actions.topic.list,
      actions.topic.get,
      actions.topic.create,
      actions.topic.list,
      actions.topic.get,
      actions.topic.update,
      actions.topic.delete
    ],
    siteTopic: [
      actions.siteTopic.list,
      actions.siteTopic.get,
      actions.siteTopic.create,
      actions.siteTopic.list,
      actions.siteTopic.get,
      actions.siteTopic.update,
      actions.siteTopic.delete
    ],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    employee: [actions.employee.get],
    consultant: [actions.consultant.list, actions.consultant.listQuestions],
    faq: [
      actions.faq.create,
      actions.faq.get,
      actions.faq.list,
      actions.faq.create,
      actions.faq.get,
      actions.faq.list,
      actions.faq.update,
      actions.faq.delete,
      actions.faq.review,
      actions.faq.release,
      actions.faq.create,
      actions.faq.list,
      actions.faq.get,
      actions.faq.update,
      actions.faq.delete
    ],
    question: [
      actions.question.list,
      actions.question.get,
      actions.question.answer,
      actions.question.review,
      actions.question.release,
      actions.question.assignReviewer,
      actions.question.updateStatus,
      actions.question.submitForReview,
      actions.question.assignAnswerer,
      actions.question.update,
      actions.question.delete
    ],
    site: [
      actions.site.get,
      actions.site.list,
      actions.site.listTopics,
      actions.site.create,
      actions.site.list,
      actions.site.get,
      actions.site.update,
      actions.site.delete
    ],
    user: [actions.user.get],
    company: [actions.company.get],
    faqStatus: [
      actions.faqStatus.list,
      actions.faqStatus.create,
      actions.faqStatus.list,
      actions.faqStatus.get,
      actions.faqStatus.update,
      actions.faqStatus.delete
    ],
    member: [],
    questionStatus: [
      actions.questionStatus.create,
      actions.questionStatus.list,
      actions.questionStatus.get,
      actions.questionStatus.update,
      actions.questionStatus.delete
    ]
  },
  gsbQuestionMaster: {
    topic: [actions.topic.list, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    employee: [actions.employee.get],
    consultant: [actions.consultant.list, actions.consultant.listQuestions],
    faq: [
      actions.faq.create,
      actions.faq.get,
      actions.faq.list,
      actions.faq.create,
      actions.faq.get,
      actions.faq.list,
      actions.faq.update,
      actions.faq.delete,
      actions.faq.review,
      actions.faq.release
    ],
    question: [
      actions.question.list,
      actions.question.get,
      actions.question.answer,
      actions.question.review,
      actions.question.release,
      actions.question.assignReviewer,
      actions.question.updateStatus,
      actions.question.submitForReview,
      actions.question.assignAnswerer,
      actions.question.update,
      actions.question.delete
    ],
    site: [actions.site.get, actions.site.list, actions.site.listTopics],
    user: [actions.user.get],
    company: [actions.company.get],
    faqStatus: [actions.faqStatus.list]
  },
  gsbConsultant: {
    topic: [actions.topic.list, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    employee: [actions.employee.get],
    consultant: [actions.consultant.list, actions.consultant.listQuestions],
    faq: [actions.faq.create, actions.faq.get, actions.faq.list],
    question: [
      actions.question.list,
      actions.question.get,
      actions.question.answer,
      actions.question.review,
      actions.question.release,
      actions.question.assignReviewer,
      actions.question.updateStatus,
      actions.question.submitForReview
    ],
    site: [actions.site.get, actions.site.list, actions.site.listTopics],
    user: [actions.user.get],
    company: [actions.company.get]
  },
  gsbUser: {
    topic: [actions.topic.list, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    employee: [actions.employee.get],
    company: [
      actions.company.create,
      actions.company.list,
      actions.company.get,
      actions.company.update,
      actions.company.updateProfile,
      actions.company.createUser,
      actions.company.listUsers,
      actions.company.deleteUser,
      actions.company.listLicenses,
      actions.company.listUsersForLicense,
      actions.company.listPlans,
      actions.company.getSmtpSettings,
      actions.company.updateSmtpSettings,
      actions.company.getMailChimpSettings,
      actions.company.updateMailChimpSettings,
      actions.company.listSubscriptions,
      actions.company.createNoChargeSubscription,
      actions.company.updateSubscription,
      actions.company.cancelSubscription,
      actions.company.listWebsiteAnalysis,
      actions.company.createWebsiteAnalysis,
      actions.company.updateWebsiteAnalysis,
      actions.company.linkStripeCustomer,
      actions.company.listStripeCustomers,
      actions.company.listNotes,
      actions.company.createNote,
      actions.company.updateNote,
      actions.company.listReferrals
    ],
    businessProfile: [
      actions.businessProfile.create,
      actions.businessProfile.list,
      actions.businessProfile.get,
      actions.businessProfile.update,
      actions.businessProfile.delete
    ],
    plan: [actions.plan.list, actions.plan.get],
    product: [actions.product.list, actions.product.get],
    subscription: [
      actions.subscription.list,
      actions.subscription.get,
      actions.subscription.cancel
    ],
    subscriptionFileType: [
      actions.subscriptionFileType.list,
      actions.subscriptionFileType.get
    ],
    subscriptionFileUpload: [
      actions.subscriptionFileUpload.create,
      actions.subscriptionFileUpload.list,
      actions.subscriptionFileUpload.get,
      actions.subscriptionFileUpload.update,
      actions.subscriptionFileUpload.delete
    ],
    subscriptionFileRecord: [
      actions.subscriptionFileRecord.create,
      actions.subscriptionFileRecord.list,
      actions.subscriptionFileRecord.get,
      actions.subscriptionFileRecord.update,
      actions.subscriptionFileRecord.delete
    ],
    user: [
      actions.user.create,
      actions.user.list,
      actions.user.get,
      actions.user.update,
      actions.user.listRoles,
      actions.user.listLicenses,
      actions.user.assignLicense,
      actions.user.revokeLicense,
      actions.user.listRoles,
      actions.user.assignRole,
      actions.user.revokeRole,
      actions.user.sendPasswordResetEmail,
      actions.user.logAnalytics,
      actions.user.createReferral
    ],
    sender: [
      actions.sender.create,
      actions.sender.list,
      actions.sender.get,
      actions.sender.update
    ],
    template: [
      actions.template.create,
      actions.template.list,
      actions.template.get,
      actions.template.update
    ],
    trigger: [
      actions.trigger.create,
      actions.trigger.list,
      actions.trigger.get,
      actions.trigger.update
    ],
    stripeAccount: [actions.stripeAccount.list],
    stripePlan: [actions.stripePlan.list],
    stripeProduct: [actions.stripeProduct.list]
  },
  sidConsultant: {
    topic: [actions.topic.list, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    employee: [actions.employee.get],
    stateIdConsultant: [
      actions.stateIdConsultant.list,
      actions.stateIdConsultant.listApplications
    ],
    stateApplication: [actions.stateApplication.get],
    stateIdApplication: [
      actions.stateIdApplication.list,
      actions.stateIdApplication.get,
      actions.stateIdApplication.update,
      actions.stateIdApplication.listNotes,
      actions.stateIdApplication.createNote,
      actions.stateIdApplication.updateNote
    ],
    stateIdApplicationStatus: [actions.stateIdApplicationStatus.list],
    user: [actions.user.get],
    company: [actions.company.get]
  },
  sidApplicationMaster: {
    topic: [actions.topic.list, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    employee: [actions.employee.get],
    stateIdConsultant: [
      actions.stateIdConsultant.list,
      actions.stateIdConsultant.listApplications
    ],
    stateApplication: [actions.stateApplication.get],
    stateIdApplication: [
      actions.stateIdApplication.list,
      actions.stateIdApplication.get,
      actions.stateIdApplication.update,
      actions.stateIdApplication.listNotes,
      actions.stateIdApplication.createNote,
      actions.stateIdApplication.updateNote,
      actions.stateIdApplication.assignAgent
    ],
    stateIdApplicationStatus: [actions.stateIdApplicationStatus.list],
    user: [actions.user.get],
    company: [actions.company.get]
  },
  companyUser: {
    topic: [actions.topic.list, actions.topic.get, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    company: [
      actions.company.get,
      actions.company.update,
      actions.company.listUsers,
      actions.company.createMetadata,
      actions.company.listMetadata,
      actions.company.updateMetadata
    ],
    businessProfile: [
      actions.businessProfile.create,
      actions.businessProfile.list,
      actions.businessProfile.get,
      actions.businessProfile.update,
      actions.businessProfile.delete
    ],
    industry: [actions.industry.list],
    member: [
      actions.member.askQuestion,
      actions.member.getQuestion,
      actions.member.getFaq,
      actions.member.listTopics,
      actions.member.listQuestions,
      actions.member.listFaqs
    ],
    user: [
      actions.user.get,
      actions.user.update,
      actions.user.listRoles,
      actions.user.listLicenses,
      actions.user.updateProfile,
      actions.user.logAnalytics,
      actions.user.logSignIn,
      actions.user.createReferral
    ],
    site: [actions.site.get, actions.site.list],
    stateApplication: [
      actions.stateApplication.get,
      actions.stateApplication.listStates
    ],
    stateIdMember: [
      actions.stateIdMember.createApplication,
      actions.stateIdMember.getApplication,
      actions.stateIdMember.updateApplication,
      actions.stateIdMember.listApplications
    ]
  },
  companyManager: {
    topic: [actions.topic.list, actions.topic.get, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    company: [
      actions.company.get,
      actions.company.update,
      actions.company.listUsers,
      actions.company.createMetadata,
      actions.company.listMetadata,
      actions.company.updateMetadata,
      actions.company.update,
      actions.company.getSmtpSettings,
      actions.company.updateSmtpSettings,
      actions.company.getMailChimpSettings,
      actions.company.updateMailChimpSettings
    ],
    businessProfile: [
      actions.businessProfile.create,
      actions.businessProfile.list,
      actions.businessProfile.get,
      actions.businessProfile.update,
      actions.businessProfile.delete
    ],
    industry: [actions.industry.list],
    member: [
      actions.member.askQuestion,
      actions.member.getQuestion,
      actions.member.getFaq,
      actions.member.listTopics,
      actions.member.listQuestions,
      actions.member.listFaqs
    ],
    user: [
      actions.user.get,
      actions.user.update,
      actions.user.listRoles,
      actions.user.listLicenses,
      actions.user.updateProfile,
      actions.user.logAnalytics,
      actions.user.logSignIn,
      actions.user.createReferral
    ],
    site: [actions.site.get, actions.site.list],
    stateApplication: [
      actions.stateApplication.get,
      actions.stateApplication.listStates
    ],
    stateIdMember: [
      actions.stateIdMember.createApplication,
      actions.stateIdMember.getApplication,
      actions.stateIdMember.updateApplication,
      actions.stateIdMember.listApplications
    ]
  },
  companyContractor: {
    topic: [actions.topic.list, actions.topic.get, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    company: [
      actions.company.get,
      actions.company.update,
      actions.company.listUsers,
      actions.company.createMetadata,
      actions.company.listMetadata,
      actions.company.updateMetadata
    ],
    businessProfile: [
      actions.businessProfile.create,
      actions.businessProfile.list,
      actions.businessProfile.get,
      actions.businessProfile.update,
      actions.businessProfile.delete
    ],
    industry: [actions.industry.list],
    member: [
      actions.member.askQuestion,
      actions.member.getQuestion,
      actions.member.getFaq,
      actions.member.listTopics,
      actions.member.listQuestions,
      actions.member.listFaqs
    ],
    user: [
      actions.user.get,
      actions.user.update,
      actions.user.listRoles,
      actions.user.listLicenses,
      actions.user.updateProfile,
      actions.user.logAnalytics,
      actions.user.logSignIn,
      actions.user.createReferral
    ],
    site: [actions.site.get, actions.site.list],
    stateApplication: [
      actions.stateApplication.get,
      actions.stateApplication.listStates
    ],
    stateIdMember: [
      actions.stateIdMember.createApplication,
      actions.stateIdMember.getApplication,
      actions.stateIdMember.updateApplication,
      actions.stateIdMember.listApplications
    ]
  },
  companyReviewer: {
    topic: [actions.topic.list, actions.topic.get, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    company: [
      actions.company.get,
      actions.company.update,
      actions.company.listUsers,
      actions.company.createMetadata,
      actions.company.listMetadata,
      actions.company.updateMetadata
    ],
    businessProfile: [
      actions.businessProfile.create,
      actions.businessProfile.list,
      actions.businessProfile.get,
      actions.businessProfile.update,
      actions.businessProfile.delete
    ],
    industry: [actions.industry.list],
    member: [
      actions.member.askQuestion,
      actions.member.getQuestion,
      actions.member.getFaq,
      actions.member.listTopics,
      actions.member.listQuestions,
      actions.member.listFaqs
    ],
    user: [
      actions.user.get,
      actions.user.update,
      actions.user.listRoles,
      actions.user.listLicenses,
      actions.user.updateProfile,
      actions.user.logAnalytics,
      actions.user.logSignIn,
      actions.user.createReferral
    ],
    site: [actions.site.get, actions.site.list],
    stateApplication: [
      actions.stateApplication.get,
      actions.stateApplication.listStates
    ],
    stateIdMember: [
      actions.stateIdMember.createApplication,
      actions.stateIdMember.getApplication,
      actions.stateIdMember.updateApplication,
      actions.stateIdMember.listApplications
    ]
  },
  companyAdmin: {
    topic: [actions.topic.list, actions.topic.get, actions.topic.get],
    siteTopic: [actions.siteTopic.list, actions.siteTopic.get],
    salesChannel: [actions.salesChannel.list, actions.salesChannel.get],
    reseller: [actions.reseller.list, actions.reseller.get],
    state: [actions.state.list],
    company: [
      actions.company.get,
      actions.company.update,
      actions.company.listUsers,
      actions.company.createMetadata,
      actions.company.listMetadata,
      actions.company.updateMetadata,
      actions.company.update,
      actions.company.delete,
      actions.company.updateProfile,
      actions.company.createUser,
      actions.company.listLicenses,
      actions.company.listUsersForLicense,
      actions.company.listPlans,
      actions.company.getSmtpSettings,
      actions.company.updateSmtpSettings,
      actions.company.getMailChimpSettings,
      actions.company.updateMailChimpSettings,
      actions.company.listSubscriptions,
      actions.company.createStripeCheckoutSession,
      actions.company.createAppleAppStoreSubscription,
      actions.company.updateSubscription,
      actions.company.cancelSubscription,
      actions.company.listWebsiteAnalysis,
      actions.company.createWebsiteAnalysis,
      actions.company.updateWebsiteAnalysis,
      actions.company.addCreditCard,
      actions.company.listCreditCards,
      actions.company.updateCreditCard,
      actions.company.removeCreditCard,
      actions.company.updateDefaultCreditCard,
      actions.company.addDomain,
      actions.company.listDomains,
      actions.company.removeDomain,
      actions.company.createMetadata,
      actions.company.listMetadata,
      actions.company.updateMetadata
    ],
    businessProfile: [
      actions.businessProfile.create,
      actions.businessProfile.list,
      actions.businessProfile.get,
      actions.businessProfile.update,
      actions.businessProfile.delete
    ],
    industry: [actions.industry.list],
    member: [
      actions.member.askQuestion,
      actions.member.getQuestion,
      actions.member.getFaq,
      actions.member.listTopics,
      actions.member.listQuestions,
      actions.member.listFaqs
    ],
    user: [
      actions.user.get,
      actions.user.update,
      actions.user.listRoles,
      actions.user.listLicenses,
      actions.user.updateProfile,
      actions.user.logAnalytics,
      actions.user.logSignIn,
      actions.user.createReferral,
      actions.user.assignRole,
      actions.user.revokeRole,
      actions.user.assignLicense,
      actions.user.revokeLicense
    ],
    site: [actions.site.get, actions.site.list],
    stateApplication: [
      actions.stateApplication.get,
      actions.stateApplication.listStates
    ],
    stateIdMember: [
      actions.stateIdMember.createApplication,
      actions.stateIdMember.getApplication,
      actions.stateIdMember.updateApplication,
      actions.stateIdMember.listApplications
    ]
  }
}
