import React from 'react';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { RouteComponentProps, withRouter} from "react-router-dom";
import UserInfo from "../../models/UserInfo";
import { customStyle } from '../../theme';
import Navigation, {NavigationItem} from "./Navigation";
import withAuthGuard from "../../services/auth/withAuthentication";
import withStartup from "../../services/startup/withStartup";
import {useNavigation} from "../../services/navigation/useNavigation";
import {useStartupResult} from "../../services/startup/useStartupResult";
import {DefaultNavigationLinkProvider, NavigationLinkProvider} from "../../NavigationLinkProvider";
import {Divider, ListItemIcon, ListItemText} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "64px",
            ...customStyle.header.root,
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'block',
        },
        logo: {
            height: 30,
            marginRight: theme.spacing(2),
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing(7),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 200,
            },
        },
        sectionDesktop: {
            display: 'flex',
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        }
    }),
);

interface Props extends RouteComponentProps {
    title?: string;
    logoSrc?: string;
    userInfo?: UserInfo;
    onProfileClicked?: () => void;
    onLogoutClicked?: () => void;
    onLogoClicked: () => void;
}

const menuId = 'primary-search-account-menu';

function Header(props: Props) {
    const {logoSrc, onLogoClicked, title, userInfo, onProfileClicked, onLogoutClicked} = props;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);

    function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleProfileClicked() {
        handleMenuClose();

        if (onProfileClicked) {
            onProfileClicked();
        }
    }

    function handleLogoutClicked() {
        handleMenuClose();

        if (onLogoutClicked) {
            onLogoutClicked();
        }
    }

    const renderMenu = (
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleProfileClicked}>
                <ListItemText primary="My Account" ></ListItemText>
                <ListItemIcon style={{justifyContent: "end"}}>
                    <ArrowForwardIcon/>
                </ListItemIcon>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogoutClicked} style={{color: "#f56c6c" }}>
                <ListItemText primary="Log Out" ></ListItemText>
                <ListItemIcon style={{justifyContent: "end", color: "#f56c6c"}}>
                    <ArrowForwardIcon/>
                </ListItemIcon>
            </MenuItem>
        </Menu>
    );

    return (
        <div>
            <AppBar position="static" className={classes.root}>
                <div style={{maxWidth: '1600px', margin: '0 auto', width: '100%', display: 'block', padding: '0 36px'}}>
                    <Toolbar disableGutters={true}>

                        <img className={classes.logo} src={logoSrc} alt="logo" onClick={() => onLogoClicked()}/>

                        {title && (
                            <Typography className={classes.title} variant="h6" noWrap>
                                {title}
                            </Typography>
                        )}

                        <div className={classes.grow}/>

                        {userInfo &&
                            <NavMenuWithAuth userInfo={userInfo} onProfileMenuOpen={handleProfileMenuOpen} />
                        }
                    </Toolbar>
                </div>
            </AppBar>
            {renderMenu}
        </div>
    );
}

const NavMenuWithAuth = withAuthGuard(withStartup(NavMenu));

function NavMenu(props: { userInfo?: UserInfo, onProfileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void }) {

    const { userInfo, onProfileMenuOpen } = props;
    const { navigateToUrl,  navigateToSalesChannelApplication } = useNavigation();

    const { licenses, applications } = useStartupResult();

    const navigationLinkProvider: NavigationLinkProvider = new DefaultNavigationLinkProvider(applications, licenses);
    const navigationLinks = navigationLinkProvider.getNavigationLinks();

    const onNavigationItemSelected = (item: NavigationItem) => {
        if(item.id === 'chat') {
            //@ts-ignore
            window.Beacon('toggle');
        }
        else if(item.href && item.href.length > 0) {
            navigateToUrl(item.href, item.target);
        }
        else {
            const salesChannelApplication = applications.find(a => a.application && a.application.identifier === item.id);
            if (salesChannelApplication) {
                navigateToSalesChannelApplication(salesChannelApplication)
            }
        }
    };

    return (
        <>
            <Navigation
                links={navigationLinks}
                onNavigationItemSelected={onNavigationItemSelected}
            />

            <IconButton
                edge="end"
                aria-label={'account of ' + userInfo?.firstName}
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={onProfileMenuOpen}
                color="inherit"
            >
                <AccountCircle fontSize="large" />
            </IconButton>
        </>
    )
}


export default withRouter(Header);
