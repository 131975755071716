import {Button} from "@material-ui/core";
import React from "react";

export default function NoLicensesAvailable(props: { onPurchaseLicensesClicked: () => void }) {
    const { onPurchaseLicensesClicked } = props;
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 8 }}>
            <div style={{ width: '100%', marginBottom: 18 }}>
                You currently have no licenses available to assign to users.
                This generally means you are out of licenses or each user is
                already assigned licenses for each application. If you have licenses
                available, you can create a new user by pressing the "Invite User" button.
                You can purchase more or remove licenses from existing users.
            </div>

            <Button variant='contained'
                    color='secondary'
                    style={{ alignSelf: 'flex-end' }}
                    onClick={onPurchaseLicensesClicked}>Purchase Licenses</Button>
        </div>
    )
}
