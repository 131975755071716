import * as React from "react";
import {useState} from "react";
import CreditCardForm, { CreditCardFormProps } from "../forms/CreditCardForm";
import { withModal } from "../../modal";

const CreateCardModal = withModal((props: CreditCardFormProps) => (
    <div style={{
        display: "flex",
        flexDirection: "column",
      }}>
      <CreditCardForm onCreate={props.onCreate} onError={props.onError} />
    </div>
), "Add Credit Card");

export default function useCreateCardForCurrentCompanyModal(onCardCreated: CreditCardFormProps["onCreate"]) {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);

  const createCardModal = <CreateCardModal
    open={open}
    onClose={close}
    onError={console.error}
    onCreate={(paymentMethod, setAsDefault) => {
      onCardCreated(paymentMethod, setAsDefault);
      close();
    }}
  />;

  return {
    createCardModal,
    openCreateCardModal: () => setOpen(true),
  }
}
