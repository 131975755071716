import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useStartupResult} from "../services/startup/useStartupResult";
import {useMemo} from "react";
import * as rbac from "../rbac";
import {Button} from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {Routes} from "../Routes";
import Title from "./Title";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflow: "auto"
    },
    content: {
        margin: "0 auto",
        maxWidth: "1600px",
        padding: "36px",
        display: 'flex',
        flexDirection: 'column'
    },
    paragraph: {
        fontSize: "14px"
    }
}));

interface Props extends RouteComponentProps {
    application: string;
}

function LicenseRequired(props: Props) {
    const classes = useStyles();
    const { application } = props;
    const { roles } = useStartupResult();

    const isCompanyAdmin = useMemo(() => roles.includes(rbac.roles.companyAdmin), [roles]);

    const navigateToManageScreen = () => {
        props.history.push(Routes.manage);
    };

    return (
        <div className={classes.root}>

            <Title title={"Ask a Consultant"}/>

            <div className={classes.content}>
                <p className={classes.paragraph}>
                    You do not have a license to access {application}.
                </p>

                {!isCompanyAdmin && (
                    <p className={classes.paragraph}>
                        Please contact your company administrator.
                    </p>
                )}

                {isCompanyAdmin && (
                    <div>
                        <p className={classes.paragraph}>
                            You may assign yourself a license or purchase additional licenses by visiting the manage screen.
                        </p>
                        <Button color='secondary'
                                variant='contained'
                                style={{ alignSelf: 'flex-end' }}
                                onClick={navigateToManageScreen}>Manage My Account</Button>
                    </div>
                )}
            </div>
        </div>
    )
}


export default withRouter(LicenseRequired);