import React, { useEffect } from 'react';
import Home from './components/Home';
import ViewQuestion from "./components/ViewQuestion";
import {Route, Switch, Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import {Routes} from "./Routes";
import FaqList from "./components/FaqListPage";
import FaqDetail from "./components/FaqDetail";
import {config} from "./config";
import Manage from "./components/manage";
import {useRepo} from "./repos/useRepo";
import {useServices} from "./services/useServices";
import Ask from './components/Ask';
import UserInfo from "./models/UserInfo";
import Pricing from "./components/Pricing";
import withAuthGuard from "./services/auth/withAuthentication";
import Header from "./components/layout/Header";
import {useNavigation} from "./services/navigation/useNavigation";
import withStartup from "./services/startup/withStartup";
import withNavigation from "./services/navigation/withNavigation";
import {withLicenseGuard} from "./services/withLicenseGuard";
import {useAuthState} from "./services/auth/useAuthState";
import Impersonation from "./components/Impersonation";
import DefaultFooter from "./components/layout/DefaultFooter";
import ImpersonationFooter from "./components/layout/ImpersonationFooter";
import ReactGA from "react-ga";

interface Props extends RouteComponentProps {

}

function App({}: Props) {

    const InitGA = (key:string) => {
        ReactGA.initialize(key);
        ReactGA.pageview(window.location.pathname);
    }

    const InitOneTrust = (key:string) => {
        appendScript({ src:`https://cdn.cookielaw.org/consent/${key}/OtAutoBlock.js` });
        appendScript({ src:'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', attr: { 'data-domain-script': key, charset: 'UTF-8' } });
        appendScript({html: 'function OptanonWrapper() { }'});
    }

    const appendScript = (append: {src?: string, html?: string, attr?: {[key:string]:string }}) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        if(append.src) { script.src = append.src; }
        if(append.html) { script.innerHTML = append.html; }
        if(append.attr) {
            Object.keys(append.attr).forEach(key => {
                if(append.attr) script.setAttribute(key, append.attr[key]);
            });
        }
        document.head.appendChild(script);
    }

    useEffect(() => { InitOneTrust(config.oneTrustKey); }, []);
    useEffect(() => { InitGA(config.googleAnalyticsKey); }, []);

    const { authService, stripeService } = useServices();
    const { isImpersonatingUser } = useAuthState();

    const { companyRepo } = useRepo();
    const { navigateToRoute } = useNavigation();
    const { userInfo } = useAuthState();

    const redirectToCheckout = (planId: string) => {
        companyRepo.createCheckoutSessionForCurrentCompany({ items: [{ planId, quantity: 1 }] })
            .then(session => stripeService.redirectToCheckout({ sessionId: session.id }))
            .then(console.log)
            .catch(console.error)
    };

    const Footer = () => isImpersonatingUser ?
      <ImpersonationFooter /> :
      <DefaultFooter />;

    const redirectToProfile = () => {
        if (config.externalManageAccountUrl) {
            window.location.href = config.externalManageAccountUrl;
        } else {
            navigateToRoute(Routes.manage);
        }
    }

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100vh"}}>
            <Header
                logoSrc={config.logoUrl}
                title='Small Business Consulting Services'
                userInfo={userInfo}
                onProfileClicked={redirectToProfile}
                onLogoutClicked={authService.logout}
                onLogoClicked={() => navigateToRoute(Routes.myQuestions)}
            />
            <div style={{ flex: 1 }}>
                <Switch>
                    <Route path={Routes.pricing} render={() => <Pricing onPurchasePlan={redirectToCheckout}/>} />
                    <Route path={Routes.impersonation} render={() => <Impersonation />}/>
                    <Route path={"*"} render={() => <ContentWithAuth userInfo={userInfo}/>}/>
                </Switch>
            </div>

            <Footer/>
        </div>
    );
}

const ContentWithAuth = withAuthGuard(withStartup(Content));

function Content(props: { userInfo?: UserInfo }) {
    const { userInfo } = props;
    const { questionRepo } = useRepo();

    return (
        <>
            <div className="content" style={{display: "flex", flexGrow: 1, margin: "0", padding: "0"}}>
                <Switch>
                    <Route path={Routes.ask} render={() => <AskWithLicenseGuard userInfo={userInfo}/>}/>
                    <Route path={Routes.viewQuestion} render={() => <ViewQuestion questionRepo={questionRepo} />} />
                    <Route path={Routes.viewFaq} render={() => <FaqDetail/>}/>
                    <Route path={Routes.faqs} render={() => <FaqList/>}/>
                    <Route path={Routes.manage} render={() => <Manage />} />
                    <Route path={Routes.myQuestions} render={() => <Home/>} />
                    <Route path={"*"} render={() => <Redirect to={Routes.myQuestions}/>} />
                </Switch>
            </div>
        </>
    )
}

const AskWithLicenseGuard = withLicenseGuard(Ask);

export default withRouter(withNavigation(App));
