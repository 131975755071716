import Industry from "../models/Industry";
import HttpService from "@gsb/react-http";
import CollectionResponse from "../models/CollectionResponse";

export interface IndustryRepo {
  list(): Promise<Industry[]>
}

export class HttpIndustryRepo implements IndustryRepo {

	constructor(private http: HttpService) {}
  
	list(): Promise<Industry[]> {
		return this.http.get<CollectionResponse<Industry>>(['mk', 'v1', 'industries']).then(res => res.results)
	}
} 
