import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import Industry from "../../../models/Industry";

export default function IndustryPicker(props: PickerPropsWithoutOptions) {
    const { industryRepo } = useRepo();

    const [industries, setIndustries] = useState<Industry[]>([]);

    useEffect(() => {
        industryRepo.list().then(setIndustries).catch(console.error);
    }, [industryRepo]);

    const industryOptions = useMemo(() => industries.map(i => ({ id: i.id, label: i.name })), [industries]);

    return <PickerInput
        name={props.name || "industryId"}
        label={props.label || "Industry"}
        options={industryOptions}
        {...props}
    />
}
