import HttpService from "@gsb/react-http";
import {CreateUserRequest, User} from "../models/User";
import {License, LicenseStatus} from "../models/License";
import {Company, UpdateCompanyRequest} from "../models/Company";
import {sanitize} from "../utils/FormUtils";
import {Subscription} from "../models/Subscription";
import {StripeCheckoutSession} from "../models/StripeCheckoutSession";
import { PaymentMethod } from "@stripe/stripe-js";
import {environment} from "../env";

type ListLicenseQuery = {
    status?: LicenseStatus,
    applicationId?: string;
    include?: string;
}

type Item = { planId: string, quantity: number }

export interface CompanyRepo {
    createUserForCompanyById(id: string, body: CreateUserRequest) : Promise<User>;
    listLicensesForCompanyById(id: string, query?: ListLicenseQuery) : Promise<License[]>;
    listSubscriptionsForCompanyById(id: string) : Promise<Subscription[]>;
    getCompanyById(id: string): Promise<Company>
    updateCompanyById(id: string, body: UpdateCompanyRequest): Promise<Company>

    listUsersForCompanyById(id: string) : Promise<User[]>;
    deleteUserForCompanyById(companyId: string, query: { userId: string }): Promise<void>;

    listAvailableUsersForCompanyLicenseById(companyId: string, licenseId: string): Promise<User[]>;

    listCardsForCompanyById(companyId: string): Promise<PaymentMethod[]>;
    getPaymentMethodForCompanyById(companyId: string): Promise<PaymentMethod>;
    addPaymentMethodForCompanyById(companyId: string, paymentMethodId: string): Promise<PaymentMethod>;
    deleteCardForCompanyById(companyId: string, paymentMethodId: string): Promise<PaymentMethod>;
    setDefaultPaymentMethodForCompanyById(companyId: string, paymentMethodId: string): Promise<any>;
    createCheckoutSessionForCurrentCompany(body: { items: Item[] }): Promise<StripeCheckoutSession>
}

export class HttpCompanyRepo implements CompanyRepo {

    constructor(private http: HttpService) {}

    createUserForCompanyById(id: string, body: CreateUserRequest): Promise<User> {
        return this.http.post(['mk', 'v1', 'companies', id, 'users'], sanitize(body));
    }

    listLicensesForCompanyById(id: string, query?: ListLicenseQuery): Promise<License[]> {
        return this.http.get(['mk', 'v1', 'companies', id, 'licenses'], { query });
    }

    listSubscriptionsForCompanyById(id: string): Promise<Subscription[]> {
        return this.http.get(['mk', 'v1', 'companies', id, 'subscriptions']);
    }

    getCompanyById(id: string): Promise<Company> {
        return this.http.get(['mk', 'v1', 'companies', id]);
    }

    updateCompanyById(id: string, body: UpdateCompanyRequest): Promise<Company> {
        return this.http.put(['mk', 'v1', 'companies', id], sanitize(body));
    }

    listUsersForCompanyById(id: string): Promise<User[]> {
        return this.http.get(['mk', 'v1', 'companies', id, 'users']);
    }

    deleteUserForCompanyById(companyId: string, query: { userId: string }): Promise<void> {
        return this.http.delete(['mk', 'v1', 'companies', companyId, 'users'], { query });
    }

    listAvailableUsersForCompanyLicenseById(companyId: string, licenseId: string): Promise<User[]> {
        return this.listUsersForCompanyLicenseById(companyId, licenseId, { status: 'available' });
    }

    private listUsersForCompanyLicenseById(companyId: string, licenseId: string, query: { status: 'available' }): Promise<User[]> {
        return this.http.get(['mk', 'v1', 'companies', companyId, 'licenses', licenseId, 'users'], { query });
    }

    createCheckoutSessionForCurrentCompany(body: { items: Item[] }): Promise<StripeCheckoutSession> {
        return this.http.post(['mk', 'v1', 'companies', 'current', 'stripe-checkout-sessions'], body, { credentials: "include" });
    }

    // MARK: - /companies/:companyId/cards
    addPaymentMethodForCompanyById(id: string, paymentMethodId: string): Promise<PaymentMethod> {
        const stripeAccountIdentifier = environment.stripeAccountIdentifier;
        return this.http.post(['mk', 'v1', 'companies', id, 'cards'], sanitize({ paymentMethodId, stripeAccountIdentifier }));
    }

    getPaymentMethodForCompanyById(companyId: string): Promise<PaymentMethod> {
        return this.http.get(['mk', 'v1', 'companies', companyId, 'cards', 'default'], {
            query: {
                stripeAccountIdentifier: environment.stripeAccountIdentifier
            }
        });
    }

    setDefaultPaymentMethodForCompanyById(companyId: string, paymentMethodId: string): Promise<any> {
        const stripeAccountIdentifier = environment.stripeAccountIdentifier;
        return this.http.post(['mk', 'v1', 'companies', companyId, 'cards', paymentMethodId, 'default'], sanitize({ paymentMethodId, stripeAccountIdentifier }));
    }

    deleteCardForCompanyById(companyId: string, paymentMethodId: string): Promise<PaymentMethod> {
        return this.http.delete(['mk', 'v1', 'companies', companyId, 'cards'], {
            query: {
                paymentMethodId,
                stripeAccountIdentifier: environment.stripeAccountIdentifier
            }
        });
    }

    listCardsForCompanyById(id: string): Promise<PaymentMethod[]> {
        return this.http.get(['mk', 'v1', 'companies', id, 'cards'], {
            query: {
                stripeAccountIdentifier: environment.stripeAccountIdentifier
            }
        });
    }
}